import styled from 'styled-components/macro';

import { Image } from 'components/Spinner/Spinner.styled';
import { colors } from 'styles/colors';
import { device } from 'styles/device';

export const Form = styled.form`
	width: 100%;

	${Image} {
		position: absolute;
		top: 50%;
		left: 50%;

		@media ${device.mobileL} {
			top: 45%;
			left: 45%;
		}
	}
`;

export const FormGroup = styled.div`
	width: 100%;
	margin-bottom: 13px;
`;

export const PasswordGroup = styled.div`
	width: 100%;
	margin-bottom: 0;

	> div {
		margin-bottom: 0;
	}
`;

export const LoginBtn = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin-top: 18px;

	button {
		@media ${device.mobileL} {
			width: 100%;
		}
	}
`;

export const LinkWrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-end;
`;

export const Link = styled.a`
	color: ${colors.navy};
	cursor: pointer;
`;
