import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { SendBirdProvider } from 'sendbird-uikit';

import { useGetUser, useGetAvatar } from 'api/services';
import avatarImg from 'assets/svg/icons/user-avatar.svg';
import Header from 'components/Header/Header';
import Sidebar from 'components/Sidebar/Sidebar';
import { colors } from 'styles/colors';
import { size } from 'styles/device';
import { DELETE_MODAL_TITLE } from 'utils/constants';

import { Layout, Content } from './MainLayout.styled';

const myColorSet = {
	'--sendbird-light-primary-300': colors.teal,
	'--sendbird-light-primary-200': colors.lightGrey,
	'--sendbird-light-primary-100': colors.gradient,
};

const MainLayout = ({ children }) => {
	const isResponsive = useMediaQuery({ maxWidth: size.laptop });
	const userState = useSelector((state) => state.user);
	const { accessToken, userId } = userState;
	const [stringSet] = useState({
		MODAL__DELETE_MESSAGE__TITLE: DELETE_MODAL_TITLE,
	});

	const dispatch = useDispatch();
	const { data: userResponse } = useGetUser();

	const { data: avatarImage } = useGetAvatar({
		resolve: (data) => {
			return data.blob().then((blob) => {
				return URL.createObjectURL(blob);
			});
		},
	});

	const setUser = () => {
		const userData = {
			firstName: userResponse?.first_name || '',
			lastName: userResponse?.last_name || '',
			age: userResponse?.age || 0,
			weight: userResponse?.weight || '0 lbs',
			height: userResponse?.height || '0"',
			accessToken: userResponse?.access_token || null,
			userId: userResponse.account_id,
		};
		dispatch.user.updateUser(userData || userState);
	};

	useEffect(async () => {
		if (avatarImage) {
			await avatarImage
				.then((img) => {
					dispatch.user.uploadPicture(img);
				})
				.catch(() => {
					dispatch.user.uploadPicture(avatarImg);
				});
		}
		if (userResponse) {
			setUser();
		}
	}, [avatarImage, userResponse, userId]);

	return (
		<Layout>
			{!isResponsive && <Sidebar />}

			<Content>
				<SendBirdProvider
					userId={userId}
					accessToken={accessToken}
					appId={process.env.REACT_APP_ID_SENDBIRD}
					colorSet={myColorSet}
					stringSet={stringSet}
				>
					<Header isResponsive={isResponsive} />
					{children}
				</SendBirdProvider>
			</Content>
		</Layout>
	);
};
export default MainLayout;
