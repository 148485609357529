import React, { useState } from 'react';

import { useMediaQuery } from 'react-responsive';

import { useGetTestResultsFile } from 'api/services';
import infoIcon from 'assets/svg/icons/info-navy.svg';
import PDFModal from 'components/PDFModal/PDFModal';
import { device } from 'styles/device';
import { resultIcons } from 'utils/iconsMappings';

import {
	Title,
	Description,
	Icon,
	InfoIcon,
	LearnMore,
	Card,
	CardWrapper,
	GeneralInfo,
} from './ResultCard.styled';

const ResultCard = ({ title, description, category, documentId }) => {
	const [isModalOpen, setModalOpen] = useState(false);
	const isMobile = useMediaQuery({ query: device.tablet });
	const openModalHandler = () => setModalOpen(true);

	return (
		<>
			<CardWrapper>
				<Card onClick={openModalHandler}>
					{isMobile && (
						<LearnMore>
							<InfoIcon src={infoIcon} />
							Learn More
						</LearnMore>
					)}

					<GeneralInfo>
						<Title>{title}</Title>
						<Icon src={resultIcons[category]} />
						<Description>{description}</Description>
					</GeneralInfo>
				</Card>
			</CardWrapper>

			{/* TODO: Remove isModalOpen and handle that logic in PDFModal */}
			{isModalOpen && (
				<PDFModal
					isOpen={isModalOpen}
					getPDF={useGetTestResultsFile}
					pdfId={documentId}
					title={title}
					onClose={() => setModalOpen(false)}
				/>
			)}
		</>
	);
};

export default ResultCard;
