import React from 'react';

import avatarImg from 'assets/svg/icons/user-avatar.svg';
import Spinner from 'components/Spinner/Spinner';

import { Picture, AvatarBorder, NoCorsImg } from './Avatar.styled';

const renderSimpleAvatarImages =
	`${process.env.REACT_APP_SIMPLE_AVATARS}` === 'true';

const Avatar = ({
	picture,
	allowSwitch = true,
	loading = false,
	altText = 'User profile',
}) => (
	<AvatarBorder id='avatarBorder' isPicture={picture}>
		{allowSwitch && renderSimpleAvatarImages ? (
			<NoCorsImg
				src={picture || avatarImg}
				alt={altText}
				className='userAvatar'
			/>
		) : (
			<Picture src={picture} alt='User profile' className='userAvatar' />
		)}
		{loading && <Spinner />}
	</AvatarBorder>
);

export default Avatar;
