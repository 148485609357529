import React, { useState, useEffect } from 'react';

import { useGetWeight } from 'api/services';
import ChartModal from 'components/ChartModal/ChartModal';

import { getGradientBackground } from './utils/chartColorizing';
import { handleChartData } from './utils/chartHelpers';
import { defaultConfig, styledChartElements } from './utils/customViewChart';

const WeightChart = ({ closeChart, isOpen }) => {
	const [data, setData] = useState({});
	const [chartConfig, setChartConfig] = useState(null);
	const [labelsData, setLabelsData] = useState(undefined);

	const { data: weightData, loading } = useGetWeight();
	const isDataExist = weightData?.data && weightData?.data.length;

	useEffect(() => {
		if (isDataExist) {
			const dataAdapted = handleChartData(weightData.data);

			setData(dataAdapted);
			setLabelsData(dataAdapted?.labels);
			handleChartConfig();
		}
	}, [weightData]);

	const handleChartConfig = () => {
		const minY = 0;
		const maxY = 450;

		const config = {
			...defaultConfig,
			elements: styledChartElements(weightData.data.length - 1),
			scales: {
				...defaultConfig.scales,
				y: {
					...defaultConfig.scales.y,
					min: minY,
					max: maxY,
				},
			},
		};

		setChartConfig(config);
	};

	const getChartData = (canvas) => {
		const gradient = getGradientBackground(canvas);

		return {
			...data,
			datasets: [
				{
					data: data?.datasets[0]?.data,
					fill: true,
					backgroundColor: gradient,
				},
			],
		};
	};

	return (
		<ChartModal
			loading={loading}
			labelsData={labelsData}
			data={getChartData}
			closeChart={closeChart}
			chartConfig={chartConfig}
			isOpen={isOpen}
		/>
	);
};

export default WeightChart;
