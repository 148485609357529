import styled from 'styled-components/macro';

import { device } from 'styles/device';

export const Form = styled.form`
	width: 100%;
`;

export const FormGroup = styled.div`
	width: 100%;
	margin-bottom: 13px;
`;

export const RegisterBtn = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin-top: 7px;

	button {
		@media ${device.mobileL} {
			width: 100%;
		}
	}
`;
