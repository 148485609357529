import React from 'react';

import { useMediaQuery } from 'react-responsive';

import arrowRight from 'assets/svg/icons/arrow-right.svg';
import Tooltip from 'common/Tooltip/Tooltip';
import { device } from 'styles/device';
import { getWholeNumber } from 'utils';

import {
	Container,
	CardHeader,
	CardTitle,
	CardTitleText,
	Measurement,
	Content,
	Units,
	Value,
	Button,
	Dot,
} from './Card.styled';

const handleBloodPressure = (param, bioData) =>
	param === 'bloodPressure' &&
	`${bioData['bloodPressureSystolic'] || 'N'}/${
		bioData['bloodPressureDiastolic'] || 'A'
	}`;

const Card = ({
	chartId,
	name,
	value,
	mapKey,
	healthIndicator,
	icon,
	type,
	data,
	onClick,
}) => {
	const showDecimalValue = chartId === 'waistHipRatio';
	const bloodPressure = handleBloodPressure(mapKey, data);

	const paramValue = showDecimalValue
		? data[mapKey]
		: getWholeNumber(data[mapKey]);

	const hIndicator = data[healthIndicator];

	const isMobile = useMediaQuery({ query: device.tablet });

	return (
		<Container key={name} onClick={onClick} tabIndex='0'>
			<CardHeader>
				<CardTitle title={name}>
					<CardTitleText>{name}</CardTitleText>
					<Tooltip mapKey={mapKey} color='white' />
					{hIndicator && <Dot healthIndicator={hIndicator} />}
				</CardTitle>

				{isMobile && (
					<Button>
						<img src={arrowRight} alt='Open chart' />
					</Button>
				)}
			</CardHeader>
			<Content>
				<Value>
					<Measurement name={chartId}>
						{bloodPressure || paramValue || value}
					</Measurement>
					<Units name={type}>{type}</Units>
				</Value>

				<img src={icon} alt={name} name={name} />
			</Content>
		</Container>
	);
};
export default Card;
