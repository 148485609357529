import React, { useState, useMemo, useRef, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import { useSendbirdStateContext, sendBirdSelectors } from 'sendbird-uikit';

import Button from 'common/Button/Button';
import Modal from 'components/Modal/Modal';
import { chatModalStyle } from 'utils';
import { SEND_GENERIC_MESSAGE_SUBTITLE } from 'utils/constants';
import { handleCreateChannel, getSelectedMember } from 'utils/sendBirdHelpers';
import { sendMessageButtonsLabels } from 'utils/sendMessageButtonsMapper';

import SendMessageButton from './SendMessageButton/SendMessageButton';

import {
	SubTitle,
	SelectWrapper,
	HealthOptions,
	HorizontalLine,
	MessageOptions,
	RefDiv,
} from './SendMessageModal.styled';

const SendMessageModal = ({ data, onCloseModal, title, isOpen }) => {
	const history = useHistory();
	const context = useSendbirdStateContext();
	const dispatch = useDispatch();
	const sdk = sendBirdSelectors.getSdk(context);
	const createChannel = sendBirdSelectors.getCreateChannel(context);

	const [selectedProgram, setSelectedProgram] = useState(null);
	const [healthSelected, setHealthSelected] = useState(false);
	const [buttonWidth, setButtonWidth] = useState(null);

	const isTabletPortrait = useMediaQuery({
		query: '(max-width: 1023px) and (orientation: portrait)',
	});

	const createChannelSendBird = async () => {
		const selectedMember = getSelectedMember(data, selectedProgram);

		if (sdk && sdk.GroupChannelParams) {
			const channelUrl = await handleCreateChannel(sdk, createChannel, null, [
				selectedMember.id,
			]);

			dispatch.messaging.update({ channelUrl });
			history.push('/messaging');
		}
	};

	const ref = useRef(null);

	useEffect(() => {
		if (ref.current) setButtonWidth(ref.current.offsetWidth);
	}, [ref.current]);

	const actionButtons = useMemo(
		() => (
			<>
				<Button
					margin='0 -15px 0 0'
					onClick={() => onCloseModal()}
					variant='cancel'
				>
					Cancel
				</Button>

				<Button
					disabled={
						!selectedProgram ||
						selectedProgram === sendMessageButtonsLabels.my_health
					}
					id='ok'
					onClick={() => createChannelSendBird()}
					variant='primary'
				>
					Ok
				</Button>
			</>
		),
		[selectedProgram]
	);

	const handleProgramClick = (program) => {
		setSelectedProgram(program);
	};

	const healthOptions = sendMessageButtonsLabels.healthOptions.map((option) => (
		<SendMessageButton
			key={option}
			id={option}
			onClick={() => handleProgramClick(option)}
			selectedProgram={selectedProgram}
		>
			{option}
		</SendMessageButton>
	));

	return (
		<Modal
			onRequestClose={onCloseModal}
			title={title}
			actionButtons={actionButtons}
			isOpen={isOpen}
			isPortrait={isTabletPortrait}
			style={chatModalStyle}
		>
			<SubTitle>{SEND_GENERIC_MESSAGE_SUBTITLE}</SubTitle>
			<SelectWrapper>
				<MessageOptions>
					<RefDiv ref={ref}>
						<SendMessageButton
							id={sendMessageButtonsLabels.program_support}
							onClick={() => {
								setSelectedProgram(sendMessageButtonsLabels.program_support);
								setHealthSelected(false);
							}}
							selectedProgram={selectedProgram}
						>
							{sendMessageButtonsLabels.program_support}
						</SendMessageButton>
					</RefDiv>
					<SendMessageButton
						id={sendMessageButtonsLabels.my_health}
						onClick={() => {
							setHealthSelected(true);
							setSelectedProgram(sendMessageButtonsLabels.my_health);
						}}
						selectedProgram={
							healthSelected && sendMessageButtonsLabels.my_health
						}
					>
						{sendMessageButtonsLabels.my_health}
					</SendMessageButton>
				</MessageOptions>
				{healthSelected && (
					<HealthOptions buttonWidth={buttonWidth}>
						<HorizontalLine />
						{healthOptions}
					</HealthOptions>
				)}
			</SelectWrapper>
		</Modal>
	);
};

export default SendMessageModal;
