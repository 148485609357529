import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useRegister } from 'api/services';
import Button from 'common/Button/Button';
import Input from 'common/Input/Input';
import Password from 'common/Password/Password';
import { red80 } from 'styles/colors';
import {
	checkPasswordStrength,
	checkPasswordMatch,
	showErrorNotify,
	showSuccessNotify,
	handleApiErrors,
} from 'utils';
import {
	PASSWORD_NOT_MATCH_ERR,
	PASSWORD_SHORT_ERR,
	SUCCESS_REGISTRATION,
	BOTTOM_LEFT,
	USERNAME_REQUIRED,
	VALIDATION_ERROR_MSG,
} from 'utils/constants';

import { Form, FormGroup, RegisterBtn } from './RegistrationForm.styled';

// TODO: This file is pretty much the same as LoginForm.js,
// try to make only 1 component

const RegistrationForm = ({ userId }) => {
	const history = useHistory();

	const [formData, setFormData] = useState({
		username: '',
		newPass: '',
		confirmPass: '',
		isUsernameValid: undefined,
		isPasswordValid: undefined,
	});

	const { username, newPass, confirmPass, isUsernameValid, isPasswordValid } =
		formData;

	const { mutate: register } = useRegister({
		queryParams: { account: userId },
	});

	const handleChange = (evt) => {
		const { name, value } = evt.target;
		toast.dismiss();
		setFormData((oldState) => ({
			...oldState,
			[name]: value,
			isUsernameValid: true,
			isPasswordValid: true,
		}));
	};

	const validateForm = () => {
		let valid = true;
		let stateName = '';

		if (!username) {
			valid = false;
			stateName = 'isUsernameValid';
			handleError(USERNAME_REQUIRED, stateName);
		}

		if (!checkPasswordStrength(newPass)) {
			valid = false;
			stateName = 'isPasswordValid';
			handleError(PASSWORD_SHORT_ERR, stateName);
		}

		if (!checkPasswordMatch(newPass, confirmPass)) {
			valid = false;
			stateName = 'isPasswordValid';
			handleError(PASSWORD_NOT_MATCH_ERR, stateName);
		}
		return valid;
	};

	const handleSignUp = async () => {
		try {
			const body = {
				username,
				password: newPass,
				confirm_password: confirmPass,
			};

			await register(body);

			showSuccessNotify(SUCCESS_REGISTRATION, BOTTOM_LEFT);
			history.push('/login');
		} catch (err) {
			handleApiErrors(
				err,
				(loc, msg) => showErrorNotify(`Error with ${loc}: ${msg}`, BOTTOM_LEFT),
				(msg) => showErrorNotify(msg, BOTTOM_LEFT),
				() => showErrorNotify(VALIDATION_ERROR_MSG, BOTTOM_LEFT)
			);
		}
	};

	const handleError = (msg, stateName) => {
		setFormData((oldState) => ({ ...oldState, [stateName]: false }));
		showErrorNotify(msg, BOTTOM_LEFT);
	};

	const onSubmit = (evt) => {
		evt.preventDefault();

		if (validateForm()) {
			handleSignUp();
		}
	};

	return (
		<Form role='form' onSubmit={onSubmit}>
			<FormGroup>
				<Input
					name='username'
					value={username}
					isValid={isUsernameValid}
					labelText='Username'
					errorColor={red80}
					handleChange={handleChange}
				/>
			</FormGroup>

			<FormGroup>
				<Password
					name='newPass'
					value={newPass}
					isValid={isPasswordValid}
					labelText='Password'
					errorColor={red80}
					handleChange={handleChange}
				/>
			</FormGroup>

			<FormGroup>
				<Password
					name='confirmPass'
					value={confirmPass}
					isValid={isPasswordValid}
					labelText='Repeat Password'
					errorColor={red80}
					handleChange={handleChange}
				/>
			</FormGroup>

			<RegisterBtn>
				<Button variant='primary' type='submit'>
					Register
				</Button>
			</RegisterBtn>
		</Form>
	);
};

export default RegistrationForm;
