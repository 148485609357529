import React from 'react';

import { Row, Title, CloseBtn } from './ModalHeader.styled';

const ModalHeader = ({ onClose, title }) => {
	return (
		<Row title={title}>
			{title && <Title data-testid='modalTitle'>{title}</Title>}

			{/* TODO: Think it over if we can put this component into <Button /> as well */}
			<CloseBtn
				onClick={onClose}
				type='button'
				name='Close'
				data-testid='modalClose'
			>
				<i className='lni lni-close'></i>
			</CloseBtn>
		</Row>
	);
};

export default ModalHeader;
