import Resizer from 'react-image-file-resizer';
import { toast } from 'react-toastify';

import {
	BOTTOM_LEFT,
	GENERIC_ERROR_MESSAGE,
	EMAIL_RGX,
	PASSWORD_RGX,
	PHONE_RGX,
} from './constants';

// Toast notifications
export const showSuccessNotify = (msg, position) =>
	toast.success(msg, { position, hideProgressBar: true });

export const showWarningNotify = (msg, position) =>
	toast.warn(msg, { position, hideProgressBar: true });

export const showErrorNotify = (msg, position, toastId = null) =>
	toast.error(msg, { position, hideProgressBar: true, toastId });

export const checkPasswordStrength = (pass) => PASSWORD_RGX.test(pass);

export const checkPasswordMatch = (firstPass, secPass) => firstPass === secPass;

export const checkPhoneValidity = (phone) => PHONE_RGX.test(phone);

export const isEmailFormat = (email) => EMAIL_RGX.test(email);

export const removeToken = () => sessionStorage.removeItem('token');

export const removeStorageItem = (storageName) => {
	if (localStorage.getItem(storageName)) {
		localStorage.removeItem(storageName);
	} else {
		showErrorNotify(GENERIC_ERROR_MESSAGE, BOTTOM_LEFT);
	}
};

export const isEmptyArray = (arr) => !Array.isArray(arr) || arr.length === 0;

export const isEmptyObject = (obj) => obj && Object.keys(obj).length === 0;

export const getWholeNumber = (num) => Math.floor(num);

export const formatDate = (date) => {
	date = new Date(date);
	const year = date.getFullYear().toString();
	const month = (date.getMonth() + 1).toString();
	const day = date.getDate().toString();

	return `${month}/${day}/${year}`;
};

export const toFeetAndInch = (inches) => {
	return parseInt(inches / 12) + `'` + Math.round(inches % 12);
};

export const deleteByValue = (object) => {
	for (var key in object) {
		if (!object[key].id) delete object[key];
	}
	return object;
};

export const resizeFile = (file, maxWidth = 400, maxHeight = 400) =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			maxWidth,
			maxHeight,
			'JPEG',
			100,
			0,
			(uri) => {
				resolve(uri);
			},
			'base64'
		);
	});

export const getToken = () => sessionStorage.getItem('token');

export const getDateFromTimestamp = (timestamp) =>
	new Date(timestamp).toLocaleDateString('en-US');

const USE_SIMPLE_VALIDATION_ERRORS =
	`${process.env.REACT_APP_SIMPLE_VALIDATION_ERRORS}` === 'true';

export const handleApiErrors = (err, verr, serr, gerr) => {
	const payload = err.data;
	switch (payload.code) {
		case 'validation':
			if (!USE_SIMPLE_VALIDATION_ERRORS) {
				payload.data.forEach((v) => {
					v.loc.forEach((l) => verr(l, v.msg));
				});
				return;
			}
			gerr(err);
			break;
		default:
			if (payload && payload.message) serr(payload.message);
			else gerr(err);
	}
};

export const formatPhoneNumber = (str) => {
	const cleaned = ('' + str).replace(/\D/g, '');

	const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4,})$/);

	if (match) {
		const intlCode = match[1] ? '+1 ' : '';
		return `${intlCode}(${match[2]}) ${match[3]}-${match[4]}`;
	}

	return str;
};

export const modalStyle = {
	overlay: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'rgba(5, 14, 44, 0.5)',
	},
};

export const chatModalStyle = {
	...modalStyle,
	content: {
		width: '550px',
	},
};

export const autoWidthModalStyle = {
	...modalStyle,
	content: {
		width: 'auto',
		maxWidth: '90%',
	},
};
