import React from 'react';

import ContentLoader from 'react-content-loader';

import { Container as BodyContainer } from 'components/BodyComposition/BodyComposition.styled';
import {
	Container as HealthFitnessCards,
	Row as HealthFitnessRow,
} from 'components/HealthFitness/HealthFitnessCards/HealthFitnessCards.styled';
import { Container } from 'components/ProfileCard/ProfileCard.styled';
import {
	Row,
	CardWrapper,
	Content,
} from 'pages/HealthTracking/HealthTracking.styled';
import { compositionsBodyData } from 'utils/lists';

import {
	HFCardContainer,
	HeadingWrapper,
	CompositionCardWrapper,
	HeaderWrapper,
	UserLoaderWrapper,
} from './HealthTrackingLoader.styled';

const Heading = () => (
	<HeadingWrapper>
		<ContentLoader
			speed={2}
			width={709}
			height={116}
			viewBox='0 0 709 116'
			backgroundColor='#f3f3f3'
			foregroundColor='#ecebeb'
		>
			<rect x='0' y='46' rx='4' ry='4' width='709' height='21' />
			<rect x='0' y='95' rx='4' ry='4' width='95' height='21' />
			<rect x='117' y='95' rx='4' ry='4' width='95' height='21' />
			<rect x='0' y='0' rx='4' ry='4' width='174' height='32' />
		</ContentLoader>
	</HeadingWrapper>
);

const UserCard = () => (
	<CardWrapper>
		<Container>
			<UserLoaderWrapper>
				<ContentLoader
					speed={2}
					width={252}
					height={220}
					viewBox='0 0 252 224'
					backgroundColor='#f3f3f3'
					foregroundColor='#ecebeb'
				>
					<circle cx='126' cy='43' r='43' />
					<rect x='64' y='97' rx='0' ry='0' width='124' height='26' />
					<rect x='0' y='159' rx='0' ry='0' width='65' height='65' />
					<rect x='93' y='159' rx='0' ry='0' width='65' height='65' />
					<rect x='184' y='159' rx='0' ry='0' width='65' height='65' />
				</ContentLoader>
			</UserLoaderWrapper>
		</Container>
	</CardWrapper>
);

const BodyCompositionCard = (props) => (
	<CompositionCardWrapper>
		<ContentLoader
			speed={2}
			width='100%'
			height='220px'
			viewBox='0 0 180 220'
			backgroundColor='#f3f3f3'
			foregroundColor='#ecebeb'
			{...props}
		>
			<rect x='11' y='13' rx='4' ry='4' width='69' height='16' />
			<rect x='141' y='145' rx='4' ry='4' width='29' height='16' />
			<rect x='28' y='168' rx='4' ry='4' width='124' height='26' />
			<rect x='61' y='46' rx='30' ry='30' width='59' height='59' />
			<circle cx='152' cy='29' r='19' />
		</ContentLoader>
	</CompositionCardWrapper>
);

const BodyCompositionHeader = () => (
	<HeaderWrapper>
		<ContentLoader
			speed={2}
			width={133}
			height={21}
			viewBox='0 0 133 21'
			backgroundColor='#f3f3f3'
			foregroundColor='#ecebeb'
		>
			<rect x='0' y='0' rx='4' ry='4' width='133' height='21' />
		</ContentLoader>
	</HeaderWrapper>
);

const HealthFitnessCard = () => (
	<HFCardContainer>
		<ContentLoader
			speed={2}
			width='100%'
			height={106}
			viewBox='0 0 250 106'
			backgroundColor='#f3f3f3'
			foregroundColor='#ecebeb'
		>
			<rect x='197' y='55' rx='21' ry='21' width='41' height='41' />
			<rect x='86' y='12' rx='4' ry='4' width='29' height='16' />
			<rect x='10' y='12' rx='4' ry='4' width='66' height='16' />
			<rect x='10' y='55' rx='4' ry='4' width='40' height='40' />
			<rect x='60' y='79' rx='4' ry='4' width='74' height='16' />
			<circle cx='228' cy='18' r='10' />
		</ContentLoader>
	</HFCardContainer>
);

const HealthFitnessHeader = () => (
	<HeaderWrapper>
		<ContentLoader
			speed={2}
			width={50}
			height={21}
			viewBox='0 0 50 21'
			backgroundColor='#f3f3f3'
			foregroundColor='#ecebeb'
		>
			<rect x='0' y='0' rx='4' ry='4' width='50' height='21' />
		</ContentLoader>
	</HeaderWrapper>
);

const HealthTrackingLoader = () => (
	<div id='healthLoader'>
		<Heading id='healthHeaderLoader' />
		<Content>
			<Row>
				<UserCard />
				<BodyContainer>
					<BodyCompositionHeader />
					<Row>
						{compositionsBodyData.map((item) => (
							<BodyCompositionCard key={item.name} />
						))}
					</Row>
				</BodyContainer>
			</Row>
			<Row>
				<HealthFitnessCards>
					<HealthFitnessHeader />
					<HealthFitnessRow>
						<HealthFitnessCard />
						<HealthFitnessCard />
					</HealthFitnessRow>
					<HealthFitnessRow>
						<HealthFitnessCard />
						<HealthFitnessCard />
					</HealthFitnessRow>
				</HealthFitnessCards>
				<HealthFitnessCards>
					<HealthFitnessHeader />
					<HealthFitnessRow>
						<HealthFitnessCard />
						<HealthFitnessCard />
					</HealthFitnessRow>
					<HealthFitnessRow>
						<HealthFitnessCard />
						<HealthFitnessCard />
					</HealthFitnessRow>
				</HealthFitnessCards>
			</Row>
		</Content>
	</div>
);

export default HealthTrackingLoader;
