import React from 'react';

import { InputField, Label } from './Input.styled';

const Input = ({
	name,
	value,
	type = 'text',
	isValid,
	handleChange,
	labelText,
	errorColor,
	disabled,
	placeholder = '',
	maxLength = null,
}) => (
	<>
		<Label htmlFor={name} isValid={isValid} errorColor={errorColor}>
			{labelText}
		</Label>
		<InputField
			id={name}
			name={name}
			value={value}
			type={type}
			disabled={disabled}
			isValid={isValid}
			placeholder={placeholder}
			errorColor={errorColor}
			onChange={handleChange}
			maxLength={maxLength}
		/>
	</>
);

export default Input;
