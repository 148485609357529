import React from 'react';

import { useSelector } from 'react-redux';

import Benefits from 'components/Benefits/Benefits';
import Logo from 'components/Logo/Logo';

import {
	Layout,
	BenefitsSection,
	MainSection,
	Header,
	LogoWrapper,
	Title,
	Content,
	Container,
	PolicyContainer,
} from './AuthLayout.styled';

const AuthLayout = ({ children, title }) => {
	const { isPolicyAccepted } = useSelector((state) => state.policyConsent);

	return (
		<Layout>
			<MainSection>
				<Content>
					<Container>
						{isPolicyAccepted ? null : (
							<PolicyContainer>
								To continue, please accept our policy
							</PolicyContainer>
						)}
						<Header>
							<LogoWrapper>
								<Logo width='220px' height='40px' />
							</LogoWrapper>
							<Title>{title}</Title>
						</Header>

						{children}
					</Container>
				</Content>
			</MainSection>

			<BenefitsSection>
				<Benefits />
			</BenefitsSection>
		</Layout>
	);
};

export default AuthLayout;
