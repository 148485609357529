import React, { useState, useMemo } from 'react';

import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import { useMediaQuery } from 'react-responsive';

import pdfIcon from 'assets/svg/icons/pdf.svg';
import Button from 'common/Button/Button';
import Modal from 'components/Modal/Modal';
import { autoWidthModalStyle } from 'utils';

import {
	Anchor,
	ModalContainer,
	PDFContainer,
	NoPDF,
	Icon,
	EmptyContainer,
	IconBackground,
} from './PDFModal.styled';

const PDFModal = ({ pdfId, title, onClose, getPDF, isOpen }) => {
	const [pageNumber, setPageNumber] = useState(null);
	const [pdf, setPdf] = useState(null);

	const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

	const onDocumentLoadSuccess = ({ numPages }) => setPageNumber(numPages);

	const { loading, error } = getPDF({
		document_id: pdfId,
		resolve: (data) => {
			data.blob().then((blob) => {
				setPdf(URL.createObjectURL(blob));
			});
		},
	});

	const renderPages = () => {
		const pages = [];
		for (let i = 1; i <= pageNumber; i++) {
			pages.push(<Page loading='' key={i} pageNumber={i} />);
		}
		return pages;
	};

	const actionButtons = useMemo(
		() => (
			<>
				<Button margin='0 -20px 0 0' variant='cancel' onClick={onClose}>
					Cancel
				</Button>
				<Anchor href={pdf} download={`${title}.pdf`} title={title}>
					<Button variant='primary'>Download PDF</Button>
				</Anchor>
			</>
		),
		[pdf]
	);

	return (
		<ModalContainer>
			<Modal
				isOpen={isOpen}
				onRequestClose={onClose}
				isPortrait={isPortrait}
				actionButtons={actionButtons}
				loading={loading}
				style={autoWidthModalStyle}
				isEmpty={error}
			>
				{pdf && !error && (
					<>
						<PDFContainer>
							<Document
								file={pdf}
								onLoadSuccess={onDocumentLoadSuccess}
								className='pdf-container'
							>
								{pageNumber && renderPages()}
							</Document>
						</PDFContainer>
					</>
				)}
				{error && (
					<EmptyContainer>
						<IconBackground>
							<Icon src={pdfIcon} />
						</IconBackground>
						<NoPDF>There seems to be no PDF uploaded</NoPDF>
					</EmptyContainer>
				)}
			</Modal>
		</ModalContainer>
	);
};

export default PDFModal;
