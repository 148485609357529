import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 170px;
	max-width: 90%;
	width: 1000px;
	position: relative;

	img {
		height: 61px;
	}
`;

export const Message = styled.p`
	font-weight: 800;
	color: ${colors.navy};
	${fontStyles[fontSizes.eighteen]};
	text-align: center;
	margin-top: 14px;
`;
