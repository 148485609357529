export const biometrics = {
	account_id: 'accountId',
	age: 'age',
	anaerobic_threshold: 'anaerobicThreshold',
	arterial_thickness: 'arterialThickness',
	blood_pressure_diastolic: 'bloodPressureDiastolic',
	blood_pressure_systolic: 'bloodPressureSystolic',
	blood_pressure_indicator: 'bloodPressureIndicator',
	fat_mass: 'fatMass',
	height: 'height',
	max_wattage: 'maxWattage',
	muscle_mass: 'muscleMass',
	peak_heart_rate: 'peakHeartRate',
	resting_heart_rate: 'restingHeartRate',
	resting_heart_rate_indicator: 'restingHeartRateIndicator',
	total_body_fat: 'totalBodyFat',
	total_body_fat_indicator: 'totalBodyFatIndicator',
	upper_torso_fat: 'upperTorsoFat',
	vo2_max: 'vo2Max',
	vo2_max_indicator: 'vo2MaxIndicator',
	waist_hip_ratio: 'waistHipRatio',
	waist_hip_ratio_indicator: 'waistHipRatioIndicator',
	weight: 'weight',
};
