import { toast } from 'react-toastify';

// Toast notifications
export const PASSWORD_SHORT_ERR =
	'Password must contain at least 8 characters, one uppercase, one lowercase, one special char, one number';
export const REQUIRED_USERNAME_PASSWORD = 'Username and password are required';
export const PASSWORD_NOT_MATCH_ERR = 'Password does not match';
export const PASSWORD_REQUIRED = 'Password is required';
export const USERNAME_REQUIRED = 'Username is required';

export const INVALID_PHONE =
	'Phone number is invalid. Follow the (xxx) xxx-xxxx format';

export const WRONG_EMAIL_FORMAT = 'Email format is not correct';
export const REQUIRED_EMAIL = 'Email is required';

export const SUCCESS_REGISTRATION = 'Registered successfully';
export const REGISTER_LINK_USED = 'Registration Link is already used. ';
export const VALIDATION_ERROR_MSG =
	'There is a problem with your registration. Please contact Cenegenics support at info@cenegencis.com';

export const IMAGE_UPLOAD_SUCCESS = 'Picture uploaded successfully';
export const IMAGE_UPLOAD_FAILED = 'Picture upload failed';
export const IMAGE_REMOVE_FAILED = 'Picture removing failed';
export const IMAGE_REMOVE_SUCCESS = 'Successfully removed picture';

export const SUCCESS_CHANGE_PASSWORD = 'Successfully changed password';
export const SUCCESS_UPDATED_USER = 'Your changes have been saved.';
export const INVALID_CREDENTIALS_ERR = 'Username or password is incorrect';

export const ERROR_GET_USER = 'Failed fetching User data';
export const ERROR_UPDATING_USER = 'Error with Updating user';

// Toast positions
export const BOTTOM_LEFT = toast.POSITION.BOTTOM_LEFT;
export const TOP_CENTER = toast.POSITION.TOP_CENTER;

// Page Main and Sub Titles
export const ACCOUNT_MAIN_TITLE = 'Account Settings';
export const ACCOUNT_SUB_TITLE =
	'Here you can adjust your personal information and your account settings';

export const HEALTH_MAIN_TITLE = 'Health Tracking';
export const HEALTH_SUB_TITLE =
	'Here you can view your test results, see analytics and track your health progress';

export const MY_CARE_TEAM_TITLE = 'My Care Team';
export const MY_CARE_TEAM_SUB_TITLE =
	'Team of physicians, health coaches, and service coordinators dedicated to improving your health';

export const MESSAGING_TITLE = 'Messaging';
export const MESSAGING_SUB_TITLE =
	'This is your inbox. You can find all your conversations here and start a new one if you like.';

export const TEST_RESULTS_TITLE = 'Test Results';
export const TEST_RESULTS_SUBTITLE =
	'Here you can view and download a PDF of your test results';

export const PRODUCTS_TITLE = 'My Products';
export const PRODUCTS_SUBTITLE =
	'Here you can find a list of all the products that you have ordered';
export const PRODUCTS_SEND_GENERIC_MESSAGE = 'Send a Message';

// Send generic message modal
export const SEND_GENERIC_MESSAGE_TITLE = 'Send a Message';
export const SEND_GENERIC_MESSAGE_SUBTITLE =
	'What do you need assistance with?';
export const SEND_GENERIC_MESSAGE_CENEGENICS_USERNAME = 'Cenegenics';

// SendBird Modal
export const DELETE_MODAL_TITLE = 'Do you want to delete this message?';

// Generic message
export const GENERIC_ERROR_MESSAGE =
	'Something went wrong, please try again later...';

// REGEX
// Check email format
export const EMAIL_RGX =
	/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
// Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
export const PASSWORD_RGX =
	/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%.,\(\)^#*_+?&\-])[A-Za-z\d@$!.,#\(\)^_+%*?&\-]{8,}$/;

// Valid characters for a phone number
export const PHONE_RGX = /^[\d\-\(\)\+ ]*$/;

// Sendbird UNIQUE_HANDLER_ID for event handler
export const CHANNEL_HANDLER_ID = 'Channel_Hanndler_001';

// Google analytics ID
export const GOOGLE_ANALYTICS_ID = 'G-ZS7RF7F65P';
