export const sendMessageOptions = {
	program_support: 'Program support',
	my_health: 'My health',
	nutrition_fitness_lifestyle: 'Nutrition, Fitness or Lifestyle',
	labresults_prescriptions_clinicalcare:
		'Lab results, Prescriptions or Clinical care',
};

export const sendMessageButtonsLabels = {
	program_support: sendMessageOptions.program_support,
	my_health: sendMessageOptions.my_health,
	healthOptions: [
		sendMessageOptions.nutrition_fitness_lifestyle,
		sendMessageOptions.labresults_prescriptions_clinicalcare,
	],
};
