import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSendbirdStateContext, sendBirdSelectors } from 'sendbird-uikit';

import { useGetMyProducts } from 'api/services';
import boxIcon from 'assets/svg/icons/box.svg';
import sendIcon from 'assets/svg/icons/send.svg';
import Button from 'common/Button/Button';
import Titles from 'common/Titles/Titles';
import ProductCard from 'components/ProductCard/ProductCard';
import MyProductsLoader from 'components/Skeleton/MyProductsLoader/MyProductsLoader';
import { PRODUCTS_TITLE, PRODUCTS_SUBTITLE } from 'utils/constants';
import { handleCreateChannel, cenegenicsID } from 'utils/sendBirdHelpers';

import {
	Span,
	Container,
	Tabs,
	Tab,
	GenericMessage,
	Message,
	CardsContainer,
	SubHeading,
	Icon,
	IconWrapper,
	EmptyContainer,
	EmptyTitle,
	EmptySubtitle,
} from './MyProducts.styled';

const MyProducts = () => {
	const history = useHistory();
	const context = useSendbirdStateContext();
	const dispatch = useDispatch();

	const { data, loading } = useGetMyProducts();
	const [activeTab, setActiveTab] = useState('recurring');
	const { myCareTeam: teamData } = useSelector((state) => state.user);

	const createChannelChat = async () => {
		let teamMemberName = cenegenicsID;
		let teamMemberID = cenegenicsID;

		const serviceCoordinator = teamData.find(
			(elem) => elem.key === 'service_coordinator'
		);

		if (serviceCoordinator) {
			teamMemberName = serviceCoordinator.full_name;
			teamMemberID = serviceCoordinator.id;
		}

		const sdk = sendBirdSelectors.getSdk(context);
		const createChannel = sendBirdSelectors.getCreateChannel(context);

		if (sdk && sdk.GroupChannelParams) {
			const channelUrl = await handleCreateChannel(
				sdk,
				createChannel,
				teamMemberName,
				[teamMemberID]
			);

			dispatch.messaging.update({ channelUrl });
			history.push('/messaging');
		}
	};

	return (
		<Container>
			{loading ? (
				<MyProductsLoader />
			) : (
				<>
					<Titles mainTitle={PRODUCTS_TITLE} subTitle={PRODUCTS_SUBTITLE} />

					<SubHeading>
						<Tabs>
							<Tab
								isActive={activeTab === 'recurring'}
								onClick={() => setActiveTab('recurring')}
							>
								<Span>Recurring</Span>
							</Tab>

							<Tab
								isActive={activeTab === 'extras'}
								onClick={() => setActiveTab('extras')}
							>
								<Span>Extras</Span>
							</Tab>
						</Tabs>

						<GenericMessage>
							<Message>Need to change something in your products?</Message>

							<Button
								variant='primary'
								icon={sendIcon}
								id='sendMsgBtn'
								onClick={createChannelChat}
							>
								Send a Message
							</Button>
						</GenericMessage>
					</SubHeading>

					{data && data[activeTab].length ? (
						<CardsContainer>
							{data[activeTab].map((product) => (
								<ProductCard
									key={product.document_id}
									title={product.title}
									category={product.category}
									packaging={product.package}
									usage={product.usage}
									lastRefill={product.last_refill}
									nextRefill={product.next_refill}
									productId={product.document_id}
								/>
							))}
						</CardsContainer>
					) : (
						<EmptyContainer>
							<IconWrapper>
								<Icon src={boxIcon} />
							</IconWrapper>
							<EmptyTitle>
								Seems like there are no products in
								<br />
								your list yet
							</EmptyTitle>
							<EmptySubtitle>
								Please contact Cenegenics to add products
							</EmptySubtitle>
						</EmptyContainer>
					)}
				</>
			)}
		</Container>
	);
};

export default MyProducts;
