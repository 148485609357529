import React from 'react';

import { Router as BrowserRouter, Switch, Route } from 'react-router-dom';

import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import RouteGuard from 'components/RouteGuard';
import AccountSettings from 'pages/AccountSettings/AccountSettings';
import ForgotPassword from 'pages/ForgotPassword/ForgotPassword';
import ForgotPasswordSuccess from 'pages/ForgotPassword/ForgotPasswordSuccess/ForgotPasswordSuccess';
import HealthTracking from 'pages/HealthTracking/HealthTracking';
import Login from 'pages/Login/Login';
import Messaging from 'pages/Messaging/Messaging';
import MyCareTeam from 'pages/MyCareTeam/MyCareTeam';
import MyProducts from 'pages/MyProducts/MyProducts';
import PrivacyPolicy from 'pages/PrivacyPolicy/PrivacyPolicy';
import Registration from 'pages/Registration/Registration';
import TestResults from 'pages/TestResults/TestResults';

const Router = ({ history, location }) => (
	<BrowserRouter history={history} location={location}>
		<Switch>
			<PrivateRoute path='/' exact>
				<MyCareTeam />
			</PrivateRoute>

			<PrivateRoute path='/health'>
				<HealthTracking />
			</PrivateRoute>

			<PrivateRoute path='/tests'>
				<TestResults />
			</PrivateRoute>

			{/* TODO: Disabled until requirements arrives from Product Owner
			<PrivateRoute path='/appointments'>
				<Appointments />
			</PrivateRoute> */}

			<PrivateRoute path='/products'>
				<MyProducts />
			</PrivateRoute>

			<PrivateRoute path='/profile'>
				<AccountSettings />
			</PrivateRoute>

			<PrivateRoute path='/messaging'>
				<Messaging />
			</PrivateRoute>

			<PrivateRoute path='/privacy-policy'>
				<PrivacyPolicy />
			</PrivateRoute>

			<Route path='/registration/:userId'>
				<Registration />
			</Route>

			<Route path='/login'>
				<Login location={location} />
			</Route>

			<Route path='/forgot-password'>
				<ForgotPassword />
			</Route>

			<Route path='/forgot-password-success'>
				<ForgotPasswordSuccess />
			</Route>

			<Route path='*'>
				<RouteGuard />
			</Route>
		</Switch>
	</BrowserRouter>
);

export default Router;
