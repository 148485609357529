// Body composition
export const BODY_COMPOSITION_EMPTY_TEXT =
	'Seems like there are no body composition parameters in your biometrics yet';
export const BODY_COMPOSITION_EMPTY_SUB_TEXT =
	'Please contact Cenegenics to add your biometrics';

// Chart empty state
export const CHART_EMPTY_TEXT =
	'Seems like there is no graphical representation of your biometrics';
export const CHART_EMPTY_SUB_TEXT =
	'Please contact Cenegenics to add your graphs';

// My care team
export const CARE_TEAM_EMPTY_TEXT =
	'Seems like you are missing your dedicated care team members';
export const CARE_TEAM_EMPTY_SUB_TEXT =
	'Please contact Cenegenics to add your care team members';
