import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Container = styled.div`
	background: ${colors.navy60};
	color: white;
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	left: 0px;
	position: fixed;
	width: 100%;
	z-index: 999;
	padding: 20px 40px 20px 80px;
	bottom: 0px;

	@media ${device.tablet} {
		padding: 20px 40px;
	}

	@media ${device.mobileL} {
		padding: 10px 20px;
	}
`;

export const TextContainer = styled.div`
	max-width: 75%;

	@media ${device.tablet} {
		max-width: 100%;
	}
`;

export const Heading = styled.div`
	font-weight: 800;
	${fontStyles[fontSizes.sixteen]};
	margin-bottom: 10px;
`;

export const Text = styled.div`
	${fontStyles[fontSizes.fourteen]};
	line-height: 24px;

	@media ${device.mobileL} {
		font-size: 10px;
		line-height: 15px;
	}
`;

export const Link = styled.a`
	font-weight: 800;
	text-decoration: none;
	color: ${colors.white};

	&:hover {
		color: ${colors.green};
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
	align-self: flex-end;
	margin-left: auto;

	@media ${device.tablet} {
		margin: 20px auto 0;
	}
`;
