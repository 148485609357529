import React from 'react';

import arrowLeft from 'assets/svg/icons/arrow-left.svg';

import { BackButtonContainer, BackButton } from './BackToChat.styled';

const BackToChat = (props) => {
	const { setCurrentChannelUrl } = props;

	return (
		<BackButtonContainer>
			<BackButton onClick={() => setCurrentChannelUrl('')}>
				<img src={arrowLeft} alt='Arrow left' />
				<span>Back To Messaging</span>
			</BackButton>
		</BackButtonContainer>
	);
};

export default BackToChat;
