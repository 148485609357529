export const policyConsentTimestamp = 'CenegenicsPolicyTimestamp';
export const policyConsentName = 'CenegenicsPolicyConsent';
// Value to be used in redux together with policyConsentName
// as key, and this values represent it's value
export const policyNameAccepted = 'accepted';

/**
 * @summary Timestamp when Policy is changed
 * @example policyConsentUpdatesTimestamp('September 12, 2021');
 * @param {String} date
 * @returns {Number} timestamp when Policy is going to be updated
 */
export const policyConsentUpdatesTimestamp = (date) => {
	return new Date(date).getTime();
};
