import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { sendBirdSelectors, useSendbirdStateContext } from 'sendbird-uikit';

import useChannels from 'hooks/useChannels';
import useConnectionStatus from 'hooks/useConnectionStatus';
import { handleCreateChannel } from 'utils/sendBirdHelpers';

const useChannelManager = () => {
	const context = useSendbirdStateContext();
	const sdk = sendBirdSelectors.getSdk(context);
	const createChannel = sendBirdSelectors.getCreateChannel(context);
	const status = useConnectionStatus();
	const [channels] = useChannels();
	const user = useSelector((state) => state.user);

	useEffect(async () => {
		if (process.env.NODE_ENV === 'development') {
			console.log('connection_manager.connection_status:', status);
		}
		if (status == 'Connected') {
			const me = user.userId;
			if (!user.myCareTeam) return;
			let team = user.myCareTeam.filter((el) => el.user_in_sendbird);
			team = Array.from(new Set(team.map((el) => el.id)));
			team.push(`${process.env.REACT_APP_USER_ID_SENDBIRD}`);

			let activeMembers = [];
			activeMembers = Array.from(
				new Set(
					channels
						.map((ch) => ch.members)
						.filter((marry) => marry)
						.flatMap((marry) =>
							marry.map((m) => m.userId).filter((id) => id != me)
						)
				)
			);
			const toCreate = team.filter((el) => !activeMembers.includes(el));
			// const toFreeze = activeMembers.filter((el) => !team.includes(el));

			toCreate.forEach(async (el) => {
				await handleCreateChannel(sdk, createChannel, null, [el]);
			});
		}
	}, [status, user.myCareTeam, channels]);
	return status;
};

export default useChannelManager;
