import React from 'react';

import {
	Section,
	Heading,
	Title,
	Description,
	List,
	ListItem,
	Link,
	Strong,
} from './PrivacyPolicy.styled';

const PrivacyPolicy = () => {
	return (
		<Section>
			<Heading>Privacy Policy</Heading>

			<Title>Intro</Title>
			<Description>
				This notice describes BestLife Holdings, Inc. DBA Cenegenics
				(“Cenegenics”, “we” or “our”) privacy policy. By visiting Cenegenics.com
				(“Website”), you are accepting the practices described in this Privacy
				Notice.
			</Description>

			<Title>1. Personal Information</Title>
			<Description>
				The information we learn from clients helps us personalize and
				continually improve our provision of services via the Website. Here are
				the types of information we gather:
			</Description>
			<List>
				<ListItem>
					Information You Give Us: We receive and store any information you
					enter on our Website or give us in any other way. You can choose not
					to provide certain information, but then you might not be able to take
					advantage of many of the Website’s features nor receive important
					information from us. We use the information that you provide for such
					purposes as responding to your requests, communicating with providers
					and physicians in our provider network, and communicating with you.
				</ListItem>
				<ListItem>
					Automatic Information: We receive and store certain types of
					information whenever you interact with us. For example, like many
					Websites, we use “cookies,” and we obtain certain types of information
					when your Web browser accesses Cenegenics.com or advertisements and
					other content served by or on behalf of Cenegenics.com on other Web
					sites.
				</ListItem>
				<ListItem>
					E-mail Communications: To help us make e-mails more useful and
					interesting, we often receive a confirmation when you open e-mail from
					Cenegenics.com if your computer supports such capabilities.
				</ListItem>
				<ListItem>
					Information from Other Sources: We might receive information about you
					from other sources and add it to our account information. Examples of
					this information include the identity of any provider or physician to
					whom we assign you, and information about you that a provider or
					physician gives us. Some of this information may be defined as
					“individually identifiable health information” and/or “protected
					health information” (PHI) as those terms are defined by the Health
					Insurance Portability and Accountability Act (“HIPAA”) (see
					“definitions” found at 45 C.F.R. § 160.103). Cenegenics does not store
					or maintain information or data that is or may be considered PHI.
					Cenegenics utilizes third party service providers to collect and store
					any data that it receives from clients or potential clients, whether
					through the Website, email, or other electronic transmission(s).
					Cenegenics does not store or maintain such data in any storage device
					nor service (such as the “Cloud”) that is under its control.
					Accordingly, your transmission of any PHI to Cenegenics is incidental
					to the services Cenegenics performs for its clients, and any such
					information cannot be accessed by or through the Website. Cenegenics
					and Cenegenics.com are not covered by HIPAA.
				</ListItem>
			</List>

			<Title>2. Cookies</Title>
			<List>
				<ListItem>
					Cookies are unique identifiers that we transfer to your device to
					enable our systems to recognize your device and to provide features
					such as faster page access, and personalized advertisements on other
					web sites (e.g., Cenegenics’ business associates with content served
					by Cenegenics.com, and other web sites that carry links to
					Cenegenics.com.)
				</ListItem>
				<ListItem>
					The Help feature on most browsers will tell you how to prevent your
					browser from accepting new cookies, how to have the browser notify you
					when you receive a new cookie, or how to disable cookies altogether.
					Additionally, you can disable or delete similar data used by browser
					add-ons, such as Flash cookies, by changing the add-on’s settings or
					visiting the Web site of its manufacturer. Because cookies allow you
					to take advantage of some of Cenegenics.com’s essential features, we
					recommend that you leave them turned on. For instance, if you block or
					otherwise reject our cookies, you will likely experience slower
					service on the Website, and/or will be unable to access services that
					require you to Sign in.
				</ListItem>
			</List>

			<Title>3. Sharing Information</Title>
			<Description>
				Information about our customers is an important part of our business,
				and we are not in the business of selling it to others. We share
				customer information only as described below.
			</Description>
			<List>
				<ListItem>
					Third-Party Service Providers: We employ other companies and
					individuals to perform functions on our behalf. Examples include
					analyzing data, providing marketing assistance, providing search
					results and links (including paid listings and links), processing
					credit card payments, and providing customer service. They have access
					to personal information needed to perform their functions, but may not
					use it for other purposes.
				</ListItem>
				<ListItem>
					Promotional Offers: Sometimes we send offers to selected groups of
					Cenegenics.com customers on behalf of other businesses. When we do
					this, we do not give that business your name and address.
				</ListItem>
				<ListItem>
					Business Transfers: As we continue to develop our business, we might
					sell or buy businesses, subsidiaries, or business units. In such
					transactions, customer information generally is one of the transferred
					business assets but remains subject to the promises made in any
					pre-existing Privacy Notice (unless, of course, the customer consents
					otherwise). Also, in the unlikely event that Cenegenics.com, Inc., or
					substantially all of its assets are acquired, customer information
					will of course be one of the transferred assets.
				</ListItem>
				<ListItem>
					Protection of Cenegenics.com and Others: We release account and other
					personal information when we believe release is appropriate to comply
					with the law; enforce or apply our Conditions of Use and other
					agreements; or protect the rights, property, or safety of
					Cenegenics.com, our users, or others. This includes exchanging
					information with other companies and organizations for fraud
					protection and credit risk reduction. Obviously, however, this does
					not include selling, renting, sharing, or otherwise disclosing
					personally identifiable information from customers for commercial
					purposes in violation of the commitments set forth in this Privacy
					Notice.
				</ListItem>
				<ListItem>
					With Your Consent: Other than as set out above, you will receive
					notice when information about you might go to third parties, and you
					will have an opportunity to choose not to share the information.
				</ListItem>
			</List>

			<Title>4. Information Security</Title>
			<List>
				<ListItem>
					We work to protect the security of your information during
					transmission by using Secure Sockets Layer (SSL) software, which
					encrypts information you input.
				</ListItem>
				<ListItem>
					It is important for you to protect against unauthorized access to your
					password and to your computer. Be sure to sign off when finished using
					a shared computer.
				</ListItem>
			</List>

			<Title>5. Third-party Links</Title>
			<Description>
				Our site includes third-party advertising and links to other websites.
			</Description>

			<Title>6. Accessing Information</Title>
			<Description>
				Cenegenics.com gives you access to a range of information about your
				account and your interactions with Cenegenics.com for the limited
				purpose of viewing and, in certain cases, updating that information.
			</Description>

			<Title>7. Privacy Choices</Title>
			<List>
				<ListItem>
					As discussed above, you can always choose not to provide information,
					even though it might be needed to obtain services from Cenegenics, or
					take advantage of Cenegenics.com features.
				</ListItem>
				<ListItem>
					You can add or update certain information in your account. When you
					update information, we usually keep a copy of the prior version for
					our records.
				</ListItem>
				<ListItem>
					If you do not want to receive e-mail or other mail from us, please use
					the “unsubscribe” feature in any email you receive from us. If you do
					not want to receive Conditions of Use and other legal notices from us,
					such as this Privacy Notice, those notices will still govern your use
					of Cenegenics.com, and it is your responsibility to review them for
					changes.
				</ListItem>
				<ListItem>
					The Help feature on most browsers will tell you how to prevent your
					browser from accepting new cookies, how to have the browser notify you
					when you receive a new cookie, or how to disable cookies altogether.
					Additionally, you can disable or delete similar data used by browser
					add-ons, such as Flash cookies, by changing the add-on’s settings or
					visiting the website of its manufacturer.
				</ListItem>
			</List>

			<Title>8. Accessing & Editing Information</Title>
			<Description>
				If you would like to review, correct, update, restrict, or delete your
				personal information, or if you would like to request to receive an
				electronic copy of your personal information for purposes of
				transmitting it to another company (to the extent these rights are
				provided to you by applicable law), please contact us via visiting our
				Contact Us page (
				<Link
					href='https://cenegenics.com/contact-us/'
					target='_blank'
					rel='noreferrer'
				>
					https://cenegenics.com/contact-us/
				</Link>
				). We will respond to your request as soon as reasonably practicable and
				no later than one month after receipt. If circumstances cause any delay
				in our response, you will be promptly notified and provided a date for
				our response.
			</Description>

			<Title>9. Children under 18 years of age</Title>
			<Description>
				Cenegenics.com does not sell products or services for purchase by
				children. If you are under 18, you may use Cenegenics.com only with the
				involvement of a parent or guardian. We do not knowingly collect
				personal information from children under the age of 18.
			</Description>

			<Title>10. California Privacy Rights Notice</Title>
			<Description>
				Pursuant to California Civil Code section 1798.83, you have the right
				once per calendar year to request disclosure of what kind of personal
				information has been collected about you and shared with any third
				parties for a third party’s direct marketing use, as well as the names
				and addresses of those third parties with which the information has been
				shared.
			</Description>

			<Title>11. EU-US and Swiss-US Privacy Shield</Title>
			<Description>
				Cenegenics.com, BestLife Holdings, Inc. DBA Cenegenics, does not
				participate in the EU-US and Swiss-US Privacy Shield frameworks.
			</Description>

			<Title>12. Conditions of Use, Notices, and Revisions</Title>
			<Description>
				If you choose to visit Cenegenics.com, your visit and any dispute over
				privacy is subject to this Privacy Notice and our Terms of Use,
				including limitations on damages, resolution of disputes, and
				application of the law of the state of Nevada. If you have any concern
				about privacy at Cenegenics.com, please contact us with a thorough
				description, and we will try to resolve it. Our business changes
				constantly, and our Privacy Notice and the Terms of Use will change
				also. You should check the Website frequently to see recent changes.
				Unless stated otherwise, our current Privacy Notice applies to all
				information that we have about you and your account.
			</Description>

			<Title>13. Data Privacy FAQs</Title>
			<Description>
				For any further questions about data privacy, please email or send your
				inquiry to Cenegenics at the following address:
				<Strong>Roxana Boboescu, Director of Analytics</Strong>
				BestLife Holdings, Inc. DBA Cenegenics
				<br />
				6830 Spencer Street
				<br />
				Suite 102
				<br />
				Las Vegas, Nevada 89119
				<br />
				Email:&nbsp;
				<Link href='mailto:dataprivacy@cenegenics.com'>
					dataprivacy@cenegenics.com
				</Link>
			</Description>
		</Section>
	);
};

export default PrivacyPolicy;
