import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';
import { fontStyles, fontSizes } from 'styles/fonts';

export const Container = styled.div`
	padding: 28px;
`;

export const Description = styled.div`
	${fontStyles[fontSizes.eighteen]};
	color: ${colors.darkGrey};
	margin-top: 30px;
`;

export const CardsContainer = styled.div`
	margin-top: 30px;

	.masonry-grid {
		display: flex;
		margin-left: -20px;
	}

	.masonry-grid_column {
		border-left: 20px solid transparent;
		background-clip: padding-box;

		& > div {
			margin-bottom: 20px;
			border-radius: 8px;
		}
	}
`;

export const EmptyContainer = styled.div`
	height: 60vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	color: ${colors.darkGrey};
	text-align: center;

	@media ${device.mobileL} {
		height: 50vh;
	}
`;

export const IconWrapper = styled.div`
	display: flex;
	width: 230px;
	height: 140px;
	border-radius: 13px;
	background: ${colors.softBlue};
	align-items: center;
	justify-content: center;
`;

export const Icon = styled.img``;

export const EmptyTitle = styled.div`
	margin: 20px 0;
	${fontStyles[fontSizes.eighteen]}
`;

export const EmptySubtitle = styled.div`
	${fontStyles[fontSizes.fourteen]}
`;
