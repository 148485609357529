import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { healthData, fitnessData } from 'utils/lists';

import HealthFitnessCards from './HealthFitnessCards/HealthFitnessCards';

const HealthFitness = ({ openChart }) => {
	const biometrics = useSelector((state) => state.biometrics);
	const dispatch = useDispatch();

	const firstRowHealth = healthData.slice(0, 2);
	const secondRowHealth = healthData.slice(2);

	const firstRowFitness = fitnessData.slice(0, 2);
	const secondRowFitness = fitnessData.slice(2);

	const handleOpenChart = (data) => {
		openChart();
		dispatch.chartData.update(data);
	};

	return (
		<>
			<HealthFitnessCards
				title='Fitness'
				firstRowData={firstRowFitness}
				secondRowData={secondRowFitness}
				data={biometrics}
				handleClick={handleOpenChart}
			/>

			<HealthFitnessCards
				title='Health'
				firstRowData={firstRowHealth}
				secondRowData={secondRowHealth}
				data={biometrics}
				handleClick={handleOpenChart}
			/>
		</>
	);
};

export default HealthFitness;
