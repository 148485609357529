import React from 'react';

import EmailBox from 'assets/svg/illustrations/email-box.svg';
import ForgotPasswordLayout from 'layouts/ForgotPasswordLayout/ForgotPasswordLayout';

import {
	Heading,
	Description,
	Container,
	Image,
} from './ForgotPasswordSuccess.styled';

const ForgotPasswordSuccess = () => {
	return (
		<ForgotPasswordLayout>
			<Container>
				<Image src={EmailBox} />
				<Heading>Please check your email</Heading>
				<Description>We have sent you reset password instructions</Description>
			</Container>
		</ForgotPasswordLayout>
	);
};

export default ForgotPasswordSuccess;
