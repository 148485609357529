import styled from 'styled-components/macro';

import { colors } from 'styles/colors';

export const UserItem = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 7px;
	padding: 7px 0 13px 0;
	border-bottom: 1px solid ${colors.lightGrey};

	&:last-child {
		margin-bottom: 0;
	}
`;

export const UserDataContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const Checkbox = styled.input`
	height: 25px;
	width: 25px;
	cursor: pointer;
	border-radius: 4px;
	border: 3px solid ${colors.darkGrey};
`;
