import React from 'react';

import Password from 'common/Password/Password';
import { red80 } from 'styles/colors';

import { Container, FormGroup } from './PasswordChange.styled';

const PasswordChange = ({
	password,
	confirmPassword,
	isValid,
	handleChange,
}) => {
	return (
		<Container>
			<FormGroup>
				<Password
					name='password'
					value={password}
					isValid={isValid}
					labelText='New Password'
					errorColor={red80}
					handleChange={handleChange}
				/>
			</FormGroup>

			<FormGroup>
				<Password
					name='confirmPassword'
					value={confirmPassword}
					isValid={isValid}
					labelText='Confirm Password'
					errorColor={red80}
					handleChange={handleChange}
				/>
			</FormGroup>
		</Container>
	);
};

export default PasswordChange;
