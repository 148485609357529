import styled from 'styled-components/macro';

import SafeImage from 'components/Image/SafeImage';
import { colors } from 'styles/colors';
import { device } from 'styles/device';
import { fontSizes, fontStyles } from 'styles/fonts';

export const ChannelsContainer = styled.div`
	overflow: auto;
	width: 350px;
	min-width: 350px;
	background: ${colors.white};
	position: relative;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;

	@media ${device.mobileXL} {
		width: 100%;
		min-width: 100%;
		border-radius: 10px;
	}
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	width: 33%;
`;

export const ListHeader = styled.div`
	position: absolute;
	z-index: 10;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 8px;
	height: 64px;
	min-height: 64px;
	background: ${colors.white};
	border-bottom: 1px solid ${colors.green20};
`;

export const UserDetails = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 100%;
	padding-left: 20px;
`;

export const CreateGroupBtn = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	margin-right: 14px;
`;

export const ButtonIcon = styled.button`
	background: transparent;
	border: none;
	outline: none;
	cursor: pointer;

	i {
		font-size: 20px;
	}
`;

export const Row = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	// 65px is container left i.e. avatar container width
	width: calc(100% - 65px);
`;

export const List = styled.ul`
	height: 100%;
	width: 350px;
	overflow: auto;
	padding-top: 64px;
	list-style-type: none;

	@media ${device.mobileXL} {
		width: 100%;
	}
`;

export const ChannelItem = styled.li`
	width: 100%;
	height: 130px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 15px;
	box-sizing: border-box;
	list-style-type: none;
	cursor: pointer;
	border-top: 1px solid transparent;
	border-bottom: 1px solid ${colors.green20};
	border-left: ${(props) =>
		props.selected ? '6px solid rgb(0, 145, 148);' : '6px solid transparent'};
	background: ${(props) =>
		props.selected ? colors.gradientGreen3 : 'transparent'};

	&:hover {
		background-color: ${colors.gradient};
	}

	${({ hasNewMessages }) =>
		hasNewMessages &&
		`
		   border-left: 6px solid ${colors.red80};
		`};
`;

export const ImageWrapper = styled.div`
	height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	position: relative;
`;

export const Image = styled(SafeImage)`
	width: 61px;
	height: 61px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
`;

export const ChannelName = styled.p`
	color: ${colors.navy};
	font-weight: 800;
	${fontStyles[fontSizes.fifteen]};
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-align: start;
`;

const onlineNotifySize = (usersLength) => {
	if (usersLength === 1) {
		return '15px';
	} else {
		return '12px';
	}
};

const onlineNotifyPosition = (usersLength) => {
	if (usersLength === 1) {
		return '0px';
	} else if (usersLength === 2) {
		return '19px';
	} else {
		return '30px';
	}
};

export const ImageOnlineNotifyBox = styled.div`
	position: relative;
`;

export const OnlineNotify = styled.span`
	background: ${colors.green80};
	border-radius: 33px;
	border: 1px solid ${colors.white};
	position: absolute;
	right: 5px;
	z-index: 1;
	height: ${({ numberOfAvatars }) => onlineNotifySize(numberOfAvatars)};
	width: ${({ numberOfAvatars }) => onlineNotifySize(numberOfAvatars)};
	bottom: ${({ numberOfAvatars }) => onlineNotifyPosition(numberOfAvatars)};
`;

export const MembersCounter = styled.div`
	${fontStyles[fontSizes.fourteen]};
	color: ${colors.darkGrey};
	margin-top: 28px;
	width: 100%;
	display: flex;
	justify-content: flex-end;

	span {
		margin-left: 3px;
	}
`;

export const DateLabel = styled.p`
	text-align: end;
	font-size: 13px;
	color: ${colors.darkGrey};
`;

export const LastMessage = styled.p`
	color: ${colors.darkGrey};
	overflow: hidden;
	text-align: start;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
`;

export const BaseData = styled(Column)`
	height: 100%;
	width: 50%;
	justify-content: center;
	align-items: start;
	margin-left: 14px;
`;

export const Avatars = styled.div`
	display: flex;
	flex-direction: row-reverse;
	width: 65px;
	height: 65px;
`;

const imgSize = (imgSizeLength) => {
	if (imgSizeLength <= 1) {
		return '100%';
	} else if (imgSizeLength === 2 || imgSizeLength === 3) {
		return `calc(${100 / imgSizeLength}% + 10px)`;
	} else {
		return `calc(33.33% + 10px)`;
	}
};

export const ChannelAvatar = styled.div`
	flex-grow: 1;
	flex-basis: 0;
	display: flex;

	&:not(:last-child) {
		margin-left: -14px;
	}

	& img {
		width: 100%;
		height: ${({ numberOfAvatars }) => imgSize(numberOfAvatars)};
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		align-self: center;
		border-radius: 50%;
		object-fit: cover;
	}
`;

export const MoreUsersCount = styled.div`
	position: relative;
	top: -20px;
	color: ${colors.navy};
`;
