import React from 'react';

import { Container, Label, Icon } from './Button.styled';

const Button = ({
	children,
	disabled,
	icon,
	id,
	margin,
	onClick,
	onSubmit,
	rounded,
	small,
	tabIndex = 0,
	type = 'button',
	variant,
	...rest
}) => {
	return (
		<Container
			disabled={disabled}
			icon={icon}
			id={id}
			margin={margin}
			onClick={!disabled ? onClick : undefined}
			onSubmit={onSubmit}
			rounded={rounded}
			small={small}
			tabIndex={tabIndex}
			type={type}
			variant={variant}
			{...rest}
		>
			{icon && <Icon src={icon} alt='Button icon' small={small} />}
			{children && (
				<Label disabled={disabled} variant={variant}>
					{children}
				</Label>
			)}
		</Container>
	);
};

export default Button;
