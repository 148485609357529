import cimtIcon from 'assets/svg/icons/cimt.svg';
import dexaIcon from 'assets/svg/icons/dexa.svg';
import labsIcon from 'assets/svg/icons/lab-flowchart.svg';
import lightningProductIcon from 'assets/svg/icons/lightningProduct.svg';
import otherProductsIcon from 'assets/svg/icons/otherProducts.svg';
import rxIcon from 'assets/svg/icons/rx.svg';
import spectracellIcon from 'assets/svg/icons/spectracell.svg';
import truageIcon from 'assets/svg/icons/truage.svg';
import universalIcon from 'assets/svg/icons/universal.svg';
import vitaminsIcon from 'assets/svg/icons/vitamins.svg';

export const resultIcons = {
	labs: labsIcon,
	dexa: dexaIcon,
	cimt: cimtIcon,
	spectracell: spectracellIcon,
	truage: truageIcon,
	universal: universalIcon,
};

export const productIcons = {
	rx: rxIcon,
	vitamins: vitaminsIcon,
	lightning: lightningProductIcon,
	other: otherProductsIcon,
};
