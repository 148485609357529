import { policyConsentName, policyConsentTimestamp } from 'utils/policyConsent';

const policyConsentState = {
	isPolicyAccepted: window.localStorage.getItem(policyConsentName) || '',
	policyAcceptedTimestamp:
		window.localStorage.getItem(policyConsentTimestamp) || null,
};

const policyConsent = {
	state: policyConsentState,
	reducers: {
		updatePolicyConsent: (state, payload) => {
			return {
				...state,
				isPolicyAccepted: payload,
			};
		},
		updatePolicyTimestamp: (state, payload) => {
			return {
				...state,
				policyAcceptedTimestamp: payload,
			};
		},
		clearPolicyConsent: (state) => {
			return {
				...state,
				isPolicyAccepted: '',
				policyAcceptedTimestamp: null,
			};
		},
	},
};

export default policyConsent;
