import React from 'react';

import brokenHeart from 'assets/svg/illustrations/broken_2.svg';

import { Container, Text, Email } from './ErrorFallback.styled';

export function ErrorFallback() {
	return (
		<Container>
			<img src={brokenHeart} alt='Bandaged heart' />
			<Text>
				It is not you, it is us and we are sorry...
				<br /> Please try again later because we are working really hard to
				address the issue.
			</Text>
			<Email>You can contact us at info@cenegenics.com</Email>
		</Container>
	);
}
