import React from 'react';

import ContentLoader from 'react-content-loader';

import {
	Container,
	LoaderContainer,
	HeadingContainer,
} from './MyCareTeamLoader.styled';

const staff = ['physician', 'healthCoach', 'serviceCoordinator'];

const Loader = (props) => (
	<Container>
		<ContentLoader
			speed={2}
			height={417}
			viewBox='0 0 311 417'
			backgroundColor='#f3f3f3'
			foregroundColor='#ecebeb'
			{...props}
		>
			<rect x='81' y='37' rx='74' ry='74' width='148' height='148' />
			<rect x='93' y='205' rx='4' ry='4' width='124' height='32' />
			<rect x='33' y='340' rx='4' ry='4' width='246' height='45' />
			<rect x='52' y='263' rx='4' ry='4' width='205' height='16' />
			<rect x='71' y='293' rx='4' ry='4' width='169' height='16' />
		</ContentLoader>
	</Container>
);

const Heading = () => (
	<HeadingContainer>
		<ContentLoader
			speed={2}
			width={853}
			height={144}
			viewBox='0 0 853 144'
			backgroundColor='#f3f3f3'
			foregroundColor='#ecebeb'
		>
			<rect x='0' y='111' rx='4' ry='4' width='429' height='21' />
			<rect x='457' y='99' rx='23' ry='23' width='283' height='45' />
			<rect x='0' y='46' rx='4' ry='4' width='853' height='21' />
			<rect x='0' y='0' rx='4' ry='4' width='160' height='32' />
		</ContentLoader>
	</HeadingContainer>
);

const MyCareTeamLoader = (props) => (
	<>
		<Heading />
		<LoaderContainer>
			{staff.map((member) => (
				<Loader key={member} {...props} />
			))}
		</LoaderContainer>
	</>
);

export default MyCareTeamLoader;
