import React, { useEffect } from 'react';

import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { useCheckAccount } from 'api/services';
import RegistrationForm from 'components/RegistrationForm/RegistrationForm';
import RegistrationLoader from 'components/Skeleton/RegistrationLoader/RegistrationLoader';
import AuthLayout from 'layouts/AuthLayout/AuthLayout';
import { showWarningNotify } from 'utils';
import { REGISTER_LINK_USED, BOTTOM_LEFT } from 'utils/constants';

const title = 'Welcome! Please register to continue to our platform.';

const Registration = () => {
	const history = useHistory();
	const { userId } = useParams();

	const { loading, error } = useCheckAccount({
		queryParams: { account: userId },
	});

	const showMessageAndRedirect = (errorMsg) => {
		showWarningNotify(errorMsg || REGISTER_LINK_USED, BOTTOM_LEFT);
		setTimeout(() => {
			history.push('/login');
		}, 5000);
	};

	useEffect(() => {
		if (error) {
			showMessageAndRedirect(error?.data?.message);
		}
	});

	return (
		<AuthLayout title={title}>
			{!loading ? (
				<RegistrationForm userId={userId} isLoading={loading} />
			) : (
				<RegistrationLoader />
			)}
		</AuthLayout>
	);
};

export default Registration;
