/* Generated by restful-react */

import React from "react";
import { Get, GetProps, useGet, UseGetProps, Mutate, MutateProps, useMutate, UseMutateProps } from "restful-react";
export const SPEC_VERSION = "1.0.0";
export interface Token {
  token: string;
}

export interface Login {
  username: string;
  password: string;
}

export interface Registration {
  username: string;
  password: string;
  confirm_password: string;
}

export interface Error {
  code: string;
  message: string;
}

export interface Email {
  email: string;
}

export interface Message {
  message: string;
}

export interface ChangePassword {
  password: string;
  confirm_password: string;
}

export interface UpdateUser {
  phone?: string;
  email?: string;
  username?: string;
}

export interface User {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  username: string;
  profile_picture: string;
  access_token?: string;
  physician?: string;
  health_coach?: string;
  service_coordinator?: string;
}

export interface Biometric {
  weight?: number;
  height?: number;
  total_body_fat?: number;
  muscle_mass?: number;
  fat_mass?: number;
  upper_torso_fat?: number;
  vo2_max?: number;
	vo2_max_indicator?: string;
  peak_heart_rate?: number;
  anaerobic_threshold?: number;
  max_wattage?: number;
  resting_heart_rate?: number;
	resting_heart_rate_indicator?: string;
  blood_pressure_systolic?: number;
  blood_pressure_diastolic?: number;
	blood_pressure_indicator?: string;
  arterial_thickness?: number;
  waist_hip_ratio?: number;
	waist_hip_ratio_indicator?: string;
}

export interface BiometricData {
  data?: {
  date?: string;
  value?: number;
}[];
}

export type TotalBodyFat = BiometricData & {
  referential_values?: {
  general?: {
  classification?: "Lean" | "Optimal" | "Average" | "Moderately Overweight" | "Overweight";
  value?: string;
}[];
  athlete?: {
  classification?: "Lean" | "Optimal" | "Average" | "Moderately Overweight" | "Overweight";
  value?: string;
}[];
};
};

export type VO2Max = BiometricData & {
  referential_values?: {
  rating?: "Excellent" | "Good" | "Average" | "Fair" | "Poor";
  value?: string;
}[];
  age_group?: "20-29" | "30-39" | "40-49" | "50-59" | "60+";
};

export interface BloodPressure {
  data?: {
  date?: string;
  systolic?: number;
  diastolic?: number;
}[];
  referential_values?: {
  rating?: "Normal" | "Pre-Hypertension" | "Stage 1 Hypertension" | "Stage 2 Hypertension";
  systolic?: string;
  diastolic?: string;
}[];
}

export type RestingHeartRate = BiometricData & {
  referential_values?: {
  rating?: "Excellent" | "Good" | "Average" | "Fair" | "Poor";
  score?: string;
}[];
};

export type WaistHipRatio = BiometricData & {
  referential_values?: {
  risk?: "Low" | "Moderate" | "High" | "Very High";
  value?: string;
}[];
  age_group?: "20-29" | "30-39" | "40-49" | "50-59" | "60-69";
};

/**
 * Unauthenticated
 */
export type UnauthenticatedResponse = Error;

/**
 * Unauthorized
 */
export type UnauthorizedResponse = Error;

/**
 * The specified resource was not found
 */
export type NotFoundResponse = Error;

/**
 * Login response for successfull login
 */
export type LoginResponseResponse = Token;

/**
 * Forgot password response
 */
export type ForgotPasswordResponseResponse = Message;

/**
 * Update user response
 */
export type UpdateUserResponse = UpdateUser;

/**
 * Get user response
 */
export type GetUserResponseResponse = User;

/**
 * Get user response
 */
export type GetBiometricsResponseResponse = Biometric;

/**
 * User biometric data response
 */
export type GetBiometricDataResponseResponse = BiometricData;

/**
 * Patient total body fat response
 */
export type GetTotalBodyFatResponseResponse = TotalBodyFat;

/**
 * Patient VO2 max response
 */
export type GetVO2MaxResponseResponse = VO2Max;

/**
 * Patient blood pressure response
 */
export type GetBloodPressureResponseResponse = BloodPressure;

/**
 * Patient resting heart rate response
 */
export type GetRestingHeartRateResponseResponse = RestingHeartRate;

/**
 * Patient waist to hip ratio response
 */
export type GetWaistHipRatioResponseResponse = WaistHipRatio;

/**
 * Get patient's care team response
 */
export interface GetMyCareTeamResponseResponse {
  physician?: {
  id?: string;
  full_name?: string;
  title?: string;
  city?: string;
  state?: string;
  photo?: string;
  user_in_sendbird?: boolean;
};
  health_coach?: {
  id?: string;
  full_name?: string;
  title?: string;
  city?: string;
  state?: string;
  photo?: string;
  user_in_sendbird?: boolean;
};
  service_coordinator?: {
  id?: string;
  full_name?: string;
  title?: string;
  city?: string;
  state?: string;
  photo?: string;
  user_in_sendbird?: boolean;
};
}

/**
 * Get patient's test results
 */
export type GetTestResultsResponseResponse = {
  title?: string;
  document_id?: string;
  description?: string;
  category?: "labs" | "dexa" | "cimt" | "spectracell" | "truage" | "universal";
}[];

export type GetTestResultsFileResponseResponse = GetTestResultsResponseResponse;

/**
 * Get my products
 */
export type GetMyProductsResponseResponse = {
  recurring?: {
  title?: string;
  document_id?: string;
  package?: string;
  usage?: string;
  next_refill?: string;
  last_refill?: string;
  category?: "rx" | "vitamins" | "lightning" | "other";
}[];
  extras?: {
  title?: string;
  document_id?: string;
  package?: string;
  usage?: string;
  next_refill?: string;
  last_refill?: string;
  category?: "rx" | "vitamins" | "lightning" | "other";
}[];
};

/**
 * Get my product file
 */
export type GetMyProductFileResponseResponse = void;

export interface HealthResponse {
  healthy: boolean;
}

export type HealthProps = Omit<GetProps<HealthResponse, void, void, void>, "path">;

/**
 * Health endpoint
 */
export const Health = (props: HealthProps) => (
  <Get<HealthResponse, void, void, void>
    path={`/health`}

    {...props}
  />
);

export type UseHealthProps = Omit<UseGetProps<HealthResponse, void, void, void>, "path">;

/**
 * Health endpoint
 */
export const useHealth = (props: UseHealthProps) => useGet<HealthResponse, void, void, void>(`/health`, props);


export type TriggerErrorProps = Omit<GetProps<void, void, void, void>, "path">;

/**
 * Sentry endpoint
 */
export const TriggerError = (props: TriggerErrorProps) => (
  <Get<void, void, void, void>
    path={`/debug-sentry`}

    {...props}
  />
);

export type UseTriggerErrorProps = Omit<UseGetProps<void, void, void, void>, "path">;

/**
 * Sentry endpoint
 */
export const useTriggerError = (props: UseTriggerErrorProps) => useGet<void, void, void, void>(`/debug-sentry`, props);


export interface RegisterQueryParams {
  account: string;
}

export type RegisterProps = Omit<MutateProps<void, void, RegisterQueryParams, Registration, void>, "path" | "verb">;

/**
 * Register a new user
 */
export const Register = (props: RegisterProps) => (
  <Mutate<void, void, RegisterQueryParams, Registration, void>
    verb="POST"
    path={`/auth/register`}

    {...props}
  />
);

export type UseRegisterProps = Omit<UseMutateProps<void, void, RegisterQueryParams, Registration, void>, "path" | "verb">;

/**
 * Register a new user
 */
export const useRegister = (props: UseRegisterProps) => useMutate<void, void, RegisterQueryParams, Registration, void>("POST", `/auth/register`, props);


export type LoginProps = Omit<MutateProps<LoginResponseResponse, void, void, Login, void>, "path" | "verb">;

/**
 * Login to the system
 */
export const Login = (props: LoginProps) => (
  <Mutate<LoginResponseResponse, void, void, Login, void>
    verb="POST"
    path={`/auth/login`}

    {...props}
  />
);

export type UseLoginProps = Omit<UseMutateProps<LoginResponseResponse, void, void, Login, void>, "path" | "verb">;

/**
 * Login to the system
 */
export const useLogin = (props: UseLoginProps) => useMutate<LoginResponseResponse, void, void, Login, void>("POST", `/auth/login`, props);


export type LogoutProps = Omit<GetProps<void, void | UnauthenticatedResponse, void, void>, "path">;

/**
 * Logout from the system
 */
export const Logout = (props: LogoutProps) => (
  <Get<void, void | UnauthenticatedResponse, void, void>
    path={`/auth/logout`}

    {...props}
  />
);

export type UseLogoutProps = Omit<UseGetProps<void, void | UnauthenticatedResponse, void, void>, "path">;

/**
 * Logout from the system
 */
export const useLogout = (props: UseLogoutProps) => useGet<void, void | UnauthenticatedResponse, void, void>(`/auth/logout`, props);


export interface CheckAccountQueryParams {
  account: string;
}

export type CheckAccountProps = Omit<GetProps<void, void, CheckAccountQueryParams, void>, "path">;

/**
 * Check for existing account in system
 */
export const CheckAccount = (props: CheckAccountProps) => (
  <Get<void, void, CheckAccountQueryParams, void>
    path={`/auth/check-account`}

    {...props}
  />
);

export type UseCheckAccountProps = Omit<UseGetProps<void, void, CheckAccountQueryParams, void>, "path">;

/**
 * Check for existing account in system
 */
export const useCheckAccount = (props: UseCheckAccountProps) => useGet<void, void, CheckAccountQueryParams, void>(`/auth/check-account`, props);


export type ForgotPasswordProps = Omit<MutateProps<ForgotPasswordResponseResponse, void, void, Email, void>, "path" | "verb">;

/**
 * Forgot password
 */
export const ForgotPassword = (props: ForgotPasswordProps) => (
  <Mutate<ForgotPasswordResponseResponse, void, void, Email, void>
    verb="POST"
    path={`/auth/forgot-password`}

    {...props}
  />
);

export type UseForgotPasswordProps = Omit<UseMutateProps<ForgotPasswordResponseResponse, void, void, Email, void>, "path" | "verb">;

/**
 * Forgot password
 */
export const useForgotPassword = (props: UseForgotPasswordProps) => useMutate<ForgotPasswordResponseResponse, void, void, Email, void>("POST", `/auth/forgot-password`, props);


export type UploadImageProps = Omit<MutateProps<void, void | UnauthenticatedResponse | UnauthorizedResponse, void, void, void>, "path" | "verb">;

/**
 * Upload profile image
 */
export const UploadImage = (props: UploadImageProps) => (
  <Mutate<void, void | UnauthenticatedResponse | UnauthorizedResponse, void, void, void>
    verb="POST"
    path={`/profile/upload`}

    {...props}
  />
);

export type UseUploadImageProps = Omit<UseMutateProps<void, void | UnauthenticatedResponse | UnauthorizedResponse, void, void, void>, "path" | "verb">;

/**
 * Upload profile image
 */
export const useUploadImage = (props: UseUploadImageProps) => useMutate<void, void | UnauthenticatedResponse | UnauthorizedResponse, void, void, void>("POST", `/profile/upload`, props);


export type GetAvatarProps = Omit<GetProps<void, UnauthenticatedResponse | UnauthorizedResponse | NotFoundResponse, void, void>, "path">;

/**
 * Get patient avatar image
 */
export const GetAvatar = (props: GetAvatarProps) => (
  <Get<void, UnauthenticatedResponse | UnauthorizedResponse | NotFoundResponse, void, void>
    path={`/profile/avatar`}

    {...props}
  />
);

export type UseGetAvatarProps = Omit<UseGetProps<void, UnauthenticatedResponse | UnauthorizedResponse | NotFoundResponse, void, void>, "path">;

/**
 * Get patient avatar image
 */
export const useGetAvatar = (props: UseGetAvatarProps) => useGet<void, UnauthenticatedResponse | UnauthorizedResponse | NotFoundResponse, void, void>(`/profile/avatar`, props);


export type GetUserProps = Omit<GetProps<GetUserResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get user information
 */
export const GetUser = (props: GetUserProps) => (
  <Get<GetUserResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/profile/me`}

    {...props}
  />
);

export type UseGetUserProps = Omit<UseGetProps<GetUserResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get user information
 */
export const useGetUser = (props: UseGetUserProps) => useGet<GetUserResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/profile/me`, props);


export type RemoveImageProps = Omit<MutateProps<void, void | UnauthenticatedResponse | UnauthorizedResponse, void, void, void>, "path" | "verb">;

/**
 * Remove profile image
 */
export const RemoveImage = (props: RemoveImageProps) => (
  <Mutate<void, void | UnauthenticatedResponse | UnauthorizedResponse, void, void, void>
    verb="DELETE"
    path={`/profile/remove-image`}

    {...props}
  />
);

export type UseRemoveImageProps = Omit<UseMutateProps<void, void | UnauthenticatedResponse | UnauthorizedResponse, void, void, void>, "path" | "verb">;

/**
 * Remove profile image
 */
export const useRemoveImage = (props: UseRemoveImageProps) => useMutate<void, void | UnauthenticatedResponse | UnauthorizedResponse, void, void, void>("DELETE", `/profile/remove-image`, {   ...props });


export type ChangePasswordProps = Omit<MutateProps<void, void, void, ChangePassword, void>, "path" | "verb">;

/**
 * Change user password
 */
export const ChangePassword = (props: ChangePasswordProps) => (
  <Mutate<void, void, void, ChangePassword, void>
    verb="POST"
    path={`/profile/change-password`}

    {...props}
  />
);

export type UseChangePasswordProps = Omit<UseMutateProps<void, void, void, ChangePassword, void>, "path" | "verb">;

/**
 * Change user password
 */
export const useChangePassword = (props: UseChangePasswordProps) => useMutate<void, void, void, ChangePassword, void>("POST", `/profile/change-password`, props);


export type UpdateUserProps = Omit<MutateProps<UpdateUserResponse, void | UnauthenticatedResponse | UnauthorizedResponse, void, UpdateUser, void>, "path" | "verb">;

/**
 * Update user's profile
 */
export const UpdateUser = (props: UpdateUserProps) => (
  <Mutate<UpdateUserResponse, void | UnauthenticatedResponse | UnauthorizedResponse, void, UpdateUser, void>
    verb="PATCH"
    path={`/profile/update-user`}

    {...props}
  />
);

export type UseUpdateUserProps = Omit<UseMutateProps<UpdateUserResponse, void | UnauthenticatedResponse | UnauthorizedResponse, void, UpdateUser, void>, "path" | "verb">;

/**
 * Update user's profile
 */
export const useUpdateUser = (props: UseUpdateUserProps) => useMutate<UpdateUserResponse, void | UnauthenticatedResponse | UnauthorizedResponse, void, UpdateUser, void>("PATCH", `/profile/update-user`, props);


export type GetBiometricsProps = Omit<GetProps<GetBiometricsResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get biometric informations
 */
export const GetBiometrics = (props: GetBiometricsProps) => (
  <Get<GetBiometricsResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/biometrics`}

    {...props}
  />
);

export type UseGetBiometricsProps = Omit<UseGetProps<GetBiometricsResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get biometric informations
 */
export const useGetBiometrics = (props: UseGetBiometricsProps) => useGet<GetBiometricsResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/biometrics`, props);


export type GetWeightProps = Omit<GetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get patiens weight data over time
 */
export const GetWeight = (props: GetWeightProps) => (
  <Get<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/weight`}

    {...props}
  />
);

export type UseGetWeightProps = Omit<UseGetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get patiens weight data over time
 */
export const useGetWeight = (props: UseGetWeightProps) => useGet<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/weight`, props);


export type GetTotalBodyFatProps = Omit<GetProps<GetTotalBodyFatResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get total body fat data
 */
export const GetTotalBodyFat = (props: GetTotalBodyFatProps) => (
  <Get<GetTotalBodyFatResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/total-body-fat`}

    {...props}
  />
);

export type UseGetTotalBodyFatProps = Omit<UseGetProps<GetTotalBodyFatResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get total body fat data
 */
export const useGetTotalBodyFat = (props: UseGetTotalBodyFatProps) => useGet<GetTotalBodyFatResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/total-body-fat`, props);


export type GetMuscleMassProps = Omit<GetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get muscle mass data
 */
export const GetMuscleMass = (props: GetMuscleMassProps) => (
  <Get<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/muscle-mass`}

    {...props}
  />
);

export type UseGetMuscleMassProps = Omit<UseGetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get muscle mass data
 */
export const useGetMuscleMass = (props: UseGetMuscleMassProps) => useGet<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/muscle-mass`, props);


export type GetFatMassProps = Omit<GetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get fat mass data
 */
export const GetFatMass = (props: GetFatMassProps) => (
  <Get<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/fat-mass`}

    {...props}
  />
);

export type UseGetFatMassProps = Omit<UseGetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get fat mass data
 */
export const useGetFatMass = (props: UseGetFatMassProps) => useGet<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/fat-mass`, props);


export type GetUpperTorsoFatProps = Omit<GetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get upper torso fat data
 */
export const GetUpperTorsoFat = (props: GetUpperTorsoFatProps) => (
  <Get<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/upper-torso-fat`}

    {...props}
  />
);

export type UseGetUpperTorsoFatProps = Omit<UseGetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get upper torso fat data
 */
export const useGetUpperTorsoFat = (props: UseGetUpperTorsoFatProps) => useGet<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/upper-torso-fat`, props);


export type GetVo2MaxProps = Omit<GetProps<GetVO2MaxResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get VO2 max data
 */
export const GetVo2Max = (props: GetVo2MaxProps) => (
  <Get<GetVO2MaxResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/vo2-max`}

    {...props}
  />
);

export type UseGetVo2MaxProps = Omit<UseGetProps<GetVO2MaxResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get VO2 max data
 */
export const useGetVo2Max = (props: UseGetVo2MaxProps) => useGet<GetVO2MaxResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/vo2-max`, props);


export type GetPeakHeartRateProps = Omit<GetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get peak heart rate data
 */
export const GetPeakHeartRate = (props: GetPeakHeartRateProps) => (
  <Get<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/peak-heart-rate`}

    {...props}
  />
);

export type UseGetPeakHeartRateProps = Omit<UseGetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get peak heart rate data
 */
export const useGetPeakHeartRate = (props: UseGetPeakHeartRateProps) => useGet<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/peak-heart-rate`, props);


export type GetAnaerobicThresholdProps = Omit<GetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get anaerobic threshold data
 */
export const GetAnaerobicThreshold = (props: GetAnaerobicThresholdProps) => (
  <Get<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/anaerobic-threshold`}

    {...props}
  />
);

export type UseGetAnaerobicThresholdProps = Omit<UseGetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get anaerobic threshold data
 */
export const useGetAnaerobicThreshold = (props: UseGetAnaerobicThresholdProps) => useGet<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/anaerobic-threshold`, props);


export type GetMaxWattageProps = Omit<GetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get max wattage data
 */
export const GetMaxWattage = (props: GetMaxWattageProps) => (
  <Get<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/max-wattage`}

    {...props}
  />
);

export type UseGetMaxWattageProps = Omit<UseGetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get max wattage data
 */
export const useGetMaxWattage = (props: UseGetMaxWattageProps) => useGet<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/max-wattage`, props);


export type GetRestingHeartRateProps = Omit<GetProps<GetRestingHeartRateResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get resting heart rate data
 */
export const GetRestingHeartRate = (props: GetRestingHeartRateProps) => (
  <Get<GetRestingHeartRateResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/resting-heart-rate`}

    {...props}
  />
);

export type UseGetRestingHeartRateProps = Omit<UseGetProps<GetRestingHeartRateResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get resting heart rate data
 */
export const useGetRestingHeartRate = (props: UseGetRestingHeartRateProps) => useGet<GetRestingHeartRateResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/resting-heart-rate`, props);


export type GetBloodPressureProps = Omit<GetProps<GetBloodPressureResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get blood pressure data
 */
export const GetBloodPressure = (props: GetBloodPressureProps) => (
  <Get<GetBloodPressureResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/blood-pressure`}

    {...props}
  />
);

export type UseGetBloodPressureProps = Omit<UseGetProps<GetBloodPressureResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get blood pressure data
 */
export const useGetBloodPressure = (props: UseGetBloodPressureProps) => useGet<GetBloodPressureResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/blood-pressure`, props);


export type GetWaistHipRatioProps = Omit<GetProps<GetWaistHipRatioResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get waist hip ratio data
 */
export const GetWaistHipRatio = (props: GetWaistHipRatioProps) => (
  <Get<GetWaistHipRatioResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/waist-hip-ratio`}

    {...props}
  />
);

export type UseGetWaistHipRatioProps = Omit<UseGetProps<GetWaistHipRatioResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get waist hip ratio data
 */
export const useGetWaistHipRatio = (props: UseGetWaistHipRatioProps) => useGet<GetWaistHipRatioResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/waist-hip-ratio`, props);


export type GetArterialThicknessProps = Omit<GetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get arterial thickness data
 */
export const GetArterialThickness = (props: GetArterialThicknessProps) => (
  <Get<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/arterial-thickness`}

    {...props}
  />
);

export type UseGetArterialThicknessProps = Omit<UseGetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get arterial thickness data
 */
export const useGetArterialThickness = (props: UseGetArterialThicknessProps) => useGet<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/arterial-thickness`, props);


export type GetMyCareTeamProps = Omit<GetProps<GetMyCareTeamResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get patient's care team data
 */
export const GetMyCareTeam = (props: GetMyCareTeamProps) => (
  <Get<GetMyCareTeamResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/my-care-team`}

    {...props}
  />
);

export type UseGetMyCareTeamProps = Omit<UseGetProps<GetMyCareTeamResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get patient's care team data
 */
export const useGetMyCareTeam = (props: UseGetMyCareTeamProps) => useGet<GetMyCareTeamResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/my-care-team`, props);


export type GetTestResultsProps = Omit<GetProps<GetTestResultsResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get patient's test results
 */
export const GetTestResults = (props: GetTestResultsProps) => (
  <Get<GetTestResultsResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/test-results`}

    {...props}
  />
);

export type UseGetTestResultsProps = Omit<UseGetProps<GetTestResultsResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get patient's test results
 */
export const useGetTestResults = (props: UseGetTestResultsProps) => useGet<GetTestResultsResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/test-results`, props);


export type SendbirdWebhookProps = Omit<MutateProps<void, void, void, void, void>, "path" | "verb">;

/**
 * Sendbird Webhook
 */
export const SendbirdWebhook = (props: SendbirdWebhookProps) => (
  <Mutate<void, void, void, void, void>
    verb="POST"
    path={`/sendbird-webhook`}

    {...props}
  />
);

export type UseSendbirdWebhookProps = Omit<UseMutateProps<void, void, void, void, void>, "path" | "verb">;

/**
 * Sendbird Webhook
 */
export const useSendbirdWebhook = (props: UseSendbirdWebhookProps) => useMutate<void, void, void, void, void>("POST", `/sendbird-webhook`, props);


export interface GetTestResultsFilePathParams {
  /**
   * String ID of the test results file
   */
  document_id: string
}

export type GetTestResultsFileProps = Omit<GetProps<GetTestResultsResponseResponse, UnauthenticatedResponse | UnauthorizedResponse | NotFoundResponse, void, GetTestResultsFilePathParams>, "path"> & GetTestResultsFilePathParams;

/**
 * Get a patient's test results file
 */
export const GetTestResultsFile = ({document_id, ...props}: GetTestResultsFileProps) => (
  <Get<GetTestResultsResponseResponse, UnauthenticatedResponse | UnauthorizedResponse | NotFoundResponse, void, GetTestResultsFilePathParams>
    path={`/test-results-file/${document_id}`}

    {...props}
  />
);

export type UseGetTestResultsFileProps = Omit<UseGetProps<GetTestResultsResponseResponse, UnauthenticatedResponse | UnauthorizedResponse | NotFoundResponse, void, GetTestResultsFilePathParams>, "path"> & GetTestResultsFilePathParams;

/**
 * Get a patient's test results file
 */
export const useGetTestResultsFile = ({document_id, ...props}: UseGetTestResultsFileProps) => useGet<GetTestResultsResponseResponse, UnauthenticatedResponse | UnauthorizedResponse | NotFoundResponse, void, GetTestResultsFilePathParams>((paramsInPath: GetTestResultsFilePathParams) => `/test-results-file/${paramsInPath.document_id}`, {  pathParams: { document_id }, ...props });


export type GetMyProductsProps = Omit<GetProps<GetMyProductsResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get my products
 */
export const GetMyProducts = (props: GetMyProductsProps) => (
  <Get<GetMyProductsResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/my-products`}

    {...props}
  />
);

export type UseGetMyProductsProps = Omit<UseGetProps<GetMyProductsResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get my products
 */
export const useGetMyProducts = (props: UseGetMyProductsProps) => useGet<GetMyProductsResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/my-products`, props);


export interface GetMyProductFilePathParams {
  /**
   * String ID of the my product file
   */
  document_id: string
}

export type GetMyProductFileProps = Omit<GetProps<GetMyProductFileResponseResponse, UnauthenticatedResponse | UnauthorizedResponse | NotFoundResponse, void, GetMyProductFilePathParams>, "path"> & GetMyProductFilePathParams;

/**
 * Get my product file
 */
export const GetMyProductFile = ({document_id, ...props}: GetMyProductFileProps) => (
  <Get<GetMyProductFileResponseResponse, UnauthenticatedResponse | UnauthorizedResponse | NotFoundResponse, void, GetMyProductFilePathParams>
    path={`/my-product-file/${document_id}`}

    {...props}
  />
);

export type UseGetMyProductFileProps = Omit<UseGetProps<GetMyProductFileResponseResponse, UnauthenticatedResponse | UnauthorizedResponse | NotFoundResponse, void, GetMyProductFilePathParams>, "path"> & GetMyProductFilePathParams;

/**
 * Get my product file
 */
export const useGetMyProductFile = ({document_id, ...props}: UseGetMyProductFileProps) => useGet<GetMyProductFileResponseResponse, UnauthenticatedResponse | UnauthorizedResponse | NotFoundResponse, void, GetMyProductFilePathParams>((paramsInPath: GetMyProductFilePathParams) => `/my-product-file/${paramsInPath.document_id}`, {  pathParams: { document_id }, ...props });

