import React from 'react';

import { Container, Route, Wrapper, Background, Text } from './Routes.styled';

const routes = [
	{
		url: '/',
		icon: 'users',
		label: 'My Care Team',
	},
	{
		url: '/health',
		icon: 'stats-up',
		label: 'Health Tracking',
	},
	{
		url: '/tests',
		icon: 'first-aid',
		label: 'Test Results',
	},
	{
		url: '/products',
		icon: 'cart-full',
		label: 'My Products',
	},
];

const Routes = () => (
	<Container>
		{routes.map((route) => (
			<Route key={route.url} to={route.url} name={route.label}>
				<Wrapper>
					<Background>
						<Text>
							<i className={`lni lni-${route.icon}`}></i>
							{route.label}
						</Text>
					</Background>
				</Wrapper>
			</Route>
		))}
	</Container>
);

export default Routes;
