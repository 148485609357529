import { sendMessageOptions } from './sendMessageButtonsMapper';

export const cenegenicsID = 'cenegenics';

const myCareTeamRoles = {
	service_coordinator: 'Service Coordinator',
	health_coach: 'Health Coach',
	physician: 'Physician',
};

export const handleCreateChannel = async (
	sdk,
	createChannel,
	channelName,
	userIds
) => {
	if (!userIds || userIds.length === 0) return;
	let params = new sdk.GroupChannelParams();

	params.isPublic = false;
	params.isEphemeral = false;
	params.isDistinct = true;
	params.addUserIds(userIds);
	params.name = channelName;

	return await createChannel(params)
		.then((channel) => {
			return channel.url;
		})
		.catch((error) => {
			console.warn('Cannot create channel', error);
			return null;
		});
};

export const interlocutors = (members, userId) =>
	members.filter((m) => m.userId !== userId);

export const getChannelName = (list, name) =>
	list.map((li) => li[name]).join(', ');

export const getSelectedMember = (myCareTeam, selectedProgram) => {
	const cenegenics = {
		id: `${process.env.REACT_APP_USER_ID_SENDBIRD}`,
		user_in_sendbird: true,
	};

	let result = {};

	switch (selectedProgram) {
		case sendMessageOptions.labresults_prescriptions_clinicalcare:
			result = myCareTeam.find((member) =>
				member.jobRole.includes(myCareTeamRoles.service_coordinator)
			);
			break;
		case sendMessageOptions.nutrition_fitness_lifestyle:
			result = myCareTeam.find((member) =>
				member.jobRole.includes(myCareTeamRoles.health_coach)
			);
			break;
		default:
			result = cenegenics;
	}

	if (result && !result.user_in_sendbird) {
		result = cenegenics;
	}

	if (typeof result === 'undefined') {
		result = cenegenics;
	}

	return result;
};

export const findPhoto = (teamList, userPhotoId, defaultPhoto) => {
	if (userPhotoId === cenegenicsID) {
		return defaultPhoto;
	}
	return teamList.find((t) => t.id === userPhotoId)?.photo || '';
};
