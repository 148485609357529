import React from 'react';

import { useSelector } from 'react-redux';
import { Route, Redirect, useLocation } from 'react-router-dom';

import MainLayout from 'layouts/MainLayout/MainLayout';

const PrivateRoute = ({ children, ...props }) => {
	const { token } = useSelector((state) => state.user);
	const loc = useLocation();

	var toObj = {
		pathname: '/login',
	};

	if (loc.pathname != '/') {
		toObj.search = '?loc=' + loc.pathname;
	}
	return token ? (
		<MainLayout>
			<Route {...props}>{children}</Route>
		</MainLayout>
	) : (
		<Redirect to={toObj} />
	);
};

export default PrivateRoute;
