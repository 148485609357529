import React, { useState, useEffect } from 'react';

import { useGetRestingHeartRate } from 'api/services';
import ChartModal from 'components/ChartModal/ChartModal';

import { getChartBgColors } from './utils/chartColorizing';
import {
	handleChartData,
	gradientBgPlugin,
	adaptTwoColumnsTableData,
	renameObjectsProp,
} from './utils/chartHelpers';
import {
	defaultConfig,
	tooltipCallbackBPM,
	ticksCallbackBPM,
	styledChartElements,
} from './utils/customViewChart';

const RestingHeartRateChart = ({ closeChart, isOpen }) => {
	const [data, setData] = useState({});
	const [tableData, setTableData] = useState(null);
	const [chartConfig, setChartConfig] = useState(null);
	const [labelsData, setLabelsData] = useState(undefined);

	const { data: restingHeartData, loading } = useGetRestingHeartRate();
	const isDataExist = restingHeartData?.data && restingHeartData?.data.length;

	useEffect(() => {
		if (isDataExist) {
			const refValues = getRefValues(restingHeartData?.referential_values);
			const dataAdapted = handleChartData(restingHeartData.data);
			const lastResult =
				restingHeartData.data[restingHeartData.data.length - 1];
			const tableDataAdapted = adaptTwoColumnsTableData(
				restingHeartData?.referential_values,
				lastResult,
				'Score',
				'Rating'
			);

			setData(dataAdapted);
			setTableData(tableDataAdapted);
			setLabelsData(dataAdapted?.labels);
			handleChartConfig(refValues);
		}
	}, [restingHeartData]);

	const getRefValues = (refValues) =>
		(refValues &&
			refValues.length > 0 &&
			renameObjectsProp(refValues, 'score', 'rating')) ||
		null;

	const handleChartConfig = (refValues) => {
		const minY = 40;
		const maxY = 90;
		const chartColors =
			(refValues && getChartBgColors(refValues, maxY, minY)) || [];

		const config = {
			...defaultConfig,
			plugins: {
				...defaultConfig.plugins,
				backgrounds: {
					hbars: chartColors,
				},
				tooltip: {
					...defaultConfig.plugins.tooltip,
					callbacks: { label: tooltipCallbackBPM },
				},
			},
			elements: styledChartElements(restingHeartData.data.length - 1),
			scales: {
				...defaultConfig.scales,
				y: {
					...defaultConfig.scales.y,
					min: minY,
					max: maxY,
					ticks: {
						...defaultConfig.scales.y.ticks,
						callback: ticksCallbackBPM,
						stepSize: 5,
						maxTicksLimit: 20,
					},
				},
			},
		};

		setChartConfig(config);
	};

	return (
		<ChartModal
			data={data}
			loading={loading}
			tableData={tableData}
			labelsData={labelsData}
			closeChart={closeChart}
			chartConfig={chartConfig}
			chartPlugins={gradientBgPlugin}
			isOpen={isOpen}
		/>
	);
};

export default RestingHeartRateChart;
