import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';

export const Layout = styled.div`
	height: 100vh;
	display: flex;

	@media ${device.mobileL}, ${device.mobileXL} and (${device.landscape}) {
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		height: auto;
	}
`;

export const MainSection = styled.section`
	width: 60%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	@media ${device.tablet} {
		width: 55%;
	}

	@media ${device.mobileL}, ${device.mobileXL} and (${device.landscape}) {
		margin-top: 30px;
		height: auto;
		width: 100%;
	}
`;

export const Content = styled.div`
	width: 70%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: flex-end;
	margin: 0 0 42px 42px;

	@media ${device.tablet} {
		width: 90%;
		margin-left: 3px;
	}

	@media ${device.mobileL} {
		width: 95%;
	}
`;

export const LogoWrapper = styled.div`
	width: 240px;
`;

export const Container = styled.div`
	width: 95%;
`;

export const Header = styled.div`
	width: 100%;
`;

export const Title = styled.h1`
	font-weight: 800;
	font-size: 30px;
	color: ${colors.navy};
	margin: 50px 0;

	@media ${device.laptop} {
		font-size: 24px;
	}

	@media ${device.mobileL} {
		font-size: 26px;
	}
`;

export const PolicyContainer = styled.div`
	background-color: ${colors.softBlue};
	border-radius: 4px;
	border: 1px solid ${colors.teal};
	color: ${colors.teal};
	display: inline-block;
	font-size: 18px;
	line-height: 24px;
	margin-bottom: 40px;
	padding: 20px 25px;
`;

export const BenefitsSection = styled.section`
	width: 40%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	position: relative;
	z-index: 1;
	background: ${colors.gradientGreen};

	@media ${device.tablet} {
		width: 45%;
	}

	@media ${device.mobileXL} and (${device.landscape}) {
		width: 100%;
	}

	@media ${device.mobileL} {
		width: 100%;
		align-items: center;
		margin-top: 25px;
		padding-bottom: 21px;
	}

	@media ${device.mobileS} {
		display: none;
	}
`;
