import React from 'react';

import Tippy from '@tippyjs/react';

import infoIconWhite from 'assets/svg/icons/info-white.svg';
import infoIcon from 'assets/svg/icons/info.svg';
import { tooltips } from 'utils/lists';

import { Container } from './Tooltip.styled';

const icons = {
	infoIcon,
	white: infoIconWhite,
};

const Tooltip = ({ mapKey, color }) => {
	return (
		<Container name='tooltip'>
			<Tippy content={tooltips[mapKey]} className='tooltip'>
				<img src={icons[color] || infoIcon} alt='Information' />
			</Tippy>
		</Container>
	);
};

export default Tooltip;
