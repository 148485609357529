import styled from 'styled-components/macro';

import { device } from 'styles/device';

export const Container = styled.button`
	border: none;
	background: transparent;
	display: inline-flex;
	cursor: pointer;
	position: relative;

	&:hover {
		> div {
			visibility: visible;
		}
	}

	@media ${device.laptop} {
		img {
			width: 13px;
			height: 13px;
		}
	}

	@media ${device.mobileL} {
		img {
			width: 24px;
			height: 24px;
		}
	}
`;
