import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Section = styled.div`
	display: flex;
	flex-direction: column;
	padding: 28px;
	& > * {
		flex: 1 0 auto;
	}
	#sendMsgBtn {
		span {
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			display: -webkit-box;
			overflow: hidden;
		}
	}
`;

export const GenericMessage = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin: 28px 0 42px 0;

	@media ${device.mobileL} {
		flex-direction: column;
		align-items: flex-start;
	}

	@media ${device.mobileL} and (${device.portrait}) {
		& > Button {
			width: 100%;
		}
	}
`;

export const Message = styled.p`
	font-weight: 800;
	color: ${colors.navy};
	${fontStyles[fontSizes.eighteen]};
	margin-right: 32px;

	@media ${device.mobileL} {
		margin-bottom: 14px;
	}
`;

export const TeamContainer = styled.div`
	display: flex;
	flex-wrap: wrap;

	@media ${device.mobileL} {
		flex-direction: column;
		height: auto;
	}

	.user-card {
		margin-bottom: 28px;

		@media ${device.tablet} {
			:last-child {
				margin-right: 0;
			}
		}

		@media ${device.mobileL} {
			margin-right: 0;
		}
	}
`;

export const NoDataWrapper = styled.div`
	min-height: 400px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
`;
