import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';

export const ModalContainer = styled.div``;

export const PDFContainer = styled.div`
	overflow-y: hidden;
`;

export const Row = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 20px;

	@media ${device.mobileL} {
		justify-content: space-around;
	}
`;

export const Anchor = styled.a`
	text-decoration: none;
`;

export const NoPDF = styled.div`
	text-align: center;
	height: 5vh;
	color: ${colors.darkGrey};
`;

export const EmptyContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	padding: 20px;
`;

export const IconBackground = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 160px;
	height: 100px;
	border-radius: 13px;
	background: ${colors.softBlue};
	margin-bottom: 25px;
`;

export const Icon = styled.img``;
