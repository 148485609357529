import { colors } from 'styles/colors';

// FIXME: Refactor this file

export const teamMembersHelper = {
	physician: {
		primaryColor: colors.teal,
		secondaryColor: colors.green20,
		role: 'Physician',
	},
	health_coach: {
		primaryColor: colors.purple,
		secondaryColor: colors.grey5,
		role: 'Health Coach',
	},
	service_coordinator: {
		primaryColor: colors.gold,
		secondaryColor: colors.gold10,
		role: 'Service Coordinator',
	},
};

export const mapRoleByKey = (key) => teamMembersHelper[key].role;

export const getMembersIds = (data) => data.map((obj) => obj.id);

export const removeDuplicatedMembers = (members, membersIds) =>
	members.filter(({ id }, index) => !membersIds.includes(id, index + 1));

export const getJobRole = (currentRole, teamKeys, teamData) => {
	let role = mapRoleByKey(currentRole);

	teamKeys.forEach((nextRole) => {
		if (
			currentRole !== nextRole &&
			teamData[currentRole].id === teamData[nextRole].id
		) {
			const secondRole = mapRoleByKey(nextRole);
			role = `${role} & ${secondRole}`;
		}
	});

	return role;
};

export const adaptTeamData = (teamKeys, teamData) =>
	teamKeys.map((key) => {
		let jobRole = getJobRole(key, teamKeys, teamData);

		return {
			...teamData[key],
			key: key,
			jobRole: jobRole,
		};
	});

export const mockResponse = {
	physician: {
		id: '1',
		full_name: 'name 1',
		title: 'Physician',
		city: 'city 1',
		state: 'string',
		jobRole: 'string',
		photo: '',
	},
	health_coach: {
		id: '2',
		full_name: 'name 2',
		title: 'Health Coach',
		city: 'city 2',
		state: 'string',
		jobRole: 'string',
		photo: '',
	},
	service_coordinator: {
		id: '3',
		full_name: 'name 3',
		title: 'Service Coordinator',
		city: 'city 3',
		state: 'string',
		jobRole: 'string',
		photo: '',
	},
};
