import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Row = styled.div`
	display: flex;
	justify-content: ${({ title }) => (title ? 'space-between' : 'flex-end')};
	width: 100%;
	overflow: hidden;
	background-color: ${colors.white};
	z-index: 10;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	flex-shrink: 0;
	padding-bottom: 20px;
`;

export const Title = styled.h1`
	font-weight: 800;
	color: ${colors.navy};
	${fontStyles[fontSizes.twenty]};
`;

export const CloseBtn = styled.button`
	background: transparent;
	border: none;
	outline: none;
	cursor: pointer;

	i {
		${fontStyles[fontSizes.eighteen]};
		color: ${colors.darkGrey};

		@media ${device.laptop} {
			color: ${colors.navy};
			${fontStyles[fontSizes.twenty]};
			font-weight: 800;
		}
	}
`;
