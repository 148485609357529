import React from 'react';

import { useSelector } from 'react-redux';

import {
	commonRefColors,
	fatBodyReflColors,
	hipRatioRefColors,
} from 'components/Charts/utils/customViewChart';

const colorsListMapping = {
	vo2Max: commonRefColors,
	totalBodyFat: fatBodyReflColors,
	restingHeartRate: commonRefColors,
	waistHipRatio: hipRatioRefColors,
};

import {
	Row,
	DateContainer,
	ChartDate,
	ChartPointer,
	ColorsList,
	Container,
	RefName,
	Circle,
} from './ChartLegend.styled';

const ChartLegend = ({ labelsData, tableData }) => {
	const { chartId } = useSelector((state) => state.chartData);

	const colorsList = colorsListMapping[chartId];
	const chartLabel = labelsData && labelsData.find((value) => value !== '');

	return (
		<>
			<Row>
				{chartLabel && (
					<DateContainer>
						First Result:
						<ChartPointer></ChartPointer>
						<ChartDate>{chartLabel}</ChartDate>
					</DateContainer>
				)}

				{tableData && colorsList && (
					<ColorsList>
						{colorsList.map(({ colorName, color }) => (
							<Container key={color} title={colorName}>
								<Circle background={color} />
								<RefName>{colorName}</RefName>
							</Container>
						))}
					</ColorsList>
				)}
			</Row>
		</>
	);
};

export default ChartLegend;
