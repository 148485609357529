import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';

export const Container = styled.div`
	background: ${colors.white};
	border-radius: 10px;
	width: 32%;
	display: flex;
	justify-content: center;

	@media ${device.mobileL} {
		width: 100%;
		margin-bottom: 20px;

		:last-child {
			margin-bottom: 0;
		}
	}
`;

export const LoaderContainer = styled.div`
	width: 75%;
	display: flex;
	justify-content: space-between;
	margin-top: 30px;

	@media ${device.laptop} {
		width: 100%;
	}

	@media ${device.mobileL} {
		flex-direction: column;
	}
`;

export const HeadingContainer = styled.div`
	@media ${device.mobileL}, ${device.mobileXL} and (${device.landscape}) {
		& > svg {
			max-width: 100%;
		}
	}
`;
