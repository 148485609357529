import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	background: ${colors.white};
	padding: 15px 20px;
	border-radius: 10px;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
	margin: 20px 0 0 0;

	&:first-child {
		margin-right: 10px;
	}

	@media ${device.mobileL} {
		width: 100%;
		margin-top: 14px;
		margin-left: 0;
		margin-bottom: 0px;
	}
`;

export const Title = styled.div`
	font-weight: 500;
	${fontStyles[fontSizes.sixteen]};
	color: ${colors.navy};
	margin-bottom: 26px;

	@media ${device.mobileL} {
		${fontStyles[fontSizes.twenty]};
	}
`;

export const Row = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-bottom: 10px;

	@media ${device.mobileL} {
		flex-direction: column;
	}
`;
