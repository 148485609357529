import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Section = styled.section`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 28px;
	margin-bottom: 14px;
	overflow-y: auto;
`;

export const Form = styled.form`
	height: 100%;
	width: 100%;
`;

export const Divider = styled.div`
	height: 1px;
	background: ${colors.grey20};
	margin: 14px 0;
	width: 100%;

	@media ${device.mobileL} {
		width: 100%;
	}
`;

export const Wrapper = styled.div`
	display: flex;
	justify-content: flex-end;

	@media ${device.mobileL} {
		flex-direction: column-reverse;
	}

	label {
		@media ${device.laptop} {
			${fontStyles[fontSizes.sixteen]};
		}
	}

	button {
		@media ${device.mobileL} {
			width: 100%;
			margin-right: 0;
		}
	}
`;

export const RevokeAccessContainer = styled.div`
	margin-top: 30px;
	margin-bottom: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media screen and (max-width: 767px) {
		flex-wrap: wrap;
	}
`;

export const RevokeAccessText = styled.p`
	font-size: 16px;
	line-height: 18px;
	color: ${colors.darkGrey};
	max-width: 75%;

	@media screen and (max-width: 767px) {
		max-width: 100%;
	}
`;

export const RevokeAccessButton = styled.button`
	margin-left: auto;
	padding: 10px 15px;
	background-color: ${colors.navy};
	color: ${colors.white};
	border: 1px solid transparent;
	border-radius: 6px;
	cursor: pointer;

	&:hover {
		background-color: ${colors.white};
		color: ${colors.navy};
		border: 1px solid ${colors.navy};
	}

	@media screen and (max-width: 767px) {
		margin-left: 0;
		margin-top: 20px;
	}
`;
