import styled from 'styled-components/macro';

import { device } from 'styles/device';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100vh;
`;

export const LogoWrapper = styled.div`
	display: flex;
	width: 100%;
	margin: 40px 0 0 40px;

	> img {
		width: 225px;
		height: 55px;
		cursor: pointer;
	}

	// prettier-ignore
	@media ${device.mobileXL} and (${device.landscape}), ${device.mobileM} and (${device.mobileL}) {
		justify-content: center;
		margin-left: 0;
	}
`;
