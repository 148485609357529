import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Container = styled.div`
	width: 60%;
	display: flex;
	margin: auto;
	flex-direction: column;
	align-items: center;
`;

export const Heading = styled.span`
	${fontStyles[fontSizes.twentySix]}
	font-weight: 800;
	color: ${colors.navy};
	margin-top: 60px;
	text-align: center;
`;

export const Description = styled.span`
	${fontStyles[fontSizes.twentyTwo]}
	color: ${colors.darkGrey};
	text-align: center;
	margin-top: 28px;
`;

export const Image = styled.img`
	width: 150px;
`;
