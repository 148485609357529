import React, { useState, useEffect } from 'react';

import { useGetWaistHipRatio } from 'api/services';
import ChartModal from 'components/ChartModal/ChartModal';

import { getChartBgColors } from './utils/chartColorizing';
import {
	handleChartData,
	gradientBgPlugin,
	adaptHipRatioTable,
	renameObjectsProp,
} from './utils/chartHelpers';
import {
	defaultConfig,
	tooltipCallback,
	ticksCallback,
	styledChartElements,
} from './utils/customViewChart';

const HipRatioChart = ({ closeChart, isOpen }) => {
	const [data, setData] = useState({});
	const [tableData, setTableData] = useState(null);
	const [chartConfig, setChartConfig] = useState(null);
	const [labelsData, setLabelsData] = useState(undefined);

	const { data: hipRatioData, loading } = useGetWaistHipRatio();
	const isDataExist = hipRatioData?.data && hipRatioData.data.length;

	useEffect(() => {
		if (isDataExist) {
			const refValues = getRefValues(hipRatioData?.referential_values);
			const dataAdapted = handleChartData(hipRatioData.data);
			const lastResult = hipRatioData.data[hipRatioData.data.length - 1];
			const tableDataAdapted = adaptHipRatioTable(
				hipRatioData?.referential_values,
				lastResult
			);

			setData(dataAdapted);
			setTableData(tableDataAdapted);
			setLabelsData(dataAdapted?.labels);
			handleChartConfig(refValues);
		}
	}, [hipRatioData]);

	const getRefValues = (data) =>
		(data && data.length > 0 && renameObjectsProp(data, 'value', 'risk')) ||
		null;

	const handleChartConfig = (refValues) => {
		const minY = 0.6;
		const maxY = 1.2;
		const chartColors =
			(refValues && getChartBgColors(refValues, maxY, minY)) || [];

		const config = {
			...defaultConfig,
			plugins: {
				...defaultConfig.plugins,
				backgrounds: {
					hbars: chartColors,
				},
				tooltip: {
					...defaultConfig.plugins.tooltip,
					callbacks: { label: tooltipCallback },
				},
			},
			elements: styledChartElements(hipRatioData.data.length - 1),
			scales: {
				...defaultConfig.scales,
				y: {
					...defaultConfig.scales.y,
					min: minY,
					max: maxY,
					ticks: {
						...defaultConfig.scales.y.ticks,
						callback: ticksCallback,
					},
				},
			},
		};

		setChartConfig(config);
	};

	return (
		<ChartModal
			data={data}
			loading={loading}
			tableData={tableData}
			labelsData={labelsData}
			closeChart={closeChart}
			chartConfig={chartConfig}
			chartPlugins={gradientBgPlugin}
			isOpen={isOpen}
		/>
	);
};

export default HipRatioChart;
