import React from 'react';

import moveArrowIcon from 'assets/svg/icons/move-arrow.svg';

import { Container, Message } from './PortraitView.styled';

const PortraitView = () => (
	<Container>
		<img src={moveArrowIcon} alt='Flip icon' />
		<Message>Please flip your phone to see detailed view</Message>
	</Container>
);

export default PortraitView;
