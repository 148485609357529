import React from 'react';

import { useHistory } from 'react-router-dom';

import {
	Container,
	Copyright,
	Email,
	Contact,
	Privacy,
	Link,
} from './Footer.styled';

const Footer = () => {
	const year = new Date().getFullYear();
	const history = useHistory();

	return (
		<Container>
			<Copyright>
				{year} BestLife Holdings, Inc.
				<br />
				All rights reserved
			</Copyright>
			<Contact>
				{`Write us at `}
				<Email>info@cenegenics.com</Email>
			</Contact>
			<Privacy>
				Your Agreement with&nbsp;
				<Link onClick={() => history.push('/privacy-policy')}>Cenegenics</Link>
			</Privacy>
		</Container>
	);
};

export default Footer;
