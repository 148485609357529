import styled from 'styled-components/macro';

import { device } from 'styles/device';

export const Layout = styled.div`
	height: 100vh;
	width: 100%;
	// TODO: Check this line of code
	position: fixed;
	display: flex;

	@media ${device.mobileL} {
		height: 100%;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
	}
`;

export const Content = styled.div`
	display: flex;
	width: calc(100% - 260px);
	flex-direction: column;
	// TODO: Check this line of code
	overflow: auto;

	@media ${device.laptop} {
		width: 100%;
	}
`;
