import styled from 'styled-components/macro';

import { colors } from 'styles/colors';

export const LogoWrapper = styled.div`
	margin: 23px 32px 41px 32px;
`;

export const Container = styled.div`
	width: 260px;
	display: flex;
	flex-direction: column;
	align-items: center;
	border: 1px solid ${colors.grey10};
	background-color: ${colors.white};
`;

export const RoutesContainer = styled.div`
	width: calc(100% - 48px);
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;
