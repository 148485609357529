import React from 'react';

import Spinner from 'components/Spinner/Spinner';

import ModalHeader from './ModalHeader/ModalHeader';
import PortraitView from './PortraitView/PortraitView';

import {
	ModalContent,
	Loader,
	Footer,
	Column,
	MainColumn,
} from './Modal.styled';

const Modal = ({
	children,
	onRequestClose,
	isPortrait,
	loading,
	actionButtons,
	title,
	isEmpty,
	...rest
}) => {
	return (
		<ModalContent
			{...rest}
			onRequestClose={onRequestClose}
			isPortrait={isPortrait}
			appElement={document.getElementById('root')}
			isEmpty={isEmpty}
		>
			{isPortrait ? (
				<PortraitView />
			) : (
				<>
					{loading ? (
						<Loader>
							<Spinner />
						</Loader>
					) : (
						<Column>
							<ModalHeader onClose={onRequestClose} title={title} />
							<MainColumn>{children}</MainColumn>
							{actionButtons && !isEmpty && <Footer>{actionButtons}</Footer>}
						</Column>
					)}
				</>
			)}
		</ModalContent>
	);
};

export default Modal;
