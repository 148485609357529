import React from 'react';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSendbirdStateContext, sendBirdSelectors } from 'sendbird-uikit';

import Avatar from 'components/Avatar/Avatar';
import { handleCreateChannel } from 'utils/sendBirdHelpers';

import {
	Container,
	BorderTop,
	PictureContainer,
	TeamRole,
	MemberName,
	LocationText,
	Button,
} from './UserCard.styled';

const UserCard = ({
	id = '',
	full_name = '',
	jobRole = '',
	city = '',
	state = '',
	photo = '',
	user_in_sendbird = false,
	primaryColor,
	secondaryColor,
}) => {
	const history = useHistory();
	const context = useSendbirdStateContext();
	const dispatch = useDispatch();

	const location = `${city || ''}${city && state ? ',' : ''} ${state || ''}`;
	let fullName = `${full_name || 'N/A'}`;

	const createChannelSendBird = async (userId) => {
		const sdk = sendBirdSelectors.getSdk(context);
		const createChannel = sendBirdSelectors.getCreateChannel(context);

		if (sdk && sdk.GroupChannelParams) {
			const channelUrl = await handleCreateChannel(
				sdk,
				createChannel,
				full_name,
				[userId]
			);

			dispatch.messaging.update({ channelUrl });
			history.push('/messaging');
		}
	};

	return (
		<Container className='user-card'>
			<BorderTop color={primaryColor} data-testid='borderTop' />

			<PictureContainer bgColor={primaryColor} isPicture={photo}>
				<Avatar picture={photo} />
			</PictureContainer>

			<TeamRole
				color={primaryColor}
				bgColor={secondaryColor}
				name='teamRole'
				data-testid='teamRole'
			>
				{jobRole || 'N/A'}
			</TeamRole>

			<MemberName name='fullName' data-testid='fullName'>
				{fullName}
			</MemberName>

			<LocationText name='location' data-testid='location'>
				{location}
			</LocationText>

			{user_in_sendbird && (
				<Button
					type='button'
					onClick={() => createChannelSendBird(id)}
					// disabled={!user_in_sendbird}
				>
					Send Message
				</Button>
			)}
		</Container>
	);
};

export default UserCard;
