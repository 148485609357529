export function gtag() {
	window.dataLayer.push(arguments);
}

export const initializeGtag = (trackingId) => {
	const scriptId = 'fv-gtag';

	if (document.getElementById(scriptId)) {
		return;
	}

	const { head } = document;
	const script = document.createElement('script');
	script.id = scriptId;
	script.type = 'text/javascript';
	script.async = true;
	script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
	head.insertBefore(script, head.firstChild);

	window.dataLayer = window.dataLayer || [];

	gtag('js', new Date());
	gtag('config', trackingId);
};
