import { init } from '@rematch/core';

import biometrics from './models/biometrics';
import chartData from './models/chartData';
import messaging from './models/messaging';
import policyConsent from './models/policyConsent';
import user from './models/user';

const models = { user, chartData, biometrics, messaging, policyConsent };
const store = init({ models });

export default store;
