import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin: 21px 0;
	flex-shrink: 0;
`;

export const SubTitle = styled.p`
	color: ${colors.navy};
	${fontStyles[fontSizes.eighteen]};
	line-height: 18px;
	font-weight: 500;
`;

export const Row = styled.div`
	display: flex;
	align-items: center;
	margin-top: 13px;

	@media ${device.mobileL} {
		flex-direction: column;
	}

	#avatarBorder {
		width: 90px;
		height: 90px;
		border-radius: 61px;
		z-index: -1;

		@media ${device.mobileL} {
			width: 120px;
			height: 120px;
		}
	}
`;

export const GroupButtons = styled.div`
	display: flex;
	margin-top: 14px;

	@media ${device.mobileL} {
		width: 100%;
		justify-content: center;
	}
`;

export const UploadBtnWrapper = styled.div`
	@media ${device.mobileL} {
		width: 40%;
		margin-left: 0;
	}
`;

export const RemoveBtnWrapper = styled.div`
	@media ${device.mobileL} {
		width: 40%;
	}
`;
