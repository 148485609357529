import React from 'react';

import { useHistory } from 'react-router-dom';

import Logo from 'components/Logo/Logo';

import { Container, LogoWrapper } from './ForgotPasswordLayout.styled';

const ForgotPasswordLayout = ({ children }) => {
	const history = useHistory();

	return (
		<Container>
			<LogoWrapper>
				<Logo
					role='link'
					onClick={() => history.push('/login')}
					width='220px'
					height='40px'
				/>
			</LogoWrapper>
			<>{children}</>
		</Container>
	);
};

export default ForgotPasswordLayout;
