import React from 'react';

import Masonry from 'react-masonry-css';

import { useGetTestResults } from 'api/services';
import testResultsIcon from 'assets/svg/icons/test-results.svg';
import Titles from 'common/Titles/Titles';
import Card from 'components/ResultCard/ResultCard';
import TestResultsLoader from 'components/Skeleton/TestResultsLoader/TestResultsLoader';
import { TEST_RESULTS_TITLE, TEST_RESULTS_SUBTITLE } from 'utils/constants';

import {
	Description,
	Container,
	CardsContainer,
	Icon,
	IconWrapper,
	EmptyContainer,
	EmptyTitle,
	EmptySubtitle,
} from './TestResults.styled';

const breakpointColumnsMasonry = {
	default: 5,
	1440: 4,
	1280: 3,
	1024: 4, // tablet landscape
	992: 3, // iPhone X landscape and tablet portrait
	767: 1,
};

const TestResults = () => {
	const { data, loading } = useGetTestResults();

	return (
		<Container>
			{loading ? (
				<TestResultsLoader />
			) : (
				<>
					<Titles
						mainTitle={TEST_RESULTS_TITLE}
						subTitle={TEST_RESULTS_SUBTITLE}
					/>
					<Description>
						To see your test results please click on the cards below and it will
						open a PDF which you can download
					</Description>
					<CardsContainer>
						{data && data.length ? (
							<Masonry
								breakpointCols={breakpointColumnsMasonry}
								className='masonry-grid'
								columnClassName='masonry-grid_column'
							>
								{data.map((result) => (
									<Card
										title={result.title}
										description={result.description}
										category={result.category}
										documentId={result.document_id}
										key={result.document_id}
									/>
								))}
							</Masonry>
						) : (
							<EmptyContainer>
								<IconWrapper>
									<Icon src={testResultsIcon} />
								</IconWrapper>
								<EmptyTitle>
									Seems like there are no test results in
									<br />
									your list yet
								</EmptyTitle>
								<EmptySubtitle>
									Please contact Cenegenics to add your test results
								</EmptySubtitle>
							</EmptyContainer>
						)}
					</CardsContainer>
				</>
			)}
		</Container>
	);
};

export default TestResults;
