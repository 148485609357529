import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 24px;
`;

export const Contact = styled.span`
	${fontStyles[fontSizes.twelve]};
	color: ${colors.darkGrey};
	margin-top: 8px;
`;

export const Email = styled.a.attrs({
	href: 'mailto:info@cenegenics.com',
})`
	color: ${colors.darkGrey};
	font-weight: 600;
	text-decoration: none;
	&:hover {
		color: ${colors.green};
	}
`;

export const Copyright = styled.span`
	${fontStyles[fontSizes.twelve]};
	color: ${colors.darkGrey};
	text-align: center;
`;

export const Privacy = styled.span`
	${fontStyles[fontSizes.twelve]};
	color: ${colors.darkGrey};
	margin-top: 20px;
`;

export const Link = styled.a`
	font-weight: 600;
	text-decoration: none;
	color: ${colors.green};
	cursor: pointer;
`;
