import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Container = styled.div``;

export const Route = styled(NavLink).attrs({
	exact: true,
	activeClassName: 'active-route',
})`
	display: flex;
	cursor: pointer;
	${fontStyles[fontSizes.eighteen]};
	text-decoration: none;
	color: ${colors.navy};
	height: 57px;
`;

export const Wrapper = styled.div`
	display: flex;
	width: calc(100% - 2px);
	height: calc(100% - 2px);
	align-items: center;
	margin: 1px 0 0 1px;
`;

export const Background = styled.div`
	padding: 15px 0 15px 15px;
	display: flex;
	width: 100%;
	height: 100%;
`;

export const Text = styled.span`
	> i {
		display: inline;
		margin-right: 10px;
	}

	&:hover {
		color: rgb(0, 145, 148);
	}
`;
