import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useForgotPassword } from 'api/services';
import Button from 'common/Button/Button';
import Input from 'common/Input/Input';
import ForgotPasswordLayout from 'layouts/ForgotPasswordLayout/ForgotPasswordLayout';
import { red80 } from 'styles/colors';
import { showErrorNotify, isEmailFormat, handleApiErrors } from 'utils';
import {
	WRONG_EMAIL_FORMAT,
	REQUIRED_EMAIL,
	BOTTOM_LEFT,
	VALIDATION_ERROR_MSG,
} from 'utils/constants';

import {
	Container,
	FormGroup,
	Heading,
	Description,
	SubmitWrapper,
	LockIcon,
} from './ForgotPassword.styled';

const preventToastPassID = 'prevent_pass_id';

const ForgotPassword = () => {
	const [email, setEmail] = useState('');
	const [isValid, setIsValid] = useState('');
	const { mutate: forgotPassword } = useForgotPassword();
	const history = useHistory();

	const onChange = (e) => {
		setEmail(e.target.value);
		setIsValid(true);
		toast.dismiss();
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		try {
			if (validateFrom()) {
				await forgotPassword({ email });

				history.push('/forgot-password-success');
			}
		} catch (err) {
			handleApiErrors(
				err,
				(loc, msg) =>
					showErrorNotify(
						`Error with ${loc}: ${msg}`,
						BOTTOM_LEFT,
						preventToastPassID
					),
				(msg) => showErrorNotify(msg, BOTTOM_LEFT),
				() => showErrorNotify(VALIDATION_ERROR_MSG, BOTTOM_LEFT)
			);
		}
	};

	const validateFrom = () => {
		let valid = false;

		if (!email) {
			setIsValid(valid);
			showErrorNotify(REQUIRED_EMAIL, BOTTOM_LEFT, preventToastPassID);
			return valid;
		}

		if (!isEmailFormat(email)) {
			setIsValid(valid);
			showErrorNotify(WRONG_EMAIL_FORMAT, BOTTOM_LEFT, preventToastPassID);
			return valid;
		}

		return true;
	};

	return (
		<ForgotPasswordLayout>
			<Container onSubmit={(e) => onSubmit(e)}>
				<LockIcon>
					<i className='lni lni-lock lni-32'></i>
				</LockIcon>
				<Heading>Forgot your password?</Heading>
				<Description>
					Please enter your email below and we will send you reset password
					instructions
				</Description>
				<FormGroup>
					<Input
						name='email'
						value={email}
						type='email'
						labelText='Enter email'
						errorColor={red80}
						handleChange={onChange}
						isValid={isValid}
					/>
				</FormGroup>

				<SubmitWrapper>
					<Button variant='primary' type='submit'>
						Submit
					</Button>
				</SubmitWrapper>
			</Container>
		</ForgotPasswordLayout>
	);
};

export default ForgotPassword;
