import 'url-search-params-polyfill';

import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useLogin } from 'api/services';
import Button from 'common/Button/Button';
import Input from 'common/Input/Input';
import Password from 'common/Password/Password';
import PolicyConsent from 'components/PolicyConsent/PolicyConsent';
import Spinner from 'components/Spinner/Spinner';
import useLocalStorage from 'hooks/useLocalStorage';
import { red80 } from 'styles/colors';
import { showErrorNotify, removeStorageItem } from 'utils';
import {
	INVALID_CREDENTIALS_ERR,
	REQUIRED_USERNAME_PASSWORD,
	USERNAME_REQUIRED,
	PASSWORD_REQUIRED,
	BOTTOM_LEFT,
} from 'utils/constants';
import {
	policyConsentName,
	policyConsentUpdatesTimestamp,
	policyConsentTimestamp,
} from 'utils/policyConsent';

import {
	Form,
	FormGroup,
	LoginBtn,
	Link,
	LinkWrapper,
	PasswordGroup,
} from './LoginForm.styled';

const preventToastDuplicateID = 'prevent_duplicate_id';

// TODO: This file is pretty much the same as RegistrationForm.js,
// try to make only 1 component

const LoginForm = ({ location }) => {
	const [policyTimestamp] = useLocalStorage(policyConsentTimestamp);
	const { isPolicyAccepted } = useSelector((state) => state.policyConsent);

	const useQuery = () => {
		if (location) {
			return new URLSearchParams(location.search).get('loc');
		}
		return '/';
	};

	const dispatch = useDispatch();
	const history = useHistory();
	const query = useQuery();

	const { mutate: login, loading } = useLogin();

	const [formData, setFormData] = useState({
		username: '',
		password: '',
		isUsernameValid: undefined,
		isPassValid: undefined,
	});

	const { username, password, isUsernameValid, isPassValid } = formData;

	const handleChange = (evt) => {
		const { name, value } = evt.target;
		toast.dismiss();
		setFormData((oldState) => ({
			...oldState,
			[name]: value,
			isUsernameValid: true,
			isPassValid: true,
		}));
	};

	const updateIsValidState = (isValid) => {
		setFormData((oldState) => ({
			...oldState,
			isUsernameValid: isValid,
			isPassValid: isValid,
		}));
	};

	const validateFrom = () => {
		let stateName = '';
		let isValid = false;

		if (!username && !password) {
			updateIsValidState(isValid);
			showErrorNotify(
				REQUIRED_USERNAME_PASSWORD,
				BOTTOM_LEFT,
				preventToastDuplicateID
			);
			return isValid;
		}

		if (!username) {
			stateName = 'isUsernameValid';
			handleError(USERNAME_REQUIRED, stateName);
			return isValid;
		}

		if (!password) {
			stateName = 'isPassValid';
			handleError(PASSWORD_REQUIRED, stateName);
			return isValid;
		}
		return true;
	};

	const handleSignIn = async () => {
		try {
			const { token } = await login({ username: username.trim(), password });

			if (token) {
				sessionStorage.setItem('token', token);
				dispatch.biometrics.reset();
				dispatch.user.updateUser({ token });
				query ? history.push(query) : history.push('/');
			}
		} catch (err) {
			showErrorNotify(INVALID_CREDENTIALS_ERR, BOTTOM_LEFT);
		}
	};

	const onSubmit = (evt) => {
		evt.preventDefault();

		// We don't want to login the user
		// if Policy isn't accepted
		if (!isPolicyAccepted) {
			return;
		}

		if (validateFrom()) {
			handleSignIn();
		}
	};

	const handleError = (msg, stateName) => {
		setFormData((oldState) => ({ ...oldState, [stateName]: false }));
		showErrorNotify(msg, BOTTOM_LEFT);
	};

	useEffect(() => {
		// This const - REACT_APP_NEW_POLICY_DATE, is in the hand of a developer/devops (unfortunately).
		// Whenever Terms of Use, or Cookie Policy or any other Policy is changed, we must
		// manually change the date in .env file (for localhost testing) or
		// ./github/workflows/deploy.yaml for dev/staging/prod environment.
		const policyUpdateDate = policyConsentUpdatesTimestamp(
			process.env.REACT_APP_NEW_POLICY_DATE
		);

		if (parseFloat(policyUpdateDate) < parseFloat(policyTimestamp)) {
			removeStorageItem(policyConsentName);
			removeStorageItem(policyConsentTimestamp);
			dispatch.policyConsent.clearPolicyConsent();
		}
	}, [policyTimestamp]);

	return (
		<>
			<Form role='form' onSubmit={onSubmit}>
				<FormGroup>
					<Input
						name='username'
						value={username}
						labelText='Username'
						isValid={isUsernameValid}
						errorColor={red80}
						handleChange={handleChange}
					/>
				</FormGroup>

				<PasswordGroup>
					<Password
						name='password'
						value={password}
						isValid={isPassValid}
						labelText='Password'
						errorColor={red80}
						handleChange={handleChange}
					/>
				</PasswordGroup>

				<LinkWrapper>
					<Link onClick={() => history.push('/forgot-password')}>
						Forgot password?
					</Link>
				</LinkWrapper>

				<LoginBtn>
					<Button type='submit' variant='primary' disabled={loading}>
						Log in
					</Button>
				</LoginBtn>

				{loading && <Spinner />}
			</Form>

			{isPolicyAccepted ? null : <PolicyConsent />}
		</>
	);
};

export default LoginForm;
