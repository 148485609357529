import React, { useState, useCallback } from 'react';

import { useSelector } from 'react-redux';

import sendIcon from 'assets/svg/icons/send.svg';
import usersIcon from 'assets/svg/icons/users.svg';
import Button from 'common/Button/Button';
import EmptyStateCard from 'common/EmptyStateCard/EmptyStateCard';
import Titles from 'common/Titles/Titles';
import SendGenericMessageModal from 'components/SendMessageModal/SendMessageModal';
import MyCareTeamLoader from 'components/Skeleton/MyCareTeamLoader/MyCareTeamLoader';
import UserCard from 'components/UserCard/UserCard';
import { isEmptyObject } from 'utils';
import {
	MY_CARE_TEAM_TITLE,
	MY_CARE_TEAM_SUB_TITLE,
	SEND_GENERIC_MESSAGE_TITLE,
} from 'utils/constants';
import {
	CARE_TEAM_EMPTY_TEXT,
	CARE_TEAM_EMPTY_SUB_TEXT,
} from 'utils/emptyStateMsg';

import { teamMembersHelper } from './careTeamHelper';

import {
	Section,
	GenericMessage,
	Message,
	TeamContainer,
	NoDataWrapper,
} from './MyCareTeam.styled';

const MyCareTeam = () => {
	const [isSendGenericModalOpen, setIsSendGenericModalOpen] = useState(false);
	const {
		myCareTeam: data,
		myCareTeamLoading: loading,
		myCareTeamError: error,
	} = useSelector((state) => state.user);

	const isDataExist = error === null && !isEmptyObject(data);

	const showTeamMembers = () => {
		if (!data) return;
		return data.map((member) => (
			<UserCard
				key={member.id}
				{...member}
				{...teamMembersHelper[member.key]}
			/>
		));
	};

	const showEmptyState = () => (
		<NoDataWrapper data-testid='emptyState'>
			<EmptyStateCard
				text={CARE_TEAM_EMPTY_TEXT}
				subText={CARE_TEAM_EMPTY_SUB_TEXT}
				image={usersIcon}
			/>
		</NoDataWrapper>
	);

	const onCloseSendGenericMessageModal = useCallback(() => {
		setIsSendGenericModalOpen(false);
	}, [setIsSendGenericModalOpen]);

	return (
		<Section>
			{loading ? (
				<MyCareTeamLoader />
			) : (
				<>
					<Titles
						mainTitle={MY_CARE_TEAM_TITLE}
						subTitle={MY_CARE_TEAM_SUB_TITLE}
					/>
					<GenericMessage>
						<Message>
							Got a question or just want to chat with our team?
						</Message>

						<Button
							icon={sendIcon}
							id='sendMsgBtn'
							onClick={() => setIsSendGenericModalOpen(true)}
							variant='primary'
						>
							Send a Message
						</Button>
					</GenericMessage>

					<TeamContainer>
						{isDataExist ? showTeamMembers() : showEmptyState()}
					</TeamContainer>
					{isSendGenericModalOpen && (
						<SendGenericMessageModal
							isOpen={isSendGenericModalOpen}
							onCloseModal={onCloseSendGenericMessageModal}
							title={SEND_GENERIC_MESSAGE_TITLE}
							data={data}
						/>
					)}
				</>
			)}
		</Section>
	);
};

export default MyCareTeam;
