import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Wrapper = styled.div`
	width: 100%;
`;

export const Column = styled.div`
	width: 100%;
	margin-top: 28px;

	.phone-number {
		margin-top: 14px;
		width: 100%;
	}

	@media ${device.mobileL} {
		.last-name {
			margin-top: 14px;
		}

		.username {
			margin-top: 14px;
		}
	}
`;

export const Container = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;

	@media ${device.mobileL} {
		flex-direction: column;
	}
`;

export const Title = styled.p`
	color: ${colors.navy};
	${fontStyles[fontSizes.eighteen]};
	line-height: 18px;
	font-weight: 500;
	margin-bottom: 23px;
`;

export const FormGroup = styled.div`
	width: 49%;

	@media ${device.mobileL} {
		width: 100%;
	}
`;
