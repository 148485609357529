import React from 'react';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Container, DropdownItem } from './LogoutDropdown.styled';

const LogoutDropdown = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const handleLogout = () => {
		dispatch.user.logoutUser();
	};

	return (
		<Container>
			<DropdownItem onClick={() => history.push('/profile')}>
				Account Settings
			</DropdownItem>
			<DropdownItem onClick={handleLogout}>Log Out</DropdownItem>
		</Container>
	);
};

export default LogoutDropdown;
