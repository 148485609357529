import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';
import { fontSizes, fontStyles } from 'styles/fonts';

export const CardWrapper = styled.div`
	cursor: pointer;
	position: relative;
	transition: transform 0.15s linear;

	&:hover {
		box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
		transform: translateY(-5px);
	}

	&::after {
		content: '';
		background: ${colors.gradient};
		border-radius: 9px;
		bottom: -1px;
		left: -1px;
		position: absolute;
		right: -1px;
		top: -1px;
		z-index: -1;
	}
`;

export const Card = styled.div`
	background: ${colors.white};
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	flex: 1;
	min-height: 270px;
`;

// FIXME: We should have only one source of truth for LearMore component
// found same Styled component in ResultCard as well
export const LearnMore = styled.div`
	cursor: pointer;
	text-align: left;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 10px 0 0 10px;

	&:hover {
		font-weight: 600;
	}
`;

export const Title = styled.div`
	${fontStyles[fontSizes.twenty]};
	font-weight: 600;
	text-align: center;
`;

export const Icon = styled.img`
	height: 54px;
	width: 54px;
	margin: 30px auto 40px;
`;

export const InfoIcon = styled.img`
	padding-right: 5px;
`;

export const GeneralInfo = styled.div`
	display: flex;
	flex-direction: column;
	padding: 30px 10px 30px;
`;

export const Description = styled.div`
	${fontStyles[fontSizes.sixteen]};
	color: ${colors.darkGrey};
	text-align: center;
`;

export const Row = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 20px;

	@media ${device.mobileL} {
		flex-direction: column;
		align-items: center;
	}
`;

// TODO: Check if this Styled component button is used any more
export const CancelButton = styled.button`
	font-weight: 800;
	${fontStyles[fontSizes.sixteen]};
	color: ${colors.darkGrey};
	border: none;
	background: transparent;
	cursor: pointer;
	margin-right: 10px;

	@media ${device.mobileL} {
		width: 100%;
		margin-top: 7px;
	}
`;

export const Anchor = styled.a`
	text-decoration: none;
`;
