import React from 'react';

import { useHistory } from 'react-router-dom';

import FourOhFour from 'assets/svg/illustrations/four-oh-four.svg';
import Button from 'common/Button/Button';

import { Heading, Container, Image, Contact } from './NotFound.styled';

const NotFound = () => {
	const history = useHistory();

	return (
		<Container>
			<Image src={FourOhFour} />
			<Heading>
				We’ve listened and... we can’t find the page you’re looking for
			</Heading>
			<Button variant='primary' onClick={() => history.goBack()}>
				Go Back
			</Button>
			<Contact>Or contact Cenegenics team</Contact>
		</Container>
	);
};

export default NotFound;
