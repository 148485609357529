import React, { useEffect, useState, useRef } from 'react';

import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { withSendBird } from 'sendbird-uikit';

import { useGetMyCareTeam } from 'api/services';
import burger from 'assets/svg/icons/burger-menu.svg';
import Footer from 'components/Footer/Footer';
import Logo from 'components/Logo/Logo';
import Routes from 'components/Routes/Routes';
import useChannelManager from 'hooks/useChannelManager';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import {
	adaptTeamData,
	getMembersIds,
	removeDuplicatedMembers,
} from 'pages/MyCareTeam/careTeamHelper';
import { deleteByValue } from 'utils';

import Caret from './Caret/Caret';
import LogoutDropdown from './LogoutDropdown/LogoutDropdown';
import MessagingBtn from './MessagingBtn';
import Avatar from './UserImage/UserImage';

import {
	Container,
	HeaderTop,
	HeaderLogo,
	Menu,
	Burger,
	Icon,
	SmallText,
	HeaderDivider,
	DropdownMenu,
	DropdownRoutes,
	UserSection,
	UserDropdown,
	UserAction,
	Divider,
	CloseMenu,
	PageOverlay,
} from './Header.styled';

const PageHeader = ({ isResponsive }) => {
	useChannelManager();

	const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);
	const [userDropdownOpen, setUserDropdownOpen] = useState(false);

	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();
	const menuRef = useRef();

	const { data, loading, error } = useGetMyCareTeam();

	useEffect(async () => {
		const p = {
			data: data,
			loading: loading,
			error: error,
		};

		if (data) {
			const team = deleteByValue(data);
			const teamKeys = Object.keys(team);
			const teamAdapted = adaptTeamData(teamKeys, team);
			const ids = getMembersIds(teamAdapted);
			p.data = removeDuplicatedMembers(teamAdapted, ids);
		}
		dispatch.user.setTeam(p);
	}, [data, loading]);

	useEffect(() => {
		setBurgerMenuOpen(false);
		setUserDropdownOpen(false);
	}, [location]);

	const toggleMenu = () => {
		setBurgerMenuOpen(!burgerMenuOpen);
		userDropdownOpen && setUserDropdownOpen(false);
	};

	const toggleUserDropdown = () => {
		setUserDropdownOpen(!userDropdownOpen);
	};

	const handleLogout = () => {
		dispatch.user.logoutUser();
	};

	const handleRedirect = (url) => {
		if (!url) return;
		history.push(url);
	};

	useOnClickOutside(menuRef, () => menuRef && setBurgerMenuOpen(false));

	return (
		<>
			<Container ref={menuRef}>
				<HeaderTop>
					{isResponsive && (
						<HeaderLogo>
							<Logo width='220px' height='40px' />
						</HeaderLogo>
					)}
					<Menu>
						{/* TODO: Think it over if we can put this component into <Button /> as well */}
						<MessagingBtn />

						{!isResponsive && (
							<>
								<HeaderDivider />
								<Avatar />

								<Caret
									onClick={toggleUserDropdown}
									dropdownOpen={userDropdownOpen}
									onClickOutside={setUserDropdownOpen}
								>
									{userDropdownOpen && <LogoutDropdown />}
								</Caret>
							</>
						)}
						{isResponsive && (
							<Burger onClick={toggleMenu}>
								{!burgerMenuOpen ? (
									<>
										<Icon src={burger} />
										<SmallText>Menu</SmallText>
									</>
								) : (
									<CloseMenu>
										{/* TODO: Think it over if we can put this component into <Button /> as well */}
										<i className='lni lni-close'></i>
										<SmallText>Close</SmallText>
									</CloseMenu>
								)}
							</Burger>
						)}
					</Menu>
				</HeaderTop>

				{burgerMenuOpen && isResponsive && (
					<DropdownMenu>
						<UserSection>
							<Avatar />

							<Caret
								onClick={toggleUserDropdown}
								dropdownOpen={userDropdownOpen}
							/>
						</UserSection>

						{userDropdownOpen && (
							<UserDropdown>
								<UserAction
									name='settings'
									onClick={() => handleRedirect('/profile')}
								>
									Account Settings
								</UserAction>

								<UserAction name='logout' onClick={handleLogout}>
									Log Out
								</UserAction>
							</UserDropdown>
						)}

						<Divider />

						<DropdownRoutes>
							<Routes />
						</DropdownRoutes>

						<Footer />
					</DropdownMenu>
				)}
			</Container>
			{burgerMenuOpen && isResponsive && <PageOverlay />}
		</>
	);
};

export default withSendBird(PageHeader);
