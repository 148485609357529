import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Label = styled.label`
	display: block;
	margin-bottom: 8px;
	color: ${(props) =>
		props.isValid === false ? props.errorColor : colors.darkGrey};
	text-transform: capitalize;
	${fontStyles[fontSizes.sixteen]};
`;

export const InputWrapper = styled.div`
	width: 100%;
	display: flex;
	position: relative;
	margin-bottom: 13px;
`;

export const Input = styled.input`
	width: 100%;
	height: 40px;
	padding: 7px;
	margin-bottom: 7px;
	border-radius: 4px;
	color: ${colors.navy};
	border: 1px solid;
	background: ${colors.white};
	border-color: ${(props) =>
		props.isValid === false ? props.errorColor : colors.grey};

	&:focus {
		outline: ${colors.navy};
		border-color: ${colors.navy};
	}
`;

export const MessageWrapper = styled.div`
	height: 40px;
`;

export const Message = styled.p`
	margin-bottom: 7px;
	color: ${colors.darkGrey};
`;

export const Button = styled.button`
	position: absolute;
	top: 7px;
	right: 7px;
	border: none;
	outline: none;
	cursor: pointer;
	background: transparent;
`;

export const Icon = styled.img``;
