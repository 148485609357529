import styled from 'styled-components/macro';

import { device } from 'styles/device';

export const Container = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-top: 14px;

	@media ${device.mobileL} {
		flex-direction: column;
	}
`;

export const FormGroup = styled.div`
	width: 49%;

	@media ${device.mobileL} {
		width: 100%;
	}
`;

// TODO: Check this ChangeBtn
export const ChangeBtn = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin-top: 7px;

	button {
		@media ${device.mobileL} {
			width: 100%;
		}
	}
`;
