import React from 'react';

import { useHistory } from 'react-router-dom';

import messaging from 'assets/svg/icons/messaging.svg';
import useConnectionStatus from 'hooks/useConnectionStatus';
import useUnreadMessagesCount from 'hooks/useUnreadMessageCount';

import { Messaging, Icon, SmallText, NotifyPointer } from './Header.styled';

const MessagingBtn = () => {
	const history = useHistory();
	const unreadCount = useUnreadMessagesCount();
	const status = useConnectionStatus();

	const enableBtnClick = () => status === 'Connected';

	const handleClick = () => {
		enableBtnClick() && history.push('/messaging');
	};

	return (
		<Messaging onClick={handleClick} disabled={!enableBtnClick()}>
			<Icon src={messaging} />
			<SmallText>Messaging</SmallText>
			{unreadCount > 0 && <NotifyPointer />}
		</Messaging>
	);
};

export default MessagingBtn;
