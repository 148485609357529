import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Section = styled.div`
	padding: 32px;
	width: 80%;

	@media ${device.mobileL} {
		width: 100%;
	}
`;

export const Heading = styled.div`
	font-weight: 800;
	${fontStyles[fontSizes.twentyFour]};
`;

export const Strong = styled.p`
	color: ${colors.navy};
	margin: 10px 0 5px;
`;

export const Title = styled.div`
	font-weight: 500;
	${fontStyles[fontSizes.eighteen]};
	margin: 20px 0 10px;
`;

export const Description = styled.div`
	font-weight: 400;
	${fontStyles[fontSizes.eighteen]};
	margin-bottom: 10px;
	color: ${colors.darkGrey};
`;

export const List = styled.ul`
	padding-left: 28px;
`;

export const ListItem = styled.li`
	font-weight: 400;
	${fontStyles[fontSizes.eighteen]};
	color: ${colors.darkGrey};
	margin-bottom: 25px;
`;

export const Link = styled.a`
	font-weight: 600;
	text-decoration: none;
	color: ${colors.green};
	cursor: pointer;
`;
