import React from 'react';

import ContentLoader from 'react-content-loader';

const RegistrationLoader = (props) => (
	<ContentLoader
		speed={2}
		width={'100%'}
		height={307}
		viewBox='0 0 340 180'
		backgroundColor='#f3f3f3'
		foregroundColor='#ecebeb'
		{...props}
	>
		<rect x='3' y='77' rx='3' ry='3' width='324' height='18' />
		<rect x='4' y='62' rx='3' ry='3' width='63' height='9' />
		<rect x='269' y='156' rx='9' ry='9' width='60' height='17' />
		<rect x='2' y='125' rx='3' ry='3' width='324' height='18' />
		<rect x='3' y='107' rx='3' ry='3' width='63' height='9' />
		<rect x='3' y='33' rx='3' ry='3' width='324' height='18' />
		<rect x='4' y='17' rx='3' ry='3' width='63' height='9' />
	</ContentLoader>
);
export default RegistrationLoader;
