import React from 'react';

import { useDispatch } from 'react-redux';

import EmptyStateCard from 'common/EmptyStateCard/EmptyStateCard';
import {
	BODY_COMPOSITION_EMPTY_SUB_TEXT,
	BODY_COMPOSITION_EMPTY_TEXT,
} from 'utils/emptyStateMsg';
import { compositionsBodyData } from 'utils/lists';

import CompositionCard from './CompositionCard/CompositionCard';

import { Container, Title, Row } from './BodyComposition.styled';

const BodyComposition = ({ openChart }) => {
	const isDataExist = compositionsBodyData.length > 0;

	const dispatch = useDispatch();

	const renderCompositionCards = () => {
		return compositionsBodyData.map((item) => (
			<CompositionCard
				{...item}
				key={item.name}
				handleSeeMore={handleOpenChart}
			/>
		));
	};

	const handleOpenChart = (data) => {
		openChart();
		dispatch.chartData.update(data);
	};

	return (
		<Container>
			<Title>Body Composition</Title>

			<Row>
				{isDataExist ? (
					renderCompositionCards()
				) : (
					<EmptyStateCard
						text={BODY_COMPOSITION_EMPTY_TEXT}
						subText={BODY_COMPOSITION_EMPTY_SUB_TEXT}
					/>
				)}
			</Row>
		</Container>
	);
};

export default BodyComposition;
