import React from 'react';

import { Wrapper, Span } from './SendMessageButton.styled';

// TODO: Maybe we can use <Button /> component here
const SendMessageButton = ({
	type = 'button',
	id,
	tabIndex = 0,
	onClick,
	children,
	selectedProgram,
}) => {
	const isSelected = selectedProgram === id;
	return (
		<Wrapper
			id={id}
			type={type}
			tabIndex={tabIndex}
			onClick={onClick}
			selected={isSelected}
		>
			<Span selected={isSelected}>{children}</Span>
		</Wrapper>
	);
};

export default SendMessageButton;
