import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useGetTotalBodyFat } from 'api/services';
import ChartModal from 'components/ChartModal/ChartModal';

import { getChartBgColors } from './utils/chartColorizing';
import {
	adaptFatBodyTable,
	gradientBgPlugin,
	handleChartData,
} from './utils/chartHelpers';
import {
	defaultConfig,
	tooltipCallbackPercentage,
	ticksCallbackPercentage,
	styledChartElements,
} from './utils/customViewChart';

const TotalBodyFatChart = ({ closeChart, isOpen }) => {
	const [data, setData] = useState({});
	const [tableData, setTableData] = useState(null);
	const [chartConfig, setChartConfig] = useState(null);
	const [labelsData, setLabelsData] = useState(undefined);

	const { gender } = useSelector((state) => state.user);

	const { data: bodyFatData, loading } = useGetTotalBodyFat();
	const isDataExist = bodyFatData?.data && bodyFatData?.data.length;

	useEffect(() => {
		if (isDataExist) {
			const refValues = bodyFatData?.referential_values?.general;
			const dataAdapted = handleChartData(bodyFatData.data);
			const lastResult = bodyFatData.data[bodyFatData.data.length - 1];
			const tableDataAdapted = adaptFatBodyTable(
				bodyFatData?.referential_values,
				lastResult,
				gender
			);

			setData(dataAdapted);
			setTableData(tableDataAdapted);
			setLabelsData(dataAdapted?.labels);
			handleChartConfig(refValues);
		}
	}, [bodyFatData]);

	const handleChartConfig = (refValues) => {
		const minY = 0;
		const maxY = 60;
		const chartColors = (refValues && getChartBgColors(refValues, maxY)) || [];

		const config = {
			...defaultConfig,
			plugins: {
				...defaultConfig.plugins,
				backgrounds: {
					hbars: chartColors,
				},
				tooltip: {
					...defaultConfig.plugins.tooltip,
					callbacks: { label: tooltipCallbackPercentage },
				},
			},
			elements: styledChartElements(bodyFatData.data.length - 1),
			scales: {
				...defaultConfig.scales,
				y: {
					...defaultConfig.scales.y,
					min: minY,
					max: maxY,
					ticks: {
						...defaultConfig.scales.y.ticks,
						callback: ticksCallbackPercentage,
					},
				},
			},
		};

		setChartConfig(config);
	};

	return (
		<ChartModal
			data={data}
			loading={loading}
			tableData={tableData}
			labelsData={labelsData}
			closeChart={closeChart}
			chartConfig={chartConfig}
			chartPlugins={gradientBgPlugin}
			isOpen={isOpen}
		/>
	);
};

export default TotalBodyFatChart;
