import React from 'react';

import manIcon from 'assets/svg/icons/user.svg';

import {
	Container,
	ImgWrapper,
	TextWrapper,
	Text,
	SubText,
} from './EmptyStateCard.styled';

const EmptyStateCard = ({ image, text = '', subText = '' }) => (
	<Container className='empty-state-card'>
		<ImgWrapper>
			<img src={image || manIcon} alt='Empty state placeholder' />
		</ImgWrapper>

		<TextWrapper>
			<Text>{text}</Text>

			<SubText>{subText}</SubText>
		</TextWrapper>
	</Container>
);

export default EmptyStateCard;
