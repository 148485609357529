// eslint-disable-next-line no-restricted-imports
import { css } from 'styled-components';
import styled from 'styled-components/macro';

import { colors } from 'styles/colors';

// TODO: This file is crying for TypeScript :)

const setButtonPadding = (icon, small) => {
	if (small && icon) {
		return '7px 14px';
	} else if (icon) {
		return '10px 30px 10px 16px';
	} else if (small) {
		return '5px 10px';
	} else {
		return '10px 30px';
	}
};

const setButtonFontSize = (variant, small) => {
	if (variant === 'secondary') {
		return '18px';
	} else if (small) {
		return '12px';
	} else {
		return '16px';
	}
};

const setButtonLineHeight = (variant, small) => {
	if (variant === 'secondary') {
		return '24px';
	} else if (small) {
		return '16px';
	} else {
		return '20px';
	}
};

const setButtonBorder = (variant) => {
	if (variant === 'secondary') {
		return `1px solid ${colors.teal}`;
	} else if (variant === 'tertiary') {
		return `1px solid ${colors.navy}`;
	} else {
		return 'none';
	}
};

export const Container = styled.button`
	align-items: center;
	background-color: ${({ variant }) =>
		variant === 'cancel' ? 'transparent' : colors.white};
	border-radius: ${({ rounded }) => (rounded ? '6px' : '39px')};
	border: ${({ variant }) => setButtonBorder(variant)};
	color: ${({ variant }) => variant === 'cancel' && colors.darkGrey};
	cursor: pointer;
	display: flex;
	font-size: ${({ variant, small }) => setButtonFontSize(variant, small)};
	font-weight: ${({ variant }) => (variant === 'tertiary' ? 'normal' : 800)};
	justify-content: center;
	line-height: ${({ variant, small }) => setButtonLineHeight(variant, small)};
	margin: ${({ margin }) => margin};
	overflow: hidden;
	padding: ${({ icon, small }) => setButtonPadding(icon, small)};
	position: relative;
	z-index: 0;
	// Primary
	background-image: ${({ variant }) =>
		variant === 'primary' && colors.gradient};
	${({ variant }) =>
		variant === 'primary' &&
		css`
			&:before {
				content: '';
				background-color: ${colors.white};
				border-radius: ${({ rounded }) => (rounded ? '5px' : 'inherit')};
				bottom: 1px;
				box-sizing: border-box;
				left: 1px;
				position: absolute;
				right: 1px;
				top: 1px;
				z-index: -1;
			}
		`}

	&:hover {
		box-shadow: ${({ variant }) =>
			variant !== 'cancel' && css`0px 4px 8px ${colors.black20}`};

		// Primary
		${({ variant }) =>
			variant === 'primary' &&
			css`
				background-image: ${colors.white};

				&:before {
					content: '';
					background-image: ${colors.gradientGreen};
				}

				span {
					background: ${colors.gradientText};
					background-clip: text;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}

				img {
					filter: brightness(0) invert(1);
				}
			`}

		// Secondary
		${({ variant }) =>
			variant === 'secondary' &&
			css`
				background-color: ${colors.teal};

				span {
					color: ${colors.white};
				}
			`}

		// Tertiary
		${({ variant }) =>
			variant === 'tertiary' &&
			css`
				background-color: ${colors.navy};

				span {
					color: ${colors.white};
				}
			`}

		// Cancel
		${({ variant }) =>
			variant === 'cancel' &&
			css`
				color: ${colors.navy};
			`}
	}

	&:disabled {
		background: ${colors.grey20};
		box-shadow: none;
		cursor: not-allowed;
		border: none;

		&:before {
			content: '';
			background: ${colors.grey20};
		}

		&:hover {
			background: ${colors.grey20};
			box-shadow: none;
			cursor: not-allowed;
			border: none;

			&:before {
				content: '';
				background: ${colors.grey20};
			}

			span {
				background: ${colors.darkGrey};
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}

			img {
				filter: none;
			}
		}

		span {
			background: ${colors.darkGrey};
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}
`;

export const Label = styled.span`
	// Primary
	${({ variant }) =>
		variant === 'primary' &&
		css`
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-image: ${colors.gradient};
		`}
	// Secondary
	${({ variant }) =>
		variant === 'secondary' &&
		css`
			color: ${colors.teal};
		`}
	// Tertiary
	${({ variant }) =>
		variant === 'tertiary' &&
		css`
			color: ${colors.navy};
		`}
`;

export const Icon = styled.img`
	height: ${({ small }) => small && '15px'};
	margin-right: 11px;
	width: ${({ small }) => small && '15px'};
`;
