import React from 'react';

import { benefitsData } from 'utils/lists';

import {
	Container,
	Title,
	Content,
	MainTitle,
	SubTitle,
	Text,
	BenefitsList,
	BenefitWrapper,
	Number,
	Circle,
	Line,
	BenefitText,
} from './Benefits.styled';

const Benefits = () => (
	<Container>
		<Content>
			<MainTitle>
				<Title>{benefitsData.title}</Title>
			</MainTitle>

			<div>
				<SubTitle>
					<Text>{benefitsData.subTitle}</Text>
				</SubTitle>

				<BenefitsList>
					{benefitsData.infos.map(({ number, text, renderLine }) => (
						<BenefitWrapper key={number}>
							<Number>
								<Circle>{number}</Circle>
								{renderLine && <Line />}
							</Number>

							<BenefitText>{text}</BenefitText>
						</BenefitWrapper>
					))}
				</BenefitsList>
			</div>
		</Content>
	</Container>
);

export default Benefits;
