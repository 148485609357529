// Because of https://caniuse.com/resizeobserver
// adding polyfill for ResizeObserver
import 'resize-observer-polyfill/dist/ResizeObserver.global';

import React from 'react';

import ReactDOM from 'react-dom';

import { initializeGtag } from 'utils/analytics';
import { GOOGLE_ANALYTICS_ID } from 'utils/constants';

import App from './App';

initializeGtag(GOOGLE_ANALYTICS_ID);

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);
