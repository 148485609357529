import React from 'react';

import Footer from 'components/Footer/Footer';
import Logo from 'components/Logo/Logo';
import Routes from 'components/Routes/Routes';

import { Container, LogoWrapper, RoutesContainer } from './Sidebar.styled';

const Sidebar = () => {
	return (
		<Container>
			<LogoWrapper>
				<Logo width='220px' height='40px' />
			</LogoWrapper>

			<RoutesContainer>
				<Routes />
				<Footer />
			</RoutesContainer>
		</Container>
	);
};

export default Sidebar;
