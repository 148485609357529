import React from 'react';

import { useSelector } from 'react-redux';

import cenegenicsAvatarImg from 'assets/img/cen_avatar.png';
import avatarImg from 'assets/svg/icons/user-avatar.svg';
import Avatar from 'components/Avatar/Avatar';
import { getDateFromTimestamp } from 'utils';
import {
	getChannelName,
	interlocutors,
	findPhoto,
} from 'utils/sendBirdHelpers';

import { Username } from '../Chat.styled';

import {
	ChannelsContainer,
	Column,
	ListHeader,
	UserDetails,
	CreateGroupBtn,
	ButtonIcon,
	Row,
	List,
	ChannelItem,
	ImageWrapper,
	Image,
	ChannelName,
	OnlineNotify,
	ImageOnlineNotifyBox,
	MembersCounter,
	DateLabel,
	LastMessage,
	BaseData,
	Avatars,
	ChannelAvatar,
	MoreUsersCount,
} from './ChannelsList.styled';

const renderSimpleAvatarImages =
	`${process.env.REACT_APP_SIMPLE_AVATARS}` === 'true';

const MAX_AVATARS_VISIBLE = 3;

const ChannelsLists = (props) => {
	const {
		currentChannelUrl,
		handleOpenModal,
		handleChannelSelect,
		myCareTeam,
		channels,
	} = props;

	const { firstName, picture, userId } = useSelector((state) => state.user);

	const avatarOrSafeImage = (avatar) =>
		renderSimpleAvatarImages ? (
			<img src={avatar.img || avatarImg} alt={avatar.alt} />
		) : (
			<Image src={avatar.img} alt={avatar.alt} />
		);

	const renderChannels = (channelsList) =>
		channelsList
			.map((el) => {
				let team = [];
				if (myCareTeam) {
					team = Object.keys(myCareTeam).map((k) => myCareTeam[k]);
				}
				let others = interlocutors(el.members, userId);
				let avatars = others.map((m) => {
					return {
						alt: m.nickname,
						img: findPhoto(team, m.userId, cenegenicsAvatarImg),
						online: m.connectionStatus === 'online',
					};
				});

				return {
					...el,
					hasOnlineMembers: others.some((o) => o.connectionStatus === 'online'),
					customTitle: getChannelName(others, 'nickname'),
					avatars: avatars,
				};
			})
			.filter((item) => {
				return item.memberCount > 1;
			})
			.map(
				({
					url,
					customTitle: name,
					lastMessage,
					memberCount,
					createdAt,
					unreadMessageCount,
					hasOnlineMembers,
					avatars,
				}) => {
					const timestamp = lastMessage?.createAt || createdAt;
					const date = timestamp && getDateFromTimestamp(timestamp);
					const membersNum = memberCount;
					const memberOrMembers = membersNum === 1 ? 'member' : 'members';
					const numberOfAvatars = avatars.length;

					return (
						<ChannelItem
							key={url}
							selected={url === currentChannelUrl}
							onClick={() => handleChannelSelect(url)}
							hasNewMessages={unreadMessageCount}
						>
							<ImageWrapper>
								<Avatars>
									{avatars
										.reverse()
										.slice(0, 3)
										.map((a, idx) => {
											return (
												<ChannelAvatar
													numberOfAvatars={numberOfAvatars}
													key={idx}
												>
													<ImageOnlineNotifyBox>
														{avatarOrSafeImage(a)}

														{hasOnlineMembers && (
															<OnlineNotify numberOfAvatars={numberOfAvatars} />
														)}
													</ImageOnlineNotifyBox>
												</ChannelAvatar>
											);
										})}
								</Avatars>

								{numberOfAvatars > MAX_AVATARS_VISIBLE && (
									<MoreUsersCount>
										+ {numberOfAvatars - MAX_AVATARS_VISIBLE}
									</MoreUsersCount>
								)}
							</ImageWrapper>

							<Row>
								<BaseData>
									<ChannelName title={name}>{name}</ChannelName>
									<LastMessage>{lastMessage?.message || ''}</LastMessage>
								</BaseData>
								<Column>
									<DateLabel>{date}</DateLabel>

									<MembersCounter>
										{membersNum}
										<span>{memberOrMembers}</span>
									</MembersCounter>
								</Column>
							</Row>
						</ChannelItem>
					);
				}
			);

	return (
		<ChannelsContainer>
			<ListHeader>
				<UserDetails>
					<Avatar picture={picture} allowSwitch={false} />
					<Username>{firstName || ''}</Username>
				</UserDetails>
				{/* TODO: Think it over if we can put this component into <Button /> as well */}
				<CreateGroupBtn>
					<ButtonIcon onClick={() => handleOpenModal()}>
						<i className='lni lni-circle-plus'></i>
					</ButtonIcon>
				</CreateGroupBtn>
			</ListHeader>
			<List>{renderChannels(channels)}</List>
		</ChannelsContainer>
	);
};

export default ChannelsLists;
