import React, { useState } from 'react';

import { useMediaQuery } from 'react-responsive';

import { useGetMyProductFile } from 'api/services';
import infoIcon from 'assets/svg/icons/info-navy.svg';
import PDFModal from 'components/PDFModal/PDFModal';
import { device } from 'styles/device';
import { formatDate } from 'utils';
import { productIcons } from 'utils/iconsMappings';

import {
	CardWrapper,
	Card,
	Title,
	Icon,
	InfoIcon,
	LearnMore,
	Package,
	Divider,
	Usage,
	UsageContainer,
	Refill,
	RefillContainer,
	GeneralInfo,
} from './ProductCard.styled';

const ProductCard = ({
	title,
	category,
	packaging,
	usage,
	lastRefill,
	nextRefill,
	productId,
}) => {
	const [isModalOpen, setModalOpen] = useState(false);
	const openModalHandler = () => setModalOpen(true);

	const isMobile = useMediaQuery({ query: device.tablet });

	return (
		<>
			<CardWrapper>
				<Card onClick={openModalHandler} tabIndex='0'>
					{isMobile && (
						<LearnMore>
							<InfoIcon src={infoIcon} />
							Learn More
						</LearnMore>
					)}

					<GeneralInfo>
						<Icon src={productIcons[category]} />
						<Title>{title}</Title>
						<Package>{packaging}</Package>
					</GeneralInfo>

					{usage && (
						<UsageContainer>
							<Divider />
							<Usage>{usage}</Usage>
						</UsageContainer>
					)}
					{(lastRefill || nextRefill) && (
						<RefillContainer>
							<Divider />
							{nextRefill && (
								<Refill>{`Next Refill: ${formatDate(nextRefill)}`}</Refill>
							)}
							{lastRefill && (
								<Refill>{`Last Refill: ${formatDate(lastRefill)}`}</Refill>
							)}
						</RefillContainer>
					)}
				</Card>
			</CardWrapper>

			{/* TODO: Move this logic into PDFModal */}
			{isModalOpen && (
				<PDFModal
					isOpen={isModalOpen}
					onClose={() => setModalOpen(false)}
					getPDF={useGetMyProductFile}
					pdfId={productId}
					title={title}
				/>
			)}
		</>
	);
};

export default ProductCard;
