import React from 'react';

import {
	Container,
	Title,
	Table,
	TableRow,
	TableColumn,
	HeaderCell,
	TableData,
} from './ReferentialTable.styled';

const ReferentialTable = ({ data }) => {
	const { firstList, secondList, thirdList } = data;

	const isTwoColumn = !thirdList;

	const renderColumn = (list) =>
		list &&
		list.map(({ value, selected }, idx) => (
			<TableData key={idx} className='table-data' isSelected={selected}>
				{value}
			</TableData>
		));

	return (
		<Container>
			<Title>Referential Values</Title>

			<Table role='table'>
				<TableRow>
					<TableColumn className='first-column' isTwoColumn={isTwoColumn}>
						<HeaderCell>{firstList?.title || 'n/a'}</HeaderCell>
						{renderColumn(firstList.data)}
					</TableColumn>

					<TableColumn isTwoColumn={isTwoColumn}>
						<HeaderCell>{secondList?.title || 'n/a'}</HeaderCell>
						{renderColumn(secondList.data)}
					</TableColumn>
					{thirdList && (
						<TableColumn>
							<HeaderCell>{thirdList?.title || 'n/a'}</HeaderCell>
							{renderColumn(thirdList.data)}
						</TableColumn>
					)}
				</TableRow>
			</Table>
		</Container>
	);
};

export default ReferentialTable;
