import styled from 'styled-components/macro';

import { device } from 'styles/device';

export const Icon = styled.button`
	margin: -45px 0 10px 55px;
	outline: none;
	border: none;
	background: transparent;
	cursor: pointer;
	z-index: 9;

	@media ${device.laptopL} {
		margin: -45px 0 10px 40px;
	}
`;
