import React from 'react';

import ContentLoader from 'react-content-loader';

import {
	Container,
	LoaderContainer,
	HeaderContainer,
} from './MyProductsLoader.styled';

const Loader = (props) => (
	<Container>
		<ContentLoader
			speed={2}
			width={207}
			height={351}
			viewBox='0 0 207 351'
			backgroundColor='#f3f3f3'
			foregroundColor='#ecebeb'
			{...props}
		>
			<rect x='39' y='13' rx='4' ry='4' width='72' height='16' />
			<rect x='16' y='162' rx='4' ry='4' width='175' height='16' />
			<rect x='16' y='221' rx='4' ry='4' width='175' height='16' />
			<rect x='41' y='242' rx='4' ry='4' width='125' height='16' />
			<rect x='16' y='298' rx='4' ry='4' width='175' height='16' />
			<rect x='41' y='319' rx='4' ry='4' width='125' height='16' />
			<rect x='13' y='13' rx='8' ry='8' width='16' height='16' />
			<rect x='76' y='48' rx='28' ry='28' width='55' height='55' />
			<rect x='42' y='119' rx='4' ry='4' width='124' height='26' />
		</ContentLoader>
	</Container>
);

const Header = (props) => (
	<HeaderContainer>
		<ContentLoader
			speed={2}
			width={1115}
			height={126}
			viewBox='0 0 1115 126'
			backgroundColor='#f3f3f3'
			foregroundColor='#ecebeb'
			{...props}
		>
			<rect x='881' y='81' rx='23' ry='23' width='234' height='45' />
			<rect x='0' y='46' rx='4' ry='4' width='575' height='21' />
			<rect x='0' y='100' rx='4' ry='4' width='85' height='21' />
			<rect x='112' y='100' rx='4' ry='4' width='49' height='21' />
			<rect x='469' y='98' rx='4' ry='4' width='379' height='21' />
			<rect x='0' y='0' rx='4' ry='4' width='141' height='32' />
		</ContentLoader>
	</HeaderContainer>
);

const MyProductsLoader = (props) => (
	<>
		<Header />
		<LoaderContainer>
			{[...Array(8)].map((_, index) => (
				<Loader key={index} {...props} />
			))}
		</LoaderContainer>
	</>
);

export default MyProductsLoader;
