import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Span = styled.span`
	font-weight: 800;
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	background-image: ${colors.gradientGreen};
`;

export const Container = styled.div`
	padding: 28px;
	height: 100vh;
`;

export const SubHeading = styled.div`
	display: flex;
	align-items: baseline;
	justify-content: space-between;

	@media ${device.mobileM} {
		flex-flow: column wrap;
		flex-direction: column-reverse;
	}
`;

export const Tabs = styled.ul`
	display: flex;
	flex-shrink: 0;
	margin-top: 28px;

	@media ${device.mobileM} {
		width: 100%;
		justify-content: center;
	}
`;

export const Tab = styled.li`
	display: flex;
	${fontStyles[fontSizes.eighteen]};
	border-bottom: ${({ isActive }) =>
		isActive ? '3px solid transparent' : 'unset'};
	background-image: ${({ isActive }) =>
		isActive
			? `linear-gradient(rgba(0, 145, 148, 1), rgba(72, 71, 112, 1)), ${colors.gradientGreen}`
			: 'unset'};
	background-clip: ${({ isActive }) =>
		isActive ? 'content-box, border-box' : 'unset'};
	box-shadow: 2px 1000px 1px ${colors.offWhite} inset;
	cursor: pointer;
	outline: none;
	margin-right: 25px;
	align-items: baseline;

	${Span} {
		${({ isActive }) =>
			!isActive &&
			`
				font-weight: unset;
				-webkit-background-clip: unset;
				-webkit-text-fill-color: unset;
				border-image-source: unset;
				background-image: unset;
			`}
	}
`;

export const GenericMessage = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;

	@media ${device.mobileL} {
		align-items: flex-start;
	}

	@media ${device.mobileM} {
		flex-direction: column;
	}
`;

export const Message = styled.p`
	font-weight: 800;
	color: ${colors.navy};
	${fontStyles[fontSizes.eighteen]};
	margin-right: 32px;

	@media ${device.mobileL} {
		margin-bottom: 18px;
	}
`;

export const CardsContainer = styled.div`
	margin-top: 30px;
	margin-left: -20px;
	display: flex;
	flex-wrap: wrap;
`;

export const EmptyContainer = styled.div`
	height: 60%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	color: ${colors.darkGrey};
	text-align: center;
`;

export const Icon = styled.img``;

export const IconWrapper = styled.div`
	display: flex;
	width: 230px;
	height: 140px;
	border-radius: 13px;
	background: ${colors.softBlue};
	align-items: center;
	justify-content: center;
`;

export const EmptyTitle = styled.div`
	margin: 20px 0;
	${fontStyles[fontSizes.eighteen]}
`;

export const EmptySubtitle = styled.div`
	${fontStyles[fontSizes.fourteen]}
`;
