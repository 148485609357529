import React from 'react';

import { Container, MainTitle, SubTitle } from './Titles.styled';

const Titles = ({ mainTitle, subTitle }) => (
	<Container>
		<MainTitle>{mainTitle}</MainTitle>
		<SubTitle>{subTitle}</SubTitle>
	</Container>
);

export default Titles;
