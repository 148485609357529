import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Section = styled.section`
	display: flex;
	flex-direction: column;
	padding: 28px;
`;

export const Tabs = styled.ul`
	display: flex;
	flex-shrink: 0;
	width: 100%;
	margin-top: 15px;
`;

export const Tab = styled.li`
	display: flex;
	${fontStyles[fontSizes.eighteen]};
	border-bottom: 3px solid transparent;
	background-image: linear-gradient(rgba(0, 145, 148, 1), rgba(72, 71, 112, 1)),
		${colors.gradientGreen};
	background-clip: content-box, border-box;
	box-shadow: 2px 1000px 1px ${colors.offWhite} inset;
	cursor: pointer;
	outline: none;
`;

export const Span = styled.span`
	font-weight: 800;
	-webkit-text-stroke: transparent;
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-image: ${colors.gradientGreen};
`;

export const Content = styled.div`
	margin-top: 21px;
`;

export const Row = styled.div`
	width: 100%;
	display: flex;

	@media ${device.mobileL} {
		flex-direction: column;
		align-items: center;
	}
`;

export const RowHealthAndFitness = styled(Row)`
	@media ${device.laptop}, ${device.mobileXL} and (${device.landscape}) {
		flex-flow: row wrap;

		& > div {
			&:first-child {
				margin: 20px 0 0;
			}
		}
	}
`;

export const CardWrapper = styled.div`
	min-width: 380px;
	min-height: 294px;

	@media ${device.laptopL} {
		min-width: 284px;
	}

	@media ${device.laptop} {
		min-width: 229px;
		min-height: 227px;
	}

	@media ${device.mobileL} {
		width: 100%;
	}
`;
