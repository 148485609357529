import styled from 'styled-components/macro';

import { device } from 'styles/device';
import { fontSizes, fontStyles } from 'styles/fonts';

export const EditContainer = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
`;

export const Container = styled.div`
	width: 49%;
	display: flex;
	justify-content: space-between;
	margin-top: 14px;

	@media ${device.mobileL} {
		flex-direction: column;
		width: 100%;
	}

	.password-holder {
		label {
			margin-bottom: 0;
		}

		input {
			background: transparent;
			border: none;
			margin-bottom: 0;
			padding-left: 0;
			${fontStyles[fontSizes.twentyFour]};
		}
	}
`;

export const FormGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;

	@media ${device.mobileL} {
		width: 100%;
	}
`;

export const ChangeBtnWrapper = styled.div`
	align-self: center;

	@media ${device.mobileL} {
		width: 100%;
	}

	button {
		padding: 0 14px;
		min-width: 170px;
		height: 38px;

		span {
			font-size: 14px;
			line-height: 18px;
		}
	}
`;
