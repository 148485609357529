import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

export const ImgWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	width: 164px;
	height: 108px;
	border-radius: 13px;
	background: ${colors.softBlue};
`;

export const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	text-align: center;
	width: 360px;
	margin-top: 14px;

	@media ${device.mobileL} {
		width: 100%;
	}
`;

export const Text = styled.p`
	font-weight: 500;
	color: ${colors.darkGrey};
	${fontStyles[fontSizes.eighteen]};
`;

export const SubText = styled.p`
	margin-top: 7px;
	font-weight: 400;
	color: ${colors.darkGrey};
	${fontStyles[fontSizes.twelve]};
`;
