import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import Button from 'common/Button/Button';
import Input from 'common/Input/Input';

import PasswordChange from './PasswordChange/PasswordChange';
import ProfileEdit from './ProfileEdit/ProfileEdit';

import {
	EditContainer,
	Container,
	FormGroup,
	ChangeBtnWrapper,
} from './EditAccount.styled';

const EditAccount = ({
	isValid,
	setIsValid,
	showPassChange,
	setShowPassChange,
}) => {
	const { password, confirmPassword } = useSelector((state) => state.user);
	const dispatch = useDispatch();

	const handleChange = (evt) => {
		const { name, value } = evt.target;

		setIsValid(true);
		toast.dismiss();
		dispatch.user.updateUser({ [name]: value });
	};

	const enablePasswordChange = () => setShowPassChange(true);

	return (
		<EditContainer>
			<ProfileEdit />

			{showPassChange ? (
				<PasswordChange
					isValid={isValid}
					password={password}
					confirmPassword={confirmPassword}
					handleChange={handleChange}
				/>
			) : (
				<Container>
					<FormGroup className='password-holder'>
						<Input
							name='passPlaceholder'
							type='password'
							value='password'
							labelText='Password'
							disabled
						/>
					</FormGroup>

					<ChangeBtnWrapper>
						<Button variant='tertiary' rounded onClick={enablePasswordChange}>
							Change Password
						</Button>
					</ChangeBtnWrapper>
				</Container>
			)}
		</EditContainer>
	);
};

export default EditAccount;
