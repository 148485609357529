import React from 'react';

import { useSelector } from 'react-redux';

import Avatar from 'components/Avatar/Avatar';

import { Container, Username } from './UserImage.styled';

const UserImage = () => {
	const { firstName, lastName, picture } = useSelector((state) => state.user);

	return (
		<Container>
			<Avatar picture={picture} allowSwitch={false} />
			<Username>{firstName ? `${firstName} ${lastName}` : 'Unknown'}</Username>
		</Container>
	);
};

export default UserImage;
