import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';

export const Container = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	padding: 0 9px;

	@media ${device.mobileL} {
		width: 100%;
		justify-content: center;
		align-items: center;
	}
`;

export const MainTitle = styled.div`
	margin-top: 70px;

	@media ${device.tablet} {
		margin: 0 0 70px 0;
	}

	@media ${device.mobileL}, ${device.mobileXL} and (${device.landscape}) {
		margin: 28px 0;
		text-align: center;
	}
`;

export const Title = styled.p`
	font-size: 34px;
	font-weight: 800;
	color: ${colors.white};

	@media ${device.tablet} {
		font-size: 24px;
	}

	@media ${device.mobileL} {
		margin-bottom: 17px;
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	height: 100%;
	width: 90%;

	@media ${device.tablet} {
		justify-content: center;
	}

	@media ${device.mobileXL} and (${device.landscape}) {
		align-items: center;
		margin: 20px 0;
	}
`;

export const SubTitle = styled.div`
	margin-bottom: 21px;

	@media ${device.mobileL}, ${device.mobileXL} and (${device.landscape}) {
		text-align: center;
	}
`;

export const Text = styled.p`
	font-size: 17px;
	font-weight: 800;
	color: ${colors.white};

	@media ${device.laptop} {
		font-size: 16px;
	}
`;

export const BenefitText = styled(Text)`
	display: flex;
	align-items: center;
	font-weight: 400;
	margin-left: 7px;
	height: 35px;

	@media ${device.laptop} {
		font-size: 14px;
	}

	@media ${device.mobileL}, ${device.mobileXL} and (${device.landscape}) {
		height: auto;
		width: 80%;
		text-align: center;
		margin-left: 0;
	}
`;

export const BenefitsList = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	position: relative;

	@media ${device.mobileL}, ${device.mobileXL} and (${device.landscape}) {
		flex-direction: row;
	}
`;

export const BenefitWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	position: relative;

	@media ${device.mobileL}, ${device.mobileXL} and (${device.landscape}) {
		width: 33.33%;
		flex-direction: column;
		align-items: center;
	}
`;

export const Number = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media ${device.mobileL}, ${device.mobileXL} and (${device.landscape}) {
		flex-direction: row;
		margin-bottom: 7px;
	}
`;

export const Line = styled.div`
	width: 3.28px;
	height: 33.35px;
	margin: 7px 0;
	border-radius: 18px;
	background: rgba(255, 255, 255, 0.3);

	@media ${device.mobileL}, ${device.mobileXL} and (${device.landscape}) {
		position: absolute;
		left: 64%;
		width: 72%;
		height: 5px;
	}

	@media ${device.mobileM} {
		left: 66%;
		width: 66.5%;
	}
`;

export const Circle = styled.span`
	width: 36px;
	height: 36px;
	font-size: 18px;
	font-weight: 800;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	background: rgba(255, 255, 255, 0.3);
	position: relative;
`;
