import React from 'react';

import Avatar from 'components/Avatar/Avatar';

import { Username } from '../Chat.styled';

import { UserItem, UserDataContainer, Checkbox } from './MembersList.styled';

const MembersList = (props) => {
	const { handleSelectMember, selectedMembers, myCareTeam } = props;

	return (
		myCareTeam &&
		Object.keys(myCareTeam)
			.filter((k) => myCareTeam[k].user_in_sendbird)
			.map((key) => {
				const { id, photo, full_name } = myCareTeam[key];

				return (
					<UserItem key={id}>
						<UserDataContainer>
							<Avatar picture={photo} altText='member' />

							<Username>{full_name || ''}</Username>
						</UserDataContainer>

						<Checkbox
							type='checkbox'
							onClick={() =>
								handleSelectMember({ id, full_name }, selectedMembers)
							}
						/>
					</UserItem>
				);
			})
	);
};

export default MembersList;
