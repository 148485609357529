// cannot set "overrides" in .eslintrc.json so we disabled the ESlint for createGlobalStyle
// eslint-disable-next-line
import { createGlobalStyle } from 'styled-components';

import { colors } from 'styles/colors';

import { device } from './device';
import AvenirWoff from './fonts/avenir-kris.woff';
import AvenirWoff2 from './fonts/avenir-kris.woff2';

const GlobalStyle = createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  @font-face {
    font-family: 'Avenir';
    src: url(${AvenirWoff2}) format('woff2'),
        url(${AvenirWoff}) format('woff');
		font-display: swap;
		font-weight: normal;
		font-style: normal;
  }

  body {
    font-family: Avenir, Open-Sans, Sans-Serif, Helvetica !important;
    font-style: normal;
    font-size: 14px;
    background: ${colors.offWhite};
  }

  button, input, select, textarea {
		font-family: inherit !important;
  }


  // Toast notifications style
  .Toastify__toast--success {
    background: ${colors.green70};
  }

  .Toastify__toast--warning {
    background: ${colors.yellow70};
  }

  .Toastify__toast--error {
    background: ${colors.red80};
  }

  a.active-route {
    background: ${colors.gradientGreen};
    border-radius: 8px;


    > div {
      background: ${colors.white};
      border-radius: 7px;
      font-weight: 600;

      > div {
        background: linear-gradient(136.85deg, rgba(0, 145, 148, 0.1) 16.87%, rgba(72, 71, 112, 0.1) 82.61%);
        border-radius: 7px;

        > span {
          background-image: linear-gradient(136.85deg,
          ${colors.green} 16.87%, ${colors.purple} 82.61%);
          background-size: 100%;
					background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-background-clip: text;
          -moz-text-fill-color: transparent;

        }
      }
    }
  }

  .tooltip {
		background: ${colors.white};
		border-radius: 4px;
		padding: 10px;
		font-weight: 400;
		font-style: normal;
		border: 1px solid ${colors.grey30};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
	}

	/*
	*
	* SendBird styles
	*
	*/
	[class*=sendbird-label] {
		font-family: Avenir, Open-Sans, Sans-Serif, Helvetica !important;
	}

	.sendbird-fileviewer__header__right {
		.sendbird-icon {
			cursor: pointer;
		}
	}

	.sendbird-fileviewer__content {
		.sendbird-fileviewer__content__img {
			max-width: 90%;
			max-height: 90%;

			@media ${device.mobileL} {
				max-width: 70%;
				max-height: 70%;
			}
		}
	}

	.sendbird-modal {
		background-color: rgba(5, 14, 44, 0.5);

		.sendbird-modal__content {
			background-color: ${colors.white};
			border-radius: 10px;
			border: 1px solid ${colors.grey30};
			box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
			display: flex;
			flex-direction: column;
			justify-content: center;
			min-height: 225px;
			width: 450px;
			max-width: 90%;
			padding: 40px 25px 25px;
			position: relative;

			.sendbird-modal__header {
				margin-top: 25px;
				text-align: center;

				& > span {
					color: ${colors.navy};
					font-size: 20px;
					font-weight: 800;
					line-height: 22px;
				}
			}

			.sendbird-modal__footer {
				display: flex;
				justify-content: flex-end;
				margin-top: auto;

				& > button {
					& > span > span, & > span  {
						font-size: 20px;
						font-weight: 800;
						line-height: 27px;
					}

					&:first-child {
						border: none;

						& > span > span {
							color: ${colors.darkGrey};
						}

						&:focus {
							box-shadow: none;
						}

						&:hover {
							font-weight: 900;
						}
					}

					&:nth-child(2) {
						background-image: ${colors.gradient};
						border-radius: 39px;
						border: none;
						overflow: hidden;
						position: relative;
						z-index: 0;

						&:before {
							content: '';
							background-color: ${colors.white};
							border-radius: inherit;
							bottom: 1px;
							box-sizing: border-box;
							left: 1px;
							position: absolute;
							right: 1px;
							top: 1px;
							z-index: -1;
						}

						& > span {
							background-image: ${colors.gradient};
							margin: 10px 25px;
							background-clip: text;
							-webkit-background-clip: text;
							-webkit-text-fill-color: transparent;
						}

						&:hover {
							background-image: ${colors.white};
							box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);

							&:before {
								content: '';
								background-image: ${colors.gradientGreen};
							}

							& > span {
								background: linear-gradient(136.85deg, ${colors.white} 16.87%,
                ${colors.white} 82.61%);
								background-clip: text;
								-webkit-background-clip: text;
								-webkit-text-fill-color: transparent;
							}
						}

					}
				}
			}

			.sendbird-modal__close {
				position: absolute;
				right: 17px;
				top: 10px;
			}
		}
	}

	.sendbird-theme--light {
		.sendbird-message-input__edit {
			background-color: ${colors.softBlue};

			.sendbird-message-input {
				.sendbird-message-input--textarea {
					min-height: 100px;
				}
			}

			.sendbird-message-input--edit-action {
				display: flex;
				justify-content: flex-end;
				margin-top: 25px;

				& > button {
					align-items: center;
					display: flex;
					justify-content: center;
					width: auto;
					height: 40px;

					&:focus {
						box-shadow: none;
					}

					& > span {
						font-size: 20px;
						font-weight: 800;
						line-height: 27px;
						margin: 0;
					}

					&:first-child {
						border: none;
						margin-right: 7px;

						span {
							color: ${colors.darkGrey} !important;
						}
					}

					&:nth-child(2) {
						background-image: ${colors.gradient};
						border-radius: 39px;
						border: none;
						overflow: hidden;
						position: relative;
						z-index: 0;

						&:before {
							content: '';
							background-color: ${colors.white};
							border-radius: inherit;
							bottom: 1px;
							box-sizing: border-box;
							left: 1px;
							position: absolute;
							right: 1px;
							top: 1px;
							z-index: -1;
						}

						& > span {
							background-image: ${colors.gradient};
							margin: 10px 25px;
							background-clip: text;
							-webkit-background-clip: text;
							-webkit-text-fill-color: transparent;
						}

						&:hover {
							background-image: ${colors.white};
							box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);

							&:before {
								content: '';
								background-image: ${colors.gradientGreen};
							}

							& > span {
								background: linear-gradient(136.85deg, ${colors.white} 16.87%,
                ${colors.white} 82.61%);
								background-clip: text;
								-webkit-background-clip: text;
								-webkit-text-fill-color: transparent;
							}
						}
					}
				}
			}
		}

		.sendbird-fileviewer__header {
			height: 100px;
			display: flex;
			flex-direction: column-reverse;
			padding: 17px 7px 7px 7px !important;

			@media ${device.mobileL} {
				height: auto;
			}
		}

		.sendbird-fileviewer__header__left {
			display: flex;
			flex-direction: column-reverse;
		}

		.sendbird-fileviewer__header__right {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			div {
				margin-left: 5px;
			}

		}

		.sendbird-fileviewer__header__right__actions {
			display: flex !important;
			align-items: center !important;

			div {
				margin-left: 5px;
			}
		}

		.sendbird-avatar {
			display: none !important;
		}

	}

	.sendbird-dropdown__menu {
		li > span {
			font-family: Avenir;
		}
	}
`;

export default GlobalStyle;
