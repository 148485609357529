import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { useUploadImage, useRemoveImage } from 'api/services';
import Button from 'common/Button/Button';
import FileUploadBtn from 'common/FileUploadBtn/FileUploadBtn';
import Avatar from 'components/Avatar/Avatar';
import { resizeFile, showErrorNotify, showSuccessNotify } from 'utils';
import {
	IMAGE_UPLOAD_SUCCESS,
	IMAGE_UPLOAD_FAILED,
	IMAGE_REMOVE_FAILED,
	IMAGE_REMOVE_SUCCESS,
	BOTTOM_LEFT,
} from 'utils/constants';

import {
	Container,
	SubTitle,
	Row,
	GroupButtons,
	UploadBtnWrapper,
	RemoveBtnWrapper,
} from './AvatarContainer.styled';

const AvatarContainer = () => {
	const { picture } = useSelector((state) => state.user);

	const { mutate: uploadImg, loading: uploadLoading } = useUploadImage();
	const { mutate: removeImg, loading: removeLoading } = useRemoveImage();
	const loading = uploadLoading || removeLoading;

	const dispatch = useDispatch();

	const handleUploadFile = async (file) => {
		if (!file) return;

		try {
			const image = await resizeFile(file);

			await uploadImg(image);
			dispatch.user.uploadPicture(image);

			showSuccessNotify(IMAGE_UPLOAD_SUCCESS, BOTTOM_LEFT);
		} catch (err) {
			console.error(err);
			showErrorNotify(IMAGE_UPLOAD_FAILED, BOTTOM_LEFT);
		}
	};

	const handleRemoveImg = async () => {
		if (!picture) return;

		try {
			await removeImg();
			dispatch.user.uploadPicture(null);

			showSuccessNotify(IMAGE_REMOVE_SUCCESS, BOTTOM_LEFT);
		} catch (err) {
			console.error(err);
			showErrorNotify(IMAGE_REMOVE_FAILED, BOTTOM_LEFT);
		}
	};

	return (
		<Container>
			<SubTitle>Picture</SubTitle>
			<Row>
				<Avatar picture={picture} loading={loading} allowSwitch={false} />

				<GroupButtons>
					<UploadBtnWrapper>
						<FileUploadBtn
							handleFile={handleUploadFile}
							allowExtensions='.png,.jpeg,.webp'
							disabled={loading}
						/>
					</UploadBtnWrapper>

					<RemoveBtnWrapper>
						<Button
							disabled={!picture || loading}
							margin='0 0 0 10px'
							onClick={handleRemoveImg}
							rounded
							variant='tertiary'
						>
							Remove
						</Button>
					</RemoveBtnWrapper>
				</GroupButtons>
			</Row>
		</Container>
	);
};

export default AvatarContainer;
