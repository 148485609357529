import React from 'react';

import Chat from 'components/Chat/Chat';

import { Section } from './Messaging.styled';

const Messaging = () => (
	<Section>
		<Chat />
	</Section>
);

export default Messaging;
