import React, { useState, useEffect, useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { useGetBiometrics, useGetUser } from 'api/services';
import Titles from 'common/Titles/Titles';
import BodyComposition from 'components/BodyComposition/BodyComposition';
import AnaerobicThresholdChart from 'components/Charts/AnaerobicThresholdChart';
import ArterialThicknessChart from 'components/Charts/ArterialThicknessChart';
import BloodPressureChart from 'components/Charts/BloodPressureChart';
import FatMassChart from 'components/Charts/FatMassChart';
import HipRatioChart from 'components/Charts/HipRatioChart';
import MaxWattageChart from 'components/Charts/MaxWattageChart';
import MuscleMassChart from 'components/Charts/MuscleMassChart';
import PeakHeartRateChart from 'components/Charts/PeakHeartRateChart';
import RestingHeartRateChart from 'components/Charts/RestingHeartRateChart';
import TotalBodyFatChart from 'components/Charts/TotalBodyFatChart';
import UpperTorsoFatChart from 'components/Charts/UpperTorsoFatChart';
import VO2MaxChart from 'components/Charts/VO2MaxChart';
import WeightChart from 'components/Charts/WeightChart';
import HealthAndFitness from 'components/HealthFitness/HealthFitness';
import ProfileCard from 'components/ProfileCard/ProfileCard';
import HealthTrackingLoader from 'components/Skeleton/HealthTrackingLoader/HealthTrackingLoader';
import { biometrics as biometricsMapping } from 'utils/biometricsMappings';
import { HEALTH_MAIN_TITLE, HEALTH_SUB_TITLE } from 'utils/constants';

import {
	Section,
	Tabs,
	Tab,
	Span,
	Content,
	Row,
	RowHealthAndFitness,
	CardWrapper,
} from './HealthTracking.styled';

const HealthTracking = () => {
	const [isChartOpen, setIsChartOpen] = useState(false);

	const userState = useSelector((state) => state.user);
	const { chartId } = useSelector((state) => state.chartData);

	const dispatch = useDispatch();

	const { data: user } = useGetUser();

	const biometrics = useGetBiometrics();
	const biometricsData = biometrics.data;
	const { loading } = biometrics;

	const getUser = useCallback(async () => {
		let userRes = { ...userState };
		if (user) userRes = { ...user };

		dispatch.user.updateUser(userRes);
	}, [user]);

	useEffect(() => {
		if (user) {
			getUser();
		}

		if (biometricsData) {
			const payload = {};
			for (const biometric in biometricsData) {
				payload[biometricsMapping[biometric]] = biometricsData[biometric];
			}
			dispatch.biometrics.update(payload);
		}
	}, [getUser, biometricsData]);

	const openChart = () => setIsChartOpen(true);

	const closeChart = () => {
		setIsChartOpen(false);
		dispatch.chartData.update({ chartId: '', name: '' });
	};

	const chartProps = {
		closeChart: closeChart,
		isOpen: isChartOpen,
	};
	const chartMapping = {
		totalBodyFat: <TotalBodyFatChart {...chartProps} />,
		muscleMass: <MuscleMassChart {...chartProps} />,
		fatMass: <FatMassChart {...chartProps} />,
		upperTorsoFat: <UpperTorsoFatChart {...chartProps} />,
		vo2Max: <VO2MaxChart {...chartProps} />,
		peakHeartRate: <PeakHeartRateChart {...chartProps} />,
		anaerobicThreshold: <AnaerobicThresholdChart {...chartProps} />,
		maxWattage: <MaxWattageChart {...chartProps} />,
		restingHeartRate: <RestingHeartRateChart {...chartProps} />,
		bloodPressure: <BloodPressureChart {...chartProps} />,
		waistHipRatio: <HipRatioChart {...chartProps} />,
		arterialThickness: <ArterialThicknessChart {...chartProps} />,
		weight: <WeightChart {...chartProps} />,
	};

	return (
		<Section id='healthTracking'>
			{loading ? (
				<HealthTrackingLoader />
			) : (
				<>
					<Titles mainTitle={HEALTH_MAIN_TITLE} subTitle={HEALTH_SUB_TITLE} />
					<Tabs>
						<Tab>
							<Span>Biometrics</Span>
						</Tab>
					</Tabs>
					<Content>
						<Row>
							<CardWrapper>
								<ProfileCard openChart={openChart} />
							</CardWrapper>

							<BodyComposition openChart={openChart} />
						</Row>
						<RowHealthAndFitness>
							<HealthAndFitness openChart={openChart} />
						</RowHealthAndFitness>
					</Content>

					{chartMapping[chartId]}
				</>
			)}
		</Section>
	);
};

export default HealthTracking;
