import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Container = styled.form`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	flex-direction: column;
	width: 580px;
	margin: 0 auto 20vh;

	@media ${device.mobileL} {
		width: 90%;
	}

	@media ${device.mobileXL} and (${device.landscape}), ${device.mobileM} {
		margin-top: 50px;
		justify-content: flex-start;
	}
`;

export const FormGroup = styled.div`
	width: 100%;
	margin-top: 38px;
`;

export const Heading = styled.span`
	${fontStyles[fontSizes.thirty]}
	font-weight: 800;
	color: ${colors.navy};
	margin-top: 45px;
	text-align: center;
`;

export const Description = styled.span`
	${fontStyles[fontSizes.twentyFour]}
	color: ${colors.darkGrey};
	text-align: center;
	margin-top: 28px;
`;

export const SubmitWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	flex-shrink: 0;
	width: 100%;
	margin-top: 10px;

	> button {
		@media ${device.mobileM} {
			width: 100%;
		}
	}
`;

export const LockIcon = styled.div`
	width: 62px;
	height: 62px;
	border-radius: 44px;
	background: ${colors.gradient};
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;

	> i {
		color: ${colors.white};
	}
`;
