import React, { useState, useRef } from 'react';

import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import {
	Channel,
	withSendBird,
	useSendbirdStateContext,
	sendBirdSelectors,
} from 'sendbird-uikit';

import Button from 'common/Button/Button';
import Titles from 'common/Titles/Titles';
import Modal from 'components/Modal/Modal';
import useChannels from 'hooks/useChannels';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { device } from 'styles/device';
import { chatModalStyle } from 'utils';
import { MESSAGING_TITLE, MESSAGING_SUB_TITLE } from 'utils/constants';
import {
	getChannelName,
	handleCreateChannel,
	interlocutors,
} from 'utils/sendBirdHelpers';

import BackToChat from './BackToChat/BackToChat';
import ChannelsLists from './ChannelsList/ChannelsList';
import MembersList from './MembersList/MembersList';

import {
	Container,
	ChannelHeader,
	ChannelTitle,
	ChannelSubTitle,
	ChannelWrapper,
	Body,
	SelectedCounter,
	UsersList,
	Actions,
} from './Chat.styled';

import 'sendbird-uikit/dist/index.css';

const Chat = () => {
	const { channelUrl } = useSelector((state) => state.messaging);
	const { myCareTeam } = useSelector((state) => state.user);

	const [openModal, setOpenModal] = useState(false);
	const [currentChannelUrl, setCurrentChannelUrl] = useState(channelUrl || '');
	const [selectedMembers, setSelectedMembers] = useState([]);

	const modalRef = useRef();

	const context = useSendbirdStateContext();
	const sdk = sendBirdSelectors.getSdk(context);
	const [channels, refreshChannels] = useChannels();

	const isMobile = useMediaQuery({ query: device.mobileXL });
	const isChatActiveOnMobile = isMobile && currentChannelUrl;

	const handleChannelSelect = (url) => {
		url && setCurrentChannelUrl(url);
	};

	const renderChatHeader = ({ channel, user }) => {
		if (!channel) return;

		const filteredMembers = interlocutors(channel.members, user?.userId);
		const channelName = getChannelName(filteredMembers, 'nickname') || '';

		return (
			<ChannelHeader>
				<ChannelTitle title={channelName}>
					Messaging with {channelName}
				</ChannelTitle>
				<ChannelSubTitle title={channelName}>
					This is history of your conversation with {channelName}
				</ChannelSubTitle>
			</ChannelHeader>
		);
	};

	const createChannel = async () => {
		const createSbChannel = sendBirdSelectors.getCreateChannel(context);

		if (sdk && sdk.GroupChannelParams) {
			const userIds = selectedMembers.map((item) => item.id);

			const channelSbUrl = await handleCreateChannel(
				sdk,
				createSbChannel,
				null,
				userIds
			);

			setCurrentChannelUrl(channelSbUrl);
			closeModal();
		}
	};

	const handleChannelCreation = async () => {
		await createChannel();
		refreshChannels();
	};

	const handleOpenModal = () => {
		setOpenModal(true);
	};

	const closeModal = () => {
		setOpenModal(false);
		setSelectedMembers([]);
	};

	useOnClickOutside(modalRef, () => {
		if (closeModal) closeModal();
	});

	const handleSelectMember = (user, data) => {
		if (!user.id) return;

		const isAlreadySelected = data.find(({ id }) => id === user.id);

		if (isAlreadySelected) {
			const filtered = data.filter(({ id }) => id !== user.id);
			setSelectedMembers(filtered);
		} else {
			setSelectedMembers([...data, user]);
		}
	};

	return (
		<>
			{isChatActiveOnMobile ? (
				<BackToChat setCurrentChannelUrl={setCurrentChannelUrl} />
			) : (
				<Titles mainTitle={MESSAGING_TITLE} subTitle={MESSAGING_SUB_TITLE} />
			)}
			<Container>
				{isChatActiveOnMobile ? (
					''
				) : (
					<ChannelsLists
						currentChannelUrl={currentChannelUrl}
						handleOpenModal={handleOpenModal}
						handleChannelSelect={handleChannelSelect}
						myCareTeam={myCareTeam}
						channels={channels}
					/>
				)}

				{!isMobile || isChatActiveOnMobile ? (
					<ChannelWrapper>
						<Channel
							channelUrl={currentChannelUrl}
							renderChatHeader={(params) => renderChatHeader(params)}
						/>
					</ChannelWrapper>
				) : (
					''
				)}

				<Modal
					onRequestClose={closeModal}
					title='New Channel'
					isOpen={openModal}
					style={chatModalStyle}
				>
					<Body>
						<SelectedCounter isSelected={selectedMembers.length}>
							{selectedMembers.length} selected
						</SelectedCounter>

						<UsersList>
							<MembersList
								handleSelectMember={handleSelectMember}
								selectedMembers={selectedMembers}
								myCareTeam={myCareTeam}
							/>
						</UsersList>

						<Actions>
							<Button
								margin='0 -10px 0 0'
								onClick={closeModal}
								variant='cancel'
							>
								Cancel
							</Button>
							<Button
								disabled={!selectedMembers || selectedMembers.length === 0}
								onClick={handleChannelCreation}
								variant='primary'
							>
								Create
							</Button>
						</Actions>
					</Body>
				</Modal>
			</Container>
		</>
	);
};

export default withSendBird(Chat);
