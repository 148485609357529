import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 0 0 310px;
	min-height: 350px;
	margin-right: 14px;
	border-radius: 10px;
	background: ${colors.white};
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);

	@media ${device.tablet} {
		flex: 1;
	}
`;

export const BorderTop = styled.div`
	width: 80%;
	height: 5px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	background: ${({ color }) => (color ? `${color}` : `${colors.teal}`)};
`;

export const PictureContainer = styled.div`
	width: 148px;
	height: 148px;
	margin: 28px 0 21px 0;

	@media ${device.mobileL} {
		width: 175px;
		height: 175px;
	}

	#avatarBorder {
		width: 100%;
		height: 100%;
		border-radius: 100px;
		padding: 3px;
		background: ${({ bgColor, isPicture }) =>
			bgColor && isPicture ? `${bgColor}` : 'transparent'};

		@media ${device.mobileL} {
			width: 175px;
			height: 175px;
		}
	}
`;

export const TeamRole = styled.p`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	max-width: 70%;
	min-width: 124px;
	min-height: 32px;
	margin-bottom: 24px;
	padding: 8px;
	font-weight: 800;
	border-radius: 4px;
	${fontStyles[fontSizes.sixteen]};
	color: ${({ color }) => (color ? `${color}` : `${colors.teal}`)};
	background: ${({ bgColor }) =>
		bgColor ? `${bgColor}` : `${colors.green20}`};

	@media ${device.mobileL} {
		min-width: 149px;
		min-height: 40px;
		${fontStyles[fontSizes.eighteen]};
	}
`;

export const MemberName = styled.p`
	font-weight: 800;
	min-height: 24px;
	color: ${colors.navy};
	${fontStyles[fontSizes.eighteen]};

	@media ${device.mobileL} {
		${fontStyles[fontSizes.twentyTwo]};
	}
`;

export const LocationText = styled.p`
	font-weight: 500;
	min-height: 24px;
	margin-bottom: 25px;
	color: ${colors.darkGrey};
	${fontStyles[fontSizes.eighteen]};

	@media ${device.mobileL} {
		${fontStyles[fontSizes.twentyTwo]};
		margin-top: 7px;
	}
`;

export const Button = styled.button`
	width: 80%;
	min-height: 45px;
	border-radius: 4px;
	margin: auto 0 35px 0;
	color: ${colors.teal};
	background: transparent;
	font-weight: 800;
	cursor: pointer;
	border: 1px solid ${colors.teal};
	${fontStyles[fontSizes.eighteen]};

	@media ${device.mobileL} {
		${fontStyles[fontSizes.twenty]};
	}

	&:hover {
		color: ${colors.white};
		background: ${colors.teal};
	}
`;
