import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Container = styled.div`
	margin-top: 28px;
	text-align: center;
	height: 70vh;
	width: 100%;
	border-radius: 10px;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
	display: flex;
	width: 100%;

	@media ${device.mobileL} {
		margin-top: 14px;
	}

	.sendbird-conversation {
		border-radius: 10px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-right: none !important;
		border-top: none !important;
		border-bottom: none !important;
		border-left: 1px solid ${colors.green10} !important;

		@media ${device.mobileXL} {
			border-radius: 10px;
		}
	}

	.sendbird-conversation__messages {
		padding-top: 64px;
	}

	.sendbird-message-input--textarea {
		height: 56px !important;
		overflow: auto;
	}

	.sendbird-channel-preview--active {
		background: linear-gradient(
			136.85deg,
			rgba(0, 145, 148, 0.1) 16.87%,
			rgba(72, 71, 112, 0.1) 82.61%
		);
		border: none;
		border-left: 6px solid ${colors.teal} !important;
	}

	.sendbird-channel-preview {
		height: 105px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding: 16px 15px 13px 15px;
		border: 1px solid ${colors.grey30};
	}

	.sendbird-channel-preview .sendbird-channel-preview__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding-top: 16px;
	}

	// Hide buttons
	.sendbird-channel-preview .sendbird-channel-preview__action,
	.sendbird-chat-header__right,
	.sendbird-channel-header__right-icon {
		display: none !important;
	}

	.sendbird-user-message__text-balloon__inner__text-place {
		background: ${colors.grey30};
		border-radius: 10px;
	}

	.sendbird-user-message__text-balloon__inner__text-place__text {
		color: ${colors.navy} !important;
		font-size: 14px;
	}

	.sendbird-label--caption-3 sendbird-label--color-onbackground-2 {
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
		color: ${colors.darkGrey} !important;
	}

	.sendbird-channel-preview__content__upper__last-message-at {
		font-weight: 800;
		font-size: 12px;
		line-height: 16px;
		color: ${colors.darkGrey};
	}

	.sendbird-label--color-onbackground-3 {
		font-weight: 500;
		font-size: 16px;
		line-height: 18px;
		color: ${colors.navy};
	}

	.sendbird-label--body-2 sendbird-label--color-onbackground-3 {
		font-size: 14px;
		line-height: 19px;
		color: ${colors.darkGrey};
	}

	.sendbird-label--color-onbackground-2 {
		display: block !important;
	}

	.sendbird-tooltip-wrapper__hover-tooltip {
		.sendbird-tooltip > span {
			color: white !important;
		}
	}

	.sendbird-message--outgoing sendbird-message {
		button {
			display: none !important;
		}
	}

	.sendbird-label--color-oncontent-1,
	.sendbird-label--color-oncontent-2 {
		color: ${colors.navy} !important;
	}

	// overwrite hardcoded image width by sendbird
	.sendbird-outgoing-thumbnail-message-body__img,
	.sendbird-outgoing-thumbnail-message-body__wrap--inner
		.sendbird-image-renderer__image {
		@media ${device.tablet} {
			position: relative;
			width: 280px !important;
			height: 220px !important;
		}
		@media ${device.mobileL} {
			width: 200px !important;
			height: 170px !important;
		}
	}

	.sendbird-outgoing-thumbnail-message {
		text-align: end !important;
		padding-top: 64px !important;
		object-fit: cover;
	}

	.sendbird-admin-message .sendbird-admin-message__text {
		font-family: 'Avenir' !important;
	}
`;

export const Div = styled.div`
	height: 80%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

export const ChannelHeader = styled.div`
	position: absolute;
	width: 100%;
	background: ${colors.white};
	z-index: 15;
	top: 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	height: 64px;
	padding: 0 17px 0 14px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	border-top-right-radius: 10px;

	@media ${device.mobileXL} {
		border-top-left-radius: 10px;
	}
`;

export const ChannelTitle = styled.p`
	${fontStyles[fontSizes.fifteen]};
	font-weight: 800;
	color: ${colors.navy};
	overflow: hidden;
	text-align: start;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	-moz-line-clamp: 1;
	-moz-box-orient: vertical;
	-ms-line-clamp: 1;
	-ms-box-orient: vertical;
	-o-line-clamp: 1;
	-o-box-orient: vertical;
`;

export const ChannelSubTitle = styled(ChannelTitle)`
	${fontStyles[fontSizes.fourteen]};
	color: ${colors.darkGrey};
	font-weight: 400;
	margin-top: 3.5px;

	@media ${device.mobileXL} {
		display: none;
	}
`;

export const ChannelWrapper = styled.div`
	width: 100%;
	position: relative;
`;

export const Body = styled.div``;

export const SelectedCounter = styled.div`
	color: ${colors.darkGrey};
	font-weight: 500;
	text-align: start;
	margin-bottom: 8px;
`;

export const UsersList = styled.div``;

export const Actions = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 21px;

	@media ${device.mobileL} {
		flex-direction: column-reverse;
	}

	label {
		@media ${device.laptop} {
			${fontStyles[fontSizes.sixteen]};
		}
	}

	button {
		@media ${device.mobileL} {
			width: 100%;
			margin-right: 0;
		}
	}
`;

// Shared
export const Username = styled.p`
	color: ${colors.navy};
	font-weight: 800;
	${fontStyles[fontSizes.sixteen]};
	margin-left: 13px;
`;
