import React from 'react';

import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import Tooltip from 'common/Tooltip/Tooltip';
import { device } from 'styles/device';
import { getWholeNumber } from 'utils';

import {
	Container,
	Column,
	Row,
	SeeMore,
	Icon,
	Data,
	DataType,
	Wrapper,
	Buttons,
	Dot,
	Text,
} from './CompositionCard.styled';

const CompositionCard = ({
	chartId,
	name,
	value,
	type,
	icon,
	backgroundColor,
	mapKey,
	handleSeeMore,
	healthIndicator,
}) => {
	const biometrics = useSelector((state) => state.biometrics);
	const hIndicator = biometrics[healthIndicator];

	const isMobile = useMediaQuery({ query: device.tablet });
	const onClickHandler = () => handleSeeMore({ chartId, name });

	return (
		<Container
			background={backgroundColor}
			tabIndex='0'
			onClick={onClickHandler}
			name='openChart'
		>
			<Column>
				<Row>
					{isMobile && <SeeMore name='openChart'>See More</SeeMore>}

					<Icon name='icon'>
						<img src={icon} alt='Parameter' />
					</Icon>
				</Row>

				<Column>
					<Data name={chartId}>
						{getWholeNumber(biometrics[mapKey]) || value}
					</Data>
					<DataType name='dataType'>{type}</DataType>
				</Column>
			</Column>

			<Wrapper>
				<Buttons>
					{hIndicator && <Dot healthIndicator={hIndicator} />}
					<Tooltip mapKey={mapKey} />
				</Buttons>

				<Text title={name}>{name}</Text>
			</Wrapper>
		</Container>
	);
};

export default CompositionCard;
