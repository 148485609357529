import React, { useState, useRef, useEffect, useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { useGetUser, useChangePassword, useUpdateUser } from 'api/services';
import Button from 'common/Button/Button';
import Titles from 'common/Titles/Titles';
import {
	showSuccessNotify,
	showErrorNotify,
	checkPasswordStrength,
	checkPasswordMatch,
	checkPhoneValidity,
	removeStorageItem,
} from 'utils';
import {
	SUCCESS_UPDATED_USER,
	PASSWORD_SHORT_ERR,
	PASSWORD_NOT_MATCH_ERR,
	ERROR_UPDATING_USER,
	BOTTOM_LEFT,
	ACCOUNT_MAIN_TITLE,
	ACCOUNT_SUB_TITLE,
	INVALID_PHONE,
} from 'utils/constants';
import { policyConsentName, policyConsentTimestamp } from 'utils/policyConsent';

import AvatarContainer from './AvatarContainer/AvatarContainer';
import EditAccount from './EditAccount/EditAccount';

import {
	Section,
	Form,
	Divider,
	Wrapper,
	RevokeAccessContainer,
	RevokeAccessText,
	RevokeAccessButton,
} from './Settings.styled';

const Settings = () => {
	const [isValid, setIsValid] = useState(undefined);
	const [showPassChange, setShowPassChange] = useState(false);

	const userState = useSelector((state) => state.user);
	const { username, email, phone, password, confirmPassword } = userState;

	const { data: user } = useGetUser({ debounce: false });

	const { mutate: updateUserRequest, loading } = useUpdateUser();
	const { mutate: changePassword } = useChangePassword();

	const dispatch = useDispatch();
	const userDataRef = useRef();

	const getUser = useCallback(() => {
		const userData = {
			firstName: user?.first_name || '',
			lastName: user?.last_name || '',
			username: user?.username || '',
			email: user?.email || '',
			phone: user?.phone || '',
		};

		userDataRef.current = userData || userState;
		dispatch.user.updateUser(userData || userState);
	}, [user]);

	useEffect(() => {
		if (user) {
			getUser();
		}
	}, [getUser]);

	const onSubmit = async (evt) => {
		evt.preventDefault();

		try {
			const passwordBody = {
				password,
				confirm_password: confirmPassword,
			};
			const userBody = {
				username: username.trim(),
				email,
				phone,
			};

			if (!validateForm()) return;

			if (isPasswordChanged()) {
				await changePassword(passwordBody);
				resetState();
			}

			await updateUserRequest(userBody);

			setShowPassChange(false);
			showSuccessNotify(SUCCESS_UPDATED_USER, BOTTOM_LEFT);
			userDataRef.current = { ...userDataRef.current, ...userBody };
		} catch (err) {
			showErrorNotify(err?.data?.message || ERROR_UPDATING_USER, BOTTOM_LEFT);
		}
	};

	const isPasswordChanged = () => {
		if (showPassChange && (password === '' || confirmPassword === '')) {
			return true;
		}
		if (password || confirmPassword) {
			return true;
		}
		return false;
	};

	const validateForm = () => {
		let valid = true;

		if (isPasswordChanged()) {
			if (!checkPasswordStrength(password)) {
				valid = false;
				handleError(PASSWORD_SHORT_ERR);
			}

			if (!checkPasswordMatch(password, confirmPassword)) {
				valid = false;
				handleError(PASSWORD_NOT_MATCH_ERR);
			}
		}

		if (!checkPhoneValidity(phone)) {
			valid = false;
			handleError(INVALID_PHONE);
		}

		return valid;
	};

	const handleError = (msg) => {
		setIsValid(false);
		showErrorNotify(msg, BOTTOM_LEFT);
	};

	const resetState = () => {
		setIsValid(undefined);
		setShowPassChange(false);
		dispatch.user.updateUser({ password: '', confirmPassword: '' });
	};

	const getPreviousState = () => {
		const prevUserState = userDataRef.current;

		dispatch.user.updateUser(prevUserState);
		resetState();
	};

	const revokePolicyConsent = () => {
		removeStorageItem(policyConsentName);
		removeStorageItem(policyConsentTimestamp);
		dispatch.policyConsent.clearPolicyConsent();
		dispatch.user.logoutUser();
	};

	return (
		<Section id='accountSettings'>
			<Titles mainTitle={ACCOUNT_MAIN_TITLE} subTitle={ACCOUNT_SUB_TITLE} />

			<AvatarContainer />

			<Form onSubmit={onSubmit}>
				<EditAccount
					isValid={isValid}
					setIsValid={setIsValid}
					showPassChange={showPassChange}
					setShowPassChange={setShowPassChange}
				/>

				<RevokeAccessContainer>
					<RevokeAccessText>
						In order to revoke access to our Policy&apos;s please click on the
						button &quot;Revoke Access&quot;. Note: you will be logged out.
					</RevokeAccessText>
					<RevokeAccessButton onClick={revokePolicyConsent}>
						Revoke access
					</RevokeAccessButton>
				</RevokeAccessContainer>

				<Divider />

				<Wrapper>
					<Button
						variant='cancel'
						margin='0 -10px 0 0'
						onClick={getPreviousState}
					>
						Cancel
					</Button>
					<Button type='submit' variant='primary' disabled={loading}>
						Save
					</Button>
				</Wrapper>
			</Form>
		</Section>
	);
};

export default Settings;
