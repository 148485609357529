import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';
import { fontSizes, fontStyles } from 'styles/fonts';

export const PageOverlay = styled.div`
	position: fixed;
	height: 100%;
	width: 100%;
	background-color: ${colors.navy};
	opacity: 0.5;
	z-index: 1;
`;

export const Icon = styled.img``;

export const HeaderLogo = styled.div`
	display: flex;
	width: 200px;
	height: 50px;
`;

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	min-height: 86px;
	width: 100%;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
	flex-direction: column;
	padding: 0 32px;
	background-color: ${colors.white};
	z-index: 97;

	@media ${device.tablet} {
		justify-content: space-between;
	}
`;

export const Messaging = styled.button`
	display: flex;
	flex-direction: column;
	margin-right: 20px;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	position: relative;
	border: none;
	background: transparent;

	&:disabled {
		color: ${colors.grey20};
		opacity: 0.5;
		cursor: not-allowed;
	}
`;

export const Burger = styled.div`
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const CloseMenu = styled.button`
	border: 0;
	background: transparent;
	outline: none;
	cursor: pointer;
	display: flex;
	flex-direction: column;

	i {
		font-size: 24px;
	}
`;

export const Menu = styled.div`
	display: flex;
	align-items: center;
`;

export const SmallText = styled.span`
	${fontStyles[fontSizes.ten]};
`;

export const NotifyPointer = styled.div`
	width: 12px;
	height: 12px;
	right: 5px;
	top: -3px;
	position: absolute;
	border-radius: 33px;
	background: ${colors.red80};
	border: 1px solid ${colors.white};
`;

export const DropdownMenu = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 86px;
	background-color: ${colors.white};
	padding: 0 32px;
	z-index: 97;

	@media ${device.mobileXL} and (${device.landscape}) {
		height: calc(100vh - 86px);
		overflow-y: auto;
		display: inline-block;
		// iOS Safari
		-webkit-overflow-scrolling: touch;
	}
`;

export const HeaderTop = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
	height: 100%;
	align-items: center;
	min-height: 86px;

	@media ${device.laptop} {
		justify-content: space-between;
	}
`;

export const DropdownRoutes = styled.div`
	margin: 20px 0 32px 0;
`;

export const Divider = styled.div`
	height: 1px;
	background-color: ${colors.lightGrey};
	margin-top: 20px;

	@media ${device.mobileXL} and (${device.landscape}) {
		min-height: 1px;
	}
`;

export const UserSection = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const UserDropdown = styled.div`
	margin-left: 58px;
`;

export const UserAction = styled.div`
	margin-top: 20px;
	color: ${colors.darkGrey};
	${fontStyles[fontSizes.eighteen]};
	cursor: pointer;
`;

export const HeaderDivider = styled.div`
	width: 1px;
	height: 40px;
	background-color: ${colors.lightGrey};
`;
