import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';
import { fontSizes, fontStyles } from 'styles/fonts';

export const BackButtonContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-top: 14px;

	@media ${device.mobileL} {
		margin-top: 0;
	}
`;

export const BackButton = styled.button`
	cursor: pointer;
	color: ${colors.navy};
	font-weight: 800;
	${fontStyles[fontSizes.twentyTwo]};
	border: none;
	outline: none;
	background: transparent;

	@media ${device.tablet} {
		${fontStyles[fontSizes.twenty]};
	}

	img {
		height: 14px;
	}

	span {
		margin-left: 8px;
	}
`;
