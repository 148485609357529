import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Label = styled.label`
	display: block;
	margin-bottom: 8px;
	color: ${(props) =>
		props.isValid === false ? props.errorColor : colors.darkGrey};
	text-transform: capitalize;
	${fontStyles[fontSizes.sixteen]};
`;

export const InputField = styled.input`
	height: 40px;
	width: 100%;
	padding: 7px;
	margin-bottom: 7px;
	font-size: 14px;
	border-radius: 4px;
	border: 1px solid;
	color: ${colors.navy};
	background: ${colors.white};
	border-color: ${(props) =>
		props.isValid === false ? props.errorColor : colors.grey};

	&:focus {
		outline: ${colors.navy};
		border-color: ${colors.navy};
	}

	&:disabled {
		background: ${colors.grey30};
		border: 1px solid ${colors.grey20};
		color: ${colors.darkGrey};
	}

	&::placeholder {
		color: ${colors.grey40};
	}
`;
