import React, { useState, useEffect } from 'react';

import { Bar } from 'react-chartjs-2';

import { useGetBloodPressure } from 'api/services';
import ChartModal from 'components/ChartModal/ChartModal';

import {
	handleBarChartData,
	adaptBloodPressureTable,
} from './utils/chartHelpers';
import { defaultBarChartConfig } from './utils/customViewChart';

const BloodPressureChart = ({ closeChart, isOpen }) => {
	const [data, setData] = useState({});
	const [tableData, setTableData] = useState(null);
	const [labelsData, setLabelsData] = useState(undefined);

	const { data: bloodPressureData, loading } = useGetBloodPressure();
	const isDataExist = bloodPressureData?.data && bloodPressureData?.data.length;

	useEffect(() => {
		if (isDataExist) {
			const dataAdapted = handleBarChartData(bloodPressureData?.data);
			const lastResult = {
				value:
					bloodPressureData.data[bloodPressureData.data.length - 1]?.systolic,
			};
			const tableDataAdapted = adaptBloodPressureTable(
				bloodPressureData?.referential_values,
				lastResult
			);

			setData(dataAdapted);
			setTableData(tableDataAdapted);
			setLabelsData(dataAdapted?.labels);
		}
	}, [bloodPressureData]);

	return (
		<ChartModal
			data={data}
			loading={loading}
			tableData={tableData}
			labelsData={labelsData}
			closeChart={closeChart}
			isOpen={isOpen}
		>
			<Bar data={data} options={defaultBarChartConfig} />
		</ChartModal>
	);
};

export default BloodPressureChart;
