import React from 'react';

import logo from 'assets/svg/illustrations/logo.svg';
import Image from 'components/Image/Image';

const Logo = ({ ...rest }) => (
	<Image img={logo} alt='Cenegenics Logo' {...rest} />
);

export default Logo;
