import React from 'react';

import { withRouter } from 'react-router-dom';

import LoginForm from 'components/LoginForm/LoginForm';
import AuthLayout from 'layouts/AuthLayout/AuthLayout';

const Login = ({ location }) => (
	<AuthLayout title='Log in TEST'>
		<LoginForm location={location} />
	</AuthLayout>
);

export default withRouter(Login);
