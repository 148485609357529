import React, { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useUploadImage } from 'api/services';
import Button from 'common/Button/Button';
import FileUploadBtn from 'common/FileUploadBtn/FileUploadBtn';
import Avatar from 'components/Avatar/Avatar';
import {
	resizeFile,
	showErrorNotify,
	showSuccessNotify,
	toFeetAndInch,
} from 'utils';
import {
	IMAGE_UPLOAD_SUCCESS,
	IMAGE_UPLOAD_FAILED,
	BOTTOM_LEFT,
} from 'utils/constants';
import { weightGraphData } from 'utils/lists';

import {
	Container,
	Column,
	Text,
	Row,
	Param,
	Name,
	Value,
} from './ProfileCard.styled';

const ProfileCard = ({ openChart }) => {
	const { firstName, lastName, picture, age } = useSelector(
		(state) => state.user
	);
	const { weight = 0, height = 0 } = useSelector((state) => state.biometrics);

	const { mutate: uploadImg, loading } = useUploadImage();
	const dispatch = useDispatch();

	const fullName = `${firstName} ${lastName}`;

	const handleUploadFile = async (file) => {
		if (!file) return;

		try {
			const image = await resizeFile(file);

			await uploadImg(image);
			dispatch.user.uploadPicture(image);

			showSuccessNotify(IMAGE_UPLOAD_SUCCESS, BOTTOM_LEFT);
		} catch (err) {
			console.error(err);
			showErrorNotify(IMAGE_UPLOAD_FAILED, BOTTOM_LEFT);
		}
	};

	const handleOpenChart = useCallback(() => {
		openChart();
		dispatch.chartData.update({
			chartId: weightGraphData.chartId,
			name: weightGraphData.name,
		});
	}, [openChart]);

	return (
		<Container>
			<Column>
				<Avatar picture={picture} loading={loading} />
				{!loading && (
					<FileUploadBtn
						name='uploadBtn'
						useIconBtn
						handleFile={handleUploadFile}
						allowExtensions='.png,.jpeg,.webp'
					/>
				)}

				<Text name='username'>{fullName}</Text>
			</Column>

			<Row>
				<Param>
					<Value name='weight'>{weight} lbs</Value>
					<Name>Weight</Name>
					<Button variant='primary' small rounded onClick={handleOpenChart}>
						See more
					</Button>
				</Param>
				<Param>
					<Value name='height'>{toFeetAndInch(height)}</Value>
					<Name>Height</Name>
				</Param>
				<Param>
					<Value name='age'>{age}</Value>
					<Name>Age</Name>
				</Param>
			</Row>
		</Container>
	);
};

export default ProfileCard;
