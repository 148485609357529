import { colors } from 'styles/colors';
import { formatDate } from 'utils';

// PLUGINS
export const gradientBgPlugin = [
	{
		id: 'backgrounds',
		beforeDraw: (chart, args, options) => {
			const {
				ctx,
				chartArea,
				scales: { y },
			} = chart;

			options.hbars.forEach((hBar) => {
				ctx.save();
				ctx.fillStyle = hBar.color;
				ctx.fillRect(
					chartArea.left,
					y.getPixelForValue(hBar.from),
					chartArea.right - chartArea.left,
					y.getPixelForValue(hBar.to) - y.getPixelForValue(hBar.from)
				);
				ctx.restore();
			});
		},
	},
];

const setChartPointerInCenter = (chartData) => {
	const { date, value } = chartData[0];

	return {
		labels: ['', formatDate(date), ''],
		datasets: [
			{
				data: [
					{
						y: null,
						x: null,
					},
					{
						y: value,
						x: formatDate(date),
					},
					{
						y: null,
						x: null,
					},
				],
			},
		],
	};
};

/**
 * @description Make an array of colors for dots on Charts
 * @param {Number} length
 * @param {String} color
 * @returns {String[]} array of strings containing colors
 */
export const chartDotsArrayColors = (length, color) => {
	return Array(length).fill(color);
};

export const handleChartData = (data) => {
	const labels = [];

	if (data.length === 1) {
		return setChartPointerInCenter(data);
	}

	const adaptData = data.map(({ value, date }) => {
		labels.push(formatDate(date));

		return {
			y: value,
			x: formatDate(date),
		};
	});

	return {
		labels,
		datasets: [{ data: adaptData }],
	};
};

export const handleBarChartData = (data) => {
	const labels = [];
	const barLengthColor = data.length - 1;

	const adaptData = data.map(({ date, diastolic, systolic }) => {
		labels.push(formatDate(date));

		return [systolic, diastolic];
	});

	return {
		labels,
		datasets: [
			{
				data: adaptData,
				radius: 3,
				hoverRadius: 3,
				barThickness: 10,
				borderWidth: 3,
				hoverBorderWidth: 4,
				borderSkipped: false,
				borderRadius: 33,
				backgroundColor: [
					colors.white,
					...chartDotsArrayColors(barLengthColor, colors.green),
				],
				borderColor: [
					colors.green,
					...chartDotsArrayColors(barLengthColor, colors.green),
				],
				hoverBackgroundColor: colors.white,
				hoverBorderColor: [
					colors.green,
					...chartDotsArrayColors(barLengthColor, colors.green),
				],
			},
		],
	};
};

const numInBetweenInclusive = (x, min, max) => x >= min && x <= max;
const numInBetweenExclusive = (x, min, max) => x >= min && x < max;

const getSelectedRefValue = (lastResult = {}, currentValue = '') => {
	let data = [];
	let isSelected = false;

	if (currentValue.includes('<=')) {
		data = currentValue.split('<=');
		return numInBetweenInclusive(lastResult.value, 0, parseFloat(data[1]));
	}

	if (currentValue.includes('>=')) {
		data = currentValue.split('>=');
		return lastResult.value >= parseFloat(data[1]);
	}

	if (currentValue.includes('<')) {
		data = currentValue.split('<');
		return numInBetweenExclusive(lastResult.value, 0, parseFloat(data[1]));
	}

	if (currentValue.includes('>')) {
		data = currentValue.split('>');
		return lastResult.value >= parseFloat(data[1]);
	}

	if (currentValue.includes('-')) {
		data = currentValue.split('-');
		return numInBetweenInclusive(
			lastResult.value,
			parseFloat(data[0]),
			parseFloat(data[1])
		);
	}

	return isSelected;
};

export const adaptFatBodyTable = ({ athlete, general }, lastResult, gender) => {
	if (!athlete || !general) return;

	let firstList = [];
	let secondList = [];
	let thirdList = [];

	for (let i = 0; i < general.length; i++) {
		const selected = getSelectedRefValue(lastResult, general[i].value);

		firstList[i] = { value: general[i].value || 'n/a', selected };
		secondList[i] = {
			value: athlete[i].value || 'n/a',
			selected,
		};
		thirdList[i] = {
			value: general[i].classification || 'n/a',
			selected,
		};
	}

	return {
		firstList: {
			title:
				gender === 'Male'
					? 'Male-General population'
					: 'Female-General population',
			data: firstList,
		},
		secondList: {
			title: gender === 'Male' ? 'Male-Athletes' : 'Female-Athletes',
			data: secondList,
		},
		thirdList: {
			title: 'Classification',
			data: thirdList,
		},
	};
};

export const adaptVo2MaxTable = (
	refValues,
	lastResult,
	age_group = '60+++'
) => {
	if (!refValues) return;

	let firstList = [{ value: 'Score' }];
	let secondList = [];
	let thirdList = [];

	for (let i = 0; i < refValues.length; i++) {
		const { value, rating } = refValues[i];
		const selected = getSelectedRefValue(lastResult, value);

		secondList[i] = {
			value: value || 'n/a',
			selected,
		};
		thirdList[i] = {
			value: rating || 'n/a',
			selected,
		};
	}

	return {
		firstList: {
			title: 'Age',
			data: firstList,
		},
		secondList: {
			title: age_group,
			data: secondList,
		},
		thirdList: {
			title: 'Rating',
			data: thirdList,
		},
	};
};

export const adaptTwoColumnsTableData = (
	refValues,
	lastResult,
	firstTitle,
	secondTitle
) => {
	if (!refValues) return;

	let firstList = [];
	let secondList = [];

	for (let i = 0; i < refValues.length; i++) {
		const { score, rating } = refValues[i];
		const selected = getSelectedRefValue(lastResult, score);

		firstList[i] = {
			value: score || 'n/a',
			selected,
		};
		secondList[i] = {
			value: rating || 'n/a',
			selected,
		};
	}

	return {
		firstList: {
			title: firstTitle,
			data: firstList,
		},
		secondList: {
			title: secondTitle,
			data: secondList,
		},
	};
};

export const adaptBloodPressureTable = (refValues, lastResult) => {
	if (!refValues) return;

	let firstList = [];
	let secondList = [];
	let thirdList = [];

	for (let i = 0; i < refValues.length; i++) {
		const { systolic, diastolic, rating } = refValues[i];
		const selected = getSelectedRefValue(lastResult, systolic);

		firstList[i] = {
			value: systolic || 'n/a',
			selected,
		};
		secondList[i] = {
			value: diastolic || 'n/a',
			selected,
		};
		thirdList[i] = {
			value: rating || 'n/a',
			selected,
		};
	}

	return {
		firstList: {
			title: 'Systolic',
			data: firstList,
		},
		secondList: {
			title: 'Diastolic',
			data: secondList,
		},
		thirdList: {
			title: 'Risk',
			data: thirdList,
		},
	};
};

export const adaptHipRatioTable = (refValues, lastResult) => {
	if (!refValues) return;

	let firstList = [];
	let secondList = [];

	for (let i = 0; i < refValues.length; i++) {
		const { value, risk } = refValues[i];
		const selected = getSelectedRefValue(lastResult, value);

		firstList[i] = {
			value: value || 'n/a',
			selected,
		};
		secondList[i] = {
			value: risk || 'n/a',
			selected,
		};
	}

	return {
		firstList: {
			title: 'Score',
			data: firstList,
		},
		secondList: {
			title: 'Risk',
			data: secondList,
		},
	};
};

export const renameObjectsProp = (arrayOfObjects, propName1, propName2) =>
	arrayOfObjects.map(({ [propName1]: value, [propName2]: classification }) => ({
		value,
		classification,
	}));
