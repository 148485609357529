import React from 'react';

import { useDispatch } from 'react-redux';

import Button from 'common/Button/Button';
import useLocalStorage from 'hooks/useLocalStorage';
import {
	policyConsentName,
	policyConsentTimestamp,
	policyNameAccepted,
} from 'utils/policyConsent';

import {
	Container,
	TextContainer,
	Heading,
	Text,
	Link,
	ButtonContainer,
} from './PolicyConsent.styled';

const PolicyConsent = () => {
	const dispatch = useDispatch();

	const [policyName, setPolicyName] = useLocalStorage(
		policyConsentName,
		policyNameAccepted
	);
	const [policyTimestamp, setPolicyTimestamp] = useLocalStorage(
		policyConsentTimestamp
	);

	const setCookieAccept = () => {
		setPolicyTimestamp(Date.now());
		setPolicyName(policyNameAccepted);
		dispatch.policyConsent.updatePolicyConsent(policyName);
		dispatch.policyConsent.updatePolicyTimestamp(policyTimestamp);
	};

	return (
		<Container>
			<TextContainer>
				<Heading>We use cookies to enhance the user experience.</Heading>
				<Text>
					BestLife Holdings, Inc., doing business as Cenegenics (“Cenegenics”)
					only use essential and functional cookies, which are necessary to
					display our Patient Portal (“Portal”) platform correctly.
					Additionally, we use anonymized analytics cookies to review our
					traffic and to allow the best experience possible whenever you visit.
					We also share the anonymized information about your use of our Portal
					with analytics partners.
					<br />
					For more information, please visit our&nbsp;
					<Link
						href='https://cenegenics.com/privacy-policy/'
						target='_blank'
						rel='noreferrer'
					>
						Privacy & Terms of Use
					</Link>
					.
				</Text>
			</TextContainer>

			<ButtonContainer>
				<Button variant='secondary' rounded onClick={setCookieAccept}>
					I Understand
				</Button>
			</ButtonContainer>
		</Container>
	);
};

export default PolicyConsent;
