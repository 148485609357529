// Primary text
export const navy = '#050e2c';
export const navy60 = 'rgba(5, 14, 44, 0.6)';

// Secondary text
export const darkGrey = '#747575';

// Tertiary colors
export const grey = '#ccc';
export const lightGrey = '#e0e0e0';

// General
export const white = '#fff';
export const black = '#000';

// Page Background
export const offWhite = '#F8F9F9';

// Accents
export const teal = '#009194';
export const purple = '#484770';
export const gold = '#D1B985';
export const pink = '#825278';
export const softBlue = '#d9e5e9';

// Gradients
export const gradient = `linear-gradient(
  136.85deg,
  #009194 16.87%,
  #484770 82.61%
);`;

export const gradientText = `linear-gradient(
	136.85deg,
	${white} 16.87%,
	${white} 82.61%
)`;

export const gradientGreen = `linear-gradient(136.85deg, #009194 16.87%, #484770 82.61%);`;
export const gradientGreen3 = `linear-gradient(
	136.85deg,
	rgba(0, 145, 148, 0.1) 16.87%,
	rgba(72, 71, 112, 0.1) 82.61%
)`;
export const gradientButtonBackgroundImage = `linear-gradient(rgba(0, 145, 148, 1), rgba(72, 71, 112, 1)), linear-gradient(136.85deg, #009194 16.87%, #484770 82.61%)`;

// Other
export const red10 = 'rgba(251, 221, 221, 1)';
export const red80 = '#EB5757';
export const orange10 = 'rgba(252, 235, 219, 1)';
export const yellow10 = '#FCF4DB';
export const blue10 = 'rgba(45, 156, 219, 0.2)';
export const green = '#009194';
export const green10 = 'rgba(111, 207, 151, 0.2)';
export const green20 = 'rgba(0, 145, 148, 0.2)';
export const gold10 = 'rgba(209, 185, 133, 0.25)';

export const green70 = '#6FCF97';
export const green80 = '#27AE60';
export const yellow70 = '#F2C94C';
export const grey5 = 'rgba(72, 71, 112, 0.2)';
export const grey10 = '#F0F2F4';
export const grey20 = '#cccccc';
export const grey30 = '#F2F2F2';
export const grey40 = '#bdbdbd';

export const black20 = 'rgba(0, 0, 0, 0.2)';

export const colors = {
	black,
	black20,
	darkGrey,
	gold,
	gold10,
	softBlue,
	gradient,
	gradientText,
	gradientGreen,
	gradientGreen3,
	gradientButtonBackgroundImage,
	green,
	green10,
	green20,
	green70,
	green80,
	grey,
	grey5,
	grey10,
	grey20,
	grey30,
	grey40,
	lightGrey,
	navy,
	navy60,
	offWhite,
	pink,
	purple,
	red80,
	red10,
	orange10,
	teal,
	yellow10,
	yellow70,
	white,
	blue10,
};
