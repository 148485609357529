import React, { useState } from 'react';

import eyeHide from 'assets/svg/icons/eye-hide.svg';
import eyeShow from 'assets/svg/icons/eye-open.svg';

import { Label, InputWrapper, Input, Button, Icon } from './Password.styled';

const Password = ({
	name = 'password',
	value,
	isValid,
	errorColor,
	handleChange,
	labelText = 'Password',
}) => {
	const [showPassword, setShowPassword] = useState(false);

	const toggleShowPassword = () => setShowPassword(!showPassword);

	return (
		<>
			<Label htmlFor={name} isValid={isValid} errorColor={errorColor}>
				{labelText}
			</Label>
			<InputWrapper>
				<Input
					id={name}
					name={name}
					value={value}
					isValid={isValid}
					onChange={handleChange}
					type={showPassword ? 'text' : 'password'}
					errorColor={errorColor}
				/>
				<Button type='button' tabIndex='0' onClick={toggleShowPassword}>
					<Icon
						src={showPassword ? eyeHide : eyeShow}
						alt={showPassword ? 'Hide' : 'Show'}
					/>
				</Button>
			</InputWrapper>
		</>
	);
};

export default Password;
