import React, { useRef } from 'react';

import cameraIcon from 'assets/svg/icons/camera.svg';

import Button from '../Button/Button';

import { Icon } from './FileUploadBtn.styled';

const FileUploadBtn = ({
	handleFile,
	allowExtensions,
	useIconBtn,
	disabled = false,
	loading,
}) => {
	const hiddenFileInput = useRef(null);

	const handleClick = () => {
		hiddenFileInput.current.click();
	};

	const handleChange = (event) => {
		const fileUploaded = event.target.files[0];

		handleFile && handleFile(fileUploaded);
		event.target.value = null;
	};

	return (
		<>
			{useIconBtn ? (
				<Icon onClick={handleClick} id='uploadIcon'>
					<img src={cameraIcon} alt='Camera' />
				</Icon>
			) : (
				<Button
					disabled={loading}
					margin='0 0 0 28px'
					onClick={handleClick}
					rounded
					variant='primary'
				>
					Upload
				</Button>
			)}

			<input
				type='file'
				ref={hiddenFileInput}
				onChange={handleChange}
				style={{ display: 'none' }}
				accept={allowExtensions}
				data-testid='fileInput'
				disabled={disabled}
			/>
		</>
	);
};

export default FileUploadBtn;
