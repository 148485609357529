import React, { useRef } from 'react';

import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import chartIcon from 'assets/svg/icons/chart.svg';
import EmptyStateCard from 'common/EmptyStateCard/EmptyStateCard';
import Modal from 'components/Modal/Modal';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { isEmptyArray, modalStyle } from 'utils';
import { CHART_EMPTY_TEXT, CHART_EMPTY_SUB_TEXT } from 'utils/emptyStateMsg';

import ChartLegend from './ChartLegend/ChartLegend';
import ReferentialTable from './ReferentialTable/ReferentialTable';

import {
	MainColumn,
	ChartWrapper,
	EmptyState,
	ChartContainer,
	ChartTitle,
} from './ChartModal.styled';

const ChartModal = (props) => {
	const {
		children,
		data,
		tableData,
		chartConfig,
		chartPlugins,
		closeChart,
		loading,
		labelsData,
		isOpen,
	} = props;

	const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
	const { name } = useSelector((state) => state.chartData);
	const modalRef = useRef();

	const isDataExist = !loading && labelsData && !isEmptyArray(labelsData);
	const isEmptyState =
		(!loading && !labelsData) || (!loading && isEmptyArray(labelsData));

	useOnClickOutside(modalRef, () => {
		if (closeChart) closeChart();
	});

	return (
		<Modal
			isPortrait={isPortrait}
			loading={loading}
			onRequestClose={closeChart}
			isOpen={isOpen}
			style={modalStyle}
			title={name}
			isEmpty={isEmptyState}
		>
			{isDataExist && (
				<MainColumn>
					{tableData && <ReferentialTable data={tableData} />}

					<ChartWrapper>
						<ChartTitle>Your Trend</ChartTitle>
						<ChartContainer>
							{children || (
								<Line
									data={data}
									options={chartConfig}
									plugins={chartPlugins}
								/>
							)}
						</ChartContainer>
					</ChartWrapper>

					<ChartLegend tableData={tableData} labelsData={labelsData} />
				</MainColumn>
			)}

			{isEmptyState && (
				<EmptyState>
					<EmptyStateCard
						text={CHART_EMPTY_TEXT}
						subText={CHART_EMPTY_SUB_TEXT}
						image={chartIcon}
					/>
				</EmptyState>
			)}
		</Modal>
	);
};

export default ChartModal;
