import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';
import { fontSizes, fontStyles } from 'styles/fonts';

export const SubTitle = styled.p`
	${fontStyles[fontSizes.sixteen]};
	margin-top: 5px;
	margin-bottom: 32px;
	color: ${colors.darkGrey};
`;

export const SelectWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	flex-shrink: 0;
	@media ${device.laptop} {
		justify-content: space-between;
	}
`;

export const MessageOptions = styled.div`
	display: flex;
	width: 100%;
	button {
		margin: 0;
	}
	div:first-child {
		margin-right: 10px;
	}
	@media ${device.laptop} {
		button {
			width: calc(50% - 10px);
		}
	}
`;

export const HealthOptions = styled.div`
	margin-left: ${({ buttonWidth }) => `${buttonWidth + 5}px`};
	width: ${({ buttonWidth }) => `calc(100% - ${buttonWidth + 5}px)`};

	@media ${device.laptop} {
		margin-left: 0;
		width: 100%;
		button {
			width: 100%;
			margin: 20px 0 0;
		}
	}
`;

export const RefDiv = styled.div`
	@media ${device.laptop} {
		width: calc(50% - 10px);
		button {
			width: 100%;
		}
	}
`;

export const HorizontalLine = styled.div`
	height: 1px;
	width: 100%;
	margin: 15px 0;
	display: none;
	background: ${colors.grey20};
	@media ${device.laptop} {
		display: block;
	}
`;
