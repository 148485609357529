import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Wrapper = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	${fontStyles[fontSizes.fourteen]};
	font-weight: 800;
	cursor: pointer;
	outline: none;
	margin: 10px 10px 0 0;
	border-radius: 39px;
	border: ${({ selected }) =>
		selected ? `1px solid ${colors.teal};` : `1px solid ${colors.navy};`};

	background: ${({ selected }) =>
		selected
			? 'linear-gradient(136.85deg, rgba(0, 145, 148, 0.2) 16.87%, rgba(72, 71, 112, 0.2) 82.61%);'
			: 'transparent'};
	padding: 8px 25px;

	&:hover {
		border: 1px solid ${colors.teal};

		Span {
			color: ${colors.teal};
		}
	}

	@media ${device.laptop} {
		flex-grow: 1;
	}
`;

export const Span = styled.span`
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: ${({ selected }) =>
		selected ? 'transparent' : 'unset'};
	background-image: ${({ selected }) =>
		selected
			? 'linear-gradient(136.85deg, #009194 16.87%, #484770 82.61%);'
			: 'unset'};
`;
