import React from 'react';

import Card from '../../Card/Card';

import { Container, Title, Row } from './HealthFitnessCards.styled';

const HealthFitnessCards = ({
	title,
	firstRowData,
	secondRowData,
	data,
	handleClick,
}) => (
	<Container>
		<Title>{title}</Title>
		<Row>
			{firstRowData.map((item) => (
				<Card
					{...item}
					key={item.name}
					data={data}
					onClick={() =>
						handleClick({ chartId: item.chartId, name: item.name })
					}
				/>
			))}
		</Row>

		<Row>
			{secondRowData.map((item) => (
				<Card
					{...item}
					key={item.name}
					data={data}
					onClick={() =>
						handleClick({ chartId: item.chartId, name: item.name })
					}
				/>
			))}
		</Row>
	</Container>
);

export default HealthFitnessCards;
