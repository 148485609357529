import React, { useRef } from 'react';

import { useOnClickOutside } from 'hooks/useOnClickOutside';

import { Container, CaretUp, CaretDown } from './Caret.styled';

const Caret = ({ onClick, dropdownOpen, onClickOutside, children }) => {
	const ref = useRef();

	useOnClickOutside(ref, () => {
		if (onClickOutside) onClickOutside(false);
	});

	return (
		<Container onClick={onClick} ref={ref} name='caret'>
			{dropdownOpen ? <CaretUp /> : <CaretDown />}
			{children}
		</Container>
	);
};

export default Caret;
