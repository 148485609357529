import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';

export const Container = styled.div`
	align-items: center;
	display: flex;
	flex-flow: column wrap;
	flex: 1 1 auto;
	font-family: 'Avenir';
	height: 100vh;
	justify-content: center;

	@media ${device.mobileL} {
		flex-flow: row wrap;
	}

	& > img {
		margin-top: 20px;
	}
`;

export const Text = styled.p`
	color: ${colors.navy};
	font-size: 24px;
	font-weight: 600;
	line-height: 33px;
	margin-bottom: 40px;
	margin-top: 65px;
	text-align: center;
`;

export const Email = styled.a.attrs({
	href: 'mailto:info@cenegenics.com',
})`
	color: ${colors.darkGrey};
	font-size: 20px;
	font-weight: 600;
	line-height: 27px;
	text-align: center;

	&:hover {
		color: ${colors.green};
	}

	@media ${device.mobileL} {
		margin-bottom: 20px;
	}
`;
