import React from 'react';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { RestfulProvider } from 'restful-react';

import { ErrorFallback } from 'components/ErrorFallback/ErrorFallback';
import Router from 'router';
import store from 'store';
import { getToken } from 'utils';
import history from 'utils/history';

import GlobalStyle from './styles/globalStyle';

import 'react-toastify/dist/ReactToastify.min.css';

toast.configure();

const baseUrl = `${process.env.REACT_APP_BASE_URL}/api/1.0`;

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	integrations: [
		new Integrations.BrowserTracing({
			routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
		}),
	],
	tracesSampleRate: 1.0,
});

const AppWrapper = () => {
	const dispatch = useDispatch();

	return (
		<RestfulProvider
			base={baseUrl}
			requestOptions={() => ({
				headers: { Authorization: getToken() },
			})}
			onResponse={(response) => {
				if (response.status === 401) {
					dispatch.user.logoutUser();
				}
			}}
		>
			<GlobalStyle />

			<Router history={history} />
		</RestfulProvider>
	);
};

const App = () => {
	return (
		<Provider store={store}>
			{/* TODO: This isn't ideal solution, please move this ErrorBoundary
			into more local components, and not on the global level
		*/}
			<ErrorBoundary FallbackComponent={ErrorFallback}>
				<AppWrapper />
			</ErrorBoundary>
		</Provider>
	);
};

export default App;
