export const size = {
	mobileS: '375px',
	mobileM: '480px',
	mobileL: '768px',
	mobileXL: '992px',
	tablet: '1024px',
	laptop: '1280px',
	laptopL: '1440px',
	desktop: '1800px',
};

export const device = {
	mobileS: `(max-width: ${size.mobileS})`,
	mobileM: `(max-width: ${size.mobileM})`,
	mobileL: `(max-width: ${size.mobileL})`,
	mobileXL: `(max-width: ${size.mobileXL})`,
	tablet: `(max-width: ${size.tablet})`,
	laptop: `(max-width: ${size.laptop})`,
	laptopL: `(max-width: ${size.laptopL})`,
	desktop: `(max-width: ${size.desktop})`,
	portrait: 'orientation: portrait',
	landscape: 'orientation: landscape',
};
