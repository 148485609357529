import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import Input from 'common/Input/Input';
import { formatPhoneNumber } from 'utils';

import {
	Wrapper,
	Column,
	Container,
	Title,
	FormGroup,
} from './ProfileEdit.styled';

const ProfileEdit = () => {
	const dispatch = useDispatch();

	let { firstName, lastName, phone, username, email } = useSelector(
		(state) => state.user
	);
	const [inputPhone, setInputPhone] = useState(phone);

	useEffect(() => {
		setInputPhone(formatPhoneNumber(phone));
	}, [phone]);

	const handleChange = (event) => {
		let { name, value } = event.target;

		if (name === 'phone') {
			value = formatPhoneNumber(value);
		}

		dispatch.user.updateUser({ [name]: value });
	};

	return (
		<Wrapper>
			<Column>
				<Title>Personal Information</Title>

				<Container>
					<FormGroup>
						<Input
							name='firstName'
							value={firstName}
							disabled
							labelText='First name'
						/>
					</FormGroup>

					<FormGroup className='last-name'>
						<Input
							name='lastName'
							value={lastName}
							disabled
							labelText='Last name'
						/>
					</FormGroup>
				</Container>

				<FormGroup className='phone-number'>
					<Input
						name='phone'
						value={inputPhone}
						type='tel'
						labelText='Phone number'
						placeholder='(100) 123 4567'
						handleChange={handleChange}
						maxLength={14}
					/>
				</FormGroup>
			</Column>

			<Column>
				<Title>Account Settings</Title>
				<Container>
					<FormGroup>
						<Input
							name='email'
							value={email}
							type='email'
							handleChange={handleChange}
							labelText='Email address'
						/>
					</FormGroup>

					<FormGroup className='username'>
						<Input
							name='username'
							value={username}
							handleChange={handleChange}
							labelText='Username'
						/>
					</FormGroup>
				</Container>
			</Column>
		</Wrapper>
	);
};

export default ProfileEdit;
